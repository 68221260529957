import React from "react";
import Container from "@mui/material/Container";
import {  connect } from "react-redux";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import InfoComponent from "../../components/InfoComponent";

import selectDepartmentImage from "../../assets/images/department.png";

function Home(props) {
  const navigate = useNavigate();
  const [department, setDepartment] = React.useState(null);

  const handleChange = (event) => {
    const getDepartment = props.departments.find(department=>department.username===event.target.value)
    setDepartment(getDepartment);
  };
  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Stack spacing={3}>
            <InfoComponent
              title="Which Department?"
              isShowLogo
              subtitle="Choose your department to proceed"
            />
            {props.departmentSuccess && (
              <FormControl fullWidth sx={{ maxWidth: "452px" }}>
                <InputLabel id="demo-simple-select-label">
                  Choose Department
                </InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={department && department.username}
                  label="Choose Department"
                  onChange={handleChange}
                >
                  {props.departments.map((department) => (
                    <MenuItem value={department.username}>
                      {department.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <Button
              variant="contained"
              size="large"
              onClick={() => department && navigate(`/department/${department.username}`)}
              disabled={department === null}
              endIcon={<ArrowForwardIcon />}
              sx={{ maxWidth: "312px" }}
            >
              Proceed
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <img src={selectDepartmentImage} alt="Login" width="100%" />
        </Grid>
      </Grid>
    </Container>
  );
}
function mapStateToProps(state) {
  const { global } = state;
  return global;
}

export default connect(mapStateToProps)(Home);
