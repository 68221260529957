import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import React from "react";
import {useState, useEffect} from "react";
import { BrowserRouter as Router, Switch, Route, Link, useParams } from 'react-router-dom';
import Container from "@mui/material/Container";
import InfoComponent from "../../components/InfoComponent";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { updateData } from "../../utils/apiClient";
import dayjs from 'dayjs';
import { getData } from "../../utils/apiClient";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from '@mui/material/CircularProgress';
import {
    setIpoLoad,
    setIpoSuccess,
    setIpoError,
    setCreateIpoLoad,
    setCreateIpoSuccess,
    setCreateIpoError,
    setUpdateIpoLoad,
    setUpdateIpoSuccess,
    setUpdateIpoError,
  } from "../../redux/reducers/ipoReducer";
import { isNotNullOrEmpty } from "../../utils/helper";
import { InputAdornment } from "@mui/material";

const TitleText = styled.p`
  color: #2e3e8c;
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
`;

async function fetchIPOData(ipo) {
    const getIPOData = await getData("ipoe/get?ipoid="+ipo);
    if(getIPOData.success)return getIPOData.data;
    else return [];
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
}); 

function IncomingPurchaseOrderEntryEdit(props){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { id } = useParams();

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [open, setOpen] = React.useState(false);
    const [snakbarMessage, setSnakbarMessage] = React.useState('');
    const [isSnakbarError, seIsSnakbarError] = React.useState(false);
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpen(false);
      };

    useEffect(() => {
        fetchIPOData(id).then(data => {
            setData(data);
            const record = data[0];
            setContactPerson(record.contactperson);
            setIncomingPONumber(record.iponumber);
            setDateTimeOfEntry(dayjs(record.ipodate));
            setIncomingPODate(dayjs(record.ipodate));
            setCompanyName(record.companyid);
            setBuyerCustomer(record.buyerid);
            setProducts([...record.products]);
            setOpen(true);
            seIsSnakbarError(false);
            setSnakbarMessage("Data Loading for Incoming PO.");
        });
    }, []);

    const [showLoginModal, setShowLoginModal] = React.useState(false);

    const handleSelectCompany = (event) => {
        const companyDetails = props.companies.find(
            (company) => company.companyid === event.target.value
        );
        setCompanyName(companyDetails.companyid);
    };

    const handleBuyerCustomer = (event) => {
        const buyerDetails = props.buyers.find(
            (buyer) => buyer.buyerid === event.target.value
        );
        setBuyerCustomer(buyerDetails.buyerid);
    };

    const handleDateChange = (index1, index2, newVal) => {
        products[index1].productdetail[index2].date = new Date(newVal);
        setProducts([...products]);
    };

    const handleProductQuantity = (index1, index2) => (event) => {
        products[index1].productdetail[index2].quantity = event.target.value;
        setProducts([...products]);
    };

    const handleSelectProduct = (index) => (event) => {
        const categoryDetail = props.products.find(
            (category) => category.products.find(
                (product) => product.productid === event.target.value
            )
        );
        products[index].productid = event.target.value;
        products[index].category = categoryDetail.productcategoryid;
        const product = props.products.reduce((foundProduct, category) => {
            return foundProduct || category.products.find(product => product.productid === event.target.value);
          }, null);
        for(let index2=0;index2<products[index].productdetail.length;index2++){
            products[index].productdetail[index2].unit = product?.measurementmetrics?.name;
        }
        setProducts([...products]);
    };

    const handleProductUnit = (index1, index2) => (event) => {
        products[index1].productdetail[index2].unit = event.target.value;
        setProducts([...products]);
    };

    const handleRemark = (index) => (event) => {
        products[index].remark = event.target.value;
        setProducts([...products]);
    };

    const [dateTimeOfEntry, setDateTimeOfEntry] = React.useState(null);
    const [companyName, setCompanyName] = React.useState(null);
    const [contactPerson, setContactPerson] = React.useState(null);
    const [incomingPONumber, setIncomingPONumber] = React.useState(null);
    const [incomingPODate, setIncomingPODate] = React.useState(null);
    const [buyerCustomer, setBuyerCustomer] = React.useState(null);

    const [products, setProducts] = React.useState([
        {
            product: "",
            productid: "-",
            remark: "-",
            sgst: "0",
            cgst: "0",
            igst: "0",
            producttaxamount: 0,
            producttotal: 0,
            productdetail : [
                {
                    quantity: "",
                    unit : "",
                    date: "",
                    ipoproductslistmappingid: "-",
                    totalamount: 0,
                    ppu: 0
                },
            ]
        },
    ]);

    const addSplitProductHandler = (index) => {
        const unit = products[index]?.productdetail[0]?.unit;
        products[index].productdetail.push(
            {
                quantity: "",
                unit : unit,
                date: "",
                ipoproductslistmappingid: "-",
                totalamount: 0,
                ppu: 0
            }
        );
        setProducts([...products]);
    };

    const addNewProduct = () => {
        setProducts([...products, 
            {
                product: "-",
                productid: "",
                remark: "",
                sgst: "0",
                cgst: "0",
                igst: "0",
                producttaxamount: "0",
                producttotal: "0",
                productdetail : [
                    {
                        quantity: "",
                        unit : "",
                        date: "",
                        ipoproductslistmappingid: "-",
                        totalamount: 0,
                        ppu: 0
                    },
                ]
            },
        ])
    };

    const handleDeleteSplitProduct = (index1, index2) => {
        const array = products[index1].productdetail;
        const product = array.filter((_, i) => i !== index2);
        if(product.length === 0){
            product.push(
                {
                    quantity: "",
                    unit : "",
                    date: "",
                    ipoproductslistmappingid: "-",
                    totalamount: 0,
                    ppu: 0
                }
            );
        }
        products[index1].productdetail = product;
        setProducts([...products]);
    };

    const handleDeleteProduct = (index) => {
        const product = products.filter((_, i) => i !== index);
        if(product.length === 0){
            product.push({
                product: "-",
                productid: "",
                remark: "",
                sgst: "0",
                cgst: "0",
                igst: "0",
                producttaxamount: "0",
                producttotal: "0",
                productdetail : [
                    {
                        quantity: "",
                        unit : "",
                        date: "",
                        ipoproductslistmappingid: "-",
                        totalamount: 0,
                        ppu: 0
                    },
                ]
            });
        }
        setProducts([...product]);
    };

    const handleAddIPO = async () => {
        setIsLoading(true);
        try {
            const updateIPOData = {
                ipoid : id,
                datetimeofentry : new Date(dateTimeOfEntry),
                companyid : companyName,
                contactperson : contactPerson,
                iponumber: incomingPONumber,
                ipodate: new Date(incomingPODate),
                buyerid : buyerCustomer,
                products : products
            };
            if(isNotNullOrEmpty(updateIPOData)){
                const createPrnResp = await updateData("ipoe/update", updateIPOData);
                if (createPrnResp.success) {
                    setIsLoading(false);
                    setOpen(true);
                    seIsSnakbarError(false);
                    setSnakbarMessage("Incoming PO Updated.");
                    setTimeout(() => {
                        navigate('/ipoe');
                    }, 4000);
                } else {
                    setIsLoading(false);
                    setOpen(true);
                    seIsSnakbarError(true);
                    setSnakbarMessage("All fields are required.");
                }
            } else {
                setIsLoading(false);
                setOpen(true);
                seIsSnakbarError(true);
                setSnakbarMessage("All fields are required.");
            }
        } catch (error) {
            setIsLoading(false);
            setOpen(true);
            seIsSnakbarError(true);
            setSnakbarMessage("All fields are required.");
        }
      };

    return (
        <Container maxWidth="xl" sx={{ marginBottom: "40px" }}>
            {isLoading && <CircularProgress />}
            <Snackbar
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                <Alert
                onClose={handleClose}
                severity={isSnakbarError ? "error" : "success"}
                sx={{ width: "100%" }}
                >
                {snakbarMessage}
                </Alert>
            </Snackbar>
            {!isLoading && <>
            <InfoComponent
                title="Incoming Purchase Order Entry"
                subtitle="Kindly fill the details of the incoming Purchase Order"
                showBackButton
            />
            <TitleText>Incoming Purchase Order:</TitleText>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                        sx={{ width: "100%" }}
                        value={dateTimeOfEntry}
                        onChange={(newValue) => setDateTimeOfEntry(newValue)}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControl fullWidth>
                        <Select
                        id="demo-simple-select"
                        value={companyName}
                        onChange={handleSelectCompany}
                        >
                        {props.companies &&
                            props.companies.map((company) => (
                            <MenuItem value={company.companyid}>{company.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <br />
            <div style={{ width: '100%', borderTop: '1px solid #D3D3D3' }}></div>
            <TitleText>Buyer/Customer:</TitleText>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        id="contactperson"
                        variant="outlined"
                        value={contactPerson}
                        fullWidth
                        onChange={(event) => setContactPerson(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        id="incomingponumber"
                        variant="outlined"
                        fullWidth
                        value={incomingPONumber}
                        onChange={(event) => setIncomingPONumber(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                        sx={{ width: "100%" }}
                        value={incomingPODate}
                        onChange={(newValue) => setIncomingPODate(newValue)}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControl fullWidth>
                        <Select
                        id="demo-simple-select"
                        onChange={handleBuyerCustomer}
                        value={buyerCustomer}
                        >
                        {props.buyers &&
                            props.buyers.map((buyer) => (
                            <MenuItem value={buyer.buyerid}>{buyer.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <br />
            <div style={{ width: '100%', borderTop: '1px solid #D3D3D3' }}></div>
            <TitleText>Product:</TitleText>
            <Stack spacing={3}>
                {products &&
                    products.map((product, index) => (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Product</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Product"
                                            value={products[index].productid}
                                            onChange={handleSelectProduct(index)}
                                            >
                                            {props.products &&
                                                props.products.map((products) => (
                                                    products.products.map((product) => (
                                                        <MenuItem value={product.productid}>{product.name} - ({products.name})</MenuItem>
                                                    )
                                                )))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <DeleteIcon style={{ color: 'red', cursor: 'pointer', paddingTop: '20%' }} onClick={() => handleDeleteProduct(index)}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <br />
                                {product.productdetail &&
                                    product.productdetail.map((productData, innerIndex) => (
                                        <Grid container spacing={2}  style={{ margin: '3px'}}>
                                            <Grid item xs={5}>
                                                <TextField
                                                    label="Product Quantity"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={products[index].productdetail[innerIndex].quantity}
                                                    onChange={handleProductQuantity(index, innerIndex)}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <TextField
                                                                variant="standard"
                                                                value={products[index].productdetail[innerIndex].unit}
                                                                disabled
                                                                />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        sx={{ width: "100%" }}
                                                        label="Delivery Date"
                                                        value={dayjs(products[index].productdetail[innerIndex].date)}
                                                        onChange={(newValue) =>
                                                            handleDateChange(index, innerIndex, newValue)
                                                        }
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={1}>
                                                    <DeleteIcon style={{ color: 'red', cursor: 'pointer', paddingTop: '20%' }} onClick={() => handleDeleteSplitProduct(index, innerIndex)}/>
                                            </Grid>
                                        </Grid>
                                    ))}
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    id="incomingponumber"
                                    variant="outlined"
                                    fullWidth
                                    label="Specifications/Remarks"
                                    value={products[index].remark}
                                    onChange={handleRemark(index)}
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                            <Button
                                variant="contained"
                                size="large"
                                color="secondary"
                                style={{
                                    width: '300px',
                                    marginLeft : '15px',
                                }}
                                onClick={() => addSplitProductHandler(index)}
                                endIcon={<AddIcon />}
                                sx={{ maxWidth: "312px", marginTop: "20px", marginBottom: "30px"  }}
                                >
                                Split
                            </Button>
                            <div style={{ width: '100%', borderTop: '1px solid #D3D3D3' }}></div>
                        </Grid>
                ))}            
            </Stack>
            <Button
                variant="outlined"
                size="large"
                style={{
                    width: '300px',
                }}
                onClick={() => addNewProduct()}
                endIcon={<AddIcon />}
                sx={{ maxWidth: "312px", marginTop: "20px", marginBottom: "30px" }}
            >
            Another Product
            </Button>
            <br />
            <div style={{ width: '100%', borderTop: '1px solid #D3D3D3' }}></div>
            <Button
                variant="contained"
                size="large"
                style={{
                    width: '300px',
                }}
                onClick={() => handleAddIPO()}
                endIcon={<AddIcon />}
                sx={{ maxWidth: "312px", marginTop: "20px", marginBottom: "30px" }}
            >
            Update
            </Button>
            </> }
        </Container>
    );
}
function mapStateToProps(state) {
    const { global } = state;
    return global;
}
export default connect(mapStateToProps)(IncomingPurchaseOrderEntryEdit);