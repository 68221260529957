import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  account: null,
  prnsLoading: false,
  prnsSuccess: false,
  prnsError: false,
  prns: [],
  createPrnLoading: false,
  createPrnSuccess: false,
  createPrnError: false,
  updatePrnLoading: false,
  updatePrnSuccess: false,
  updatePrnError: false,
};

export const prnSlice = createSlice({
  name: "prn",
  initialState,
  reducers: {
    setPrnLoad: (state) => {
      state.prnsLoading = true;
      state.prnsSuccess = false;
      state.prnsError = false;
      state.prns = [];
    },
    setPrnSuccess: (state, action) => {
      state.prnsLoading = false;
      state.prnsSuccess = true;
      state.prnsError = false;
      state.prns = action.payload;
    },
    setPrnError: (state) => {
      state.prnsLoading = false;
      state.prnsSuccess = false;
      state.prnsError = true;
      state.prns = [];
    },
    setCreatePrnLoad: (state) => {
      state.createPrnLoading = true;
      state.createPrnSuccess = false;
      state.createPrnError = false;
    },
    setCreatePrnSuccess: (state) => {
      state.createPrnLoading = false;
      state.createPrnSuccess = true;
      state.createPrnError = false;
    },
    setCreatePrnError: (state) => {
      state.createPrnLoading = false;
      state.createPrnSuccess = false;
      state.createPrnError = true;
    },
    setUpdatePrnLoad: (state) => {
      state.updatePrnLoading = true;
      state.updatePrnSuccess = false;
      state.updatePrnError = false;
    },
    setUpdatePrnSuccess: (state) => {
      state.updatePrnLoading = false;
      state.updatePrnSuccess = true;
      state.updatePrnError = false;
    },
    setUpdatePrnError: (state) => {
      state.updatePrnLoading = false;
      state.updatePrnSuccess = false;
      state.updatePrnError = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPrnLoad,
  setPrnSuccess,
  setPrnError,
  setCreatePrnLoad,
  setCreatePrnSuccess,
  setCreatePrnError,
  setUpdatePrnLoad,
  setUpdatePrnSuccess,
  setUpdatePrnError,
} = prnSlice.actions;

export default prnSlice.reducer;
