import React, { useEffect } from "react";
import { v4 as uuid } from "uuid";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Container from "@mui/material/Container";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";

import ProductPOForm from "../../components/ProductPOForm";
import InfoComponent from "../../components/InfoComponent";
import LoginComponent from "../../components/LoginComponent";
// import POSupplierForm from "../../components/POSupplierForm";
import { updateData } from "../../utils/apiClient";

import {
  setPoLoad,
  setPoSuccess,
  setPoError,
  setCreatePoLoad,
  setCreatePoSuccess,
  setCreatePoError,
} from "../../redux/reducers/poReducer";
import { setUserData } from "../../redux/reducers/globalReducer";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TitleText = styled.p`
  color: #2e3e8c;
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
`;
const TermsText = styled.li`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
`;

function CreatePO(props) {
  const { prnid } = useParams();
  const navigate = useNavigate();
  const [showLoginModal, setShowLoginModal] = React.useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [isSnakbarError, seIsSnakbarError] = React.useState(false);
  const [prnData, setPrnData] = React.useState(null);
  const [supplierdData, setSupplierdData] = React.useState([]);
  const [allSuppliersData, setAllSuppliersData] = React.useState({
    suppliers: [],
    products: [],
  });
  const [suppliers, setSuppliers] = React.useState({
    id: "supplier1",
    selectedSupplier: null,
    purchaseorderdate: dayjs(new Date()),
    contactperson: "",
    reference: "",
    gst: "",
    paymentTerms: "",
    deliveryschedule: "",
    referencedate: dayjs(new Date()),
    company: {
      companyid: "",
      correspondenceaddress: "",
      gstnumber: "",
      billingaddress: "",
    },
    selectedSupplierId: "",
    productsState: [
      {
        selectedProductId: "",
        name: "",
        id: "product1",
        quantityRequestd: "",
        measurementmetricid: "",
        specifications: "",
        quantityTimeLine: [
          {
            quantityId: uuid(),
            quantity: "0",
            unit: "",
            quantityConverted: "0",
            priceperunit: "",
            deliveryDate: dayjs(new Date()),
          },
        ],
      },
    ],
  });
  const handleSelectSupplier = (event) => {
    const suppliers =
      supplierdData.length > 0 ? supplierdData : allSuppliersData.suppliers;
    const supplier = suppliers.find(
      (supplier) => supplier.supplierid === event.target.value
    );
    console.log("supplier", supplier);
    setSuppliers((supply) => ({
      ...supply,
      selectedSupplierId: supplier.supplierid,
      selectedSupplier: supplier,
    }));
  };
  const deleteProduct = (productid) => {
    const updatedProducts = suppliers.productsState.filter(
      (product) => product.id !== productid
    );
    const updatedSupplier = {
      ...suppliers,
      productsState: updatedProducts,
    };
    setSuppliers({ ...updatedSupplier });
  };
  const addProduct = () => {
    const productsState = suppliers.productsState.concat([
      {
        selectedProductId: "",
        name: "",
        id: `product${suppliers.productsState.length + 1}`,
        quantityRequestd: "",
        measurementmetricid: "",
        specifications: "",
        quantityTimeLine: [
          {
            quantityId: uuid(),
            quantity: "0",
            unit: "",
            priceperunit: "",
            deliveryDate: dayjs(new Date()),
          },
        ],
      },
    ]);
    const updatedSupplier = {
      ...suppliers,
      productsState,
    };
    setSuppliers({ ...updatedSupplier });
  };
  const onCreatePo = async () => {
    if (props.user && props.user.token) {
      const createPoData = {
        prnid: prnid,
        pos: [],
        userid: props.user.userid,
        usertoken: props.user.token,
      };
      const pos = [];
      const products = [];
      suppliers.productsState.forEach((product) => {
        products.push({
          productid: product.selectedProductId,
          specifications: product.specifications,
          deliverSchedules: product.quantityTimeLine.map((quantity) => {
            return {
              quantityamount: quantity.quantityConverted,
              measurementmetricid: product.measurementmetricid,
              deliverydate: new Date(quantity.deliveryDate).toDateString(),
              priceperunit: quantity.priceperunit,
            };
          }),
        });
      });
      let fiscalyear = "";
      const date = new Date(suppliers.purchaseorderdate);
      if (date.getMonth() + 1 <= 3) {
        fiscalyear = date.getFullYear() - 1 + "-" + date.getFullYear();
      } else {
        fiscalyear = date.getFullYear() + "-" + (date.getFullYear() + 1);
      }
      pos.push({
        supplierid: suppliers.selectedSupplierId,
        contactperson: suppliers.contactperson,
        reference: suppliers.reference,
        gst: suppliers.gst,
        paymentterms: suppliers.paymentTerms,
        referencedate: new Date(suppliers.referencedate),
        deliveryschedule: suppliers.deliveryschedule,
        companyid: suppliers.company.companyid,
        purchaseorderdate: new Date(suppliers.purchaseorderdate),
        products,
        purchaseordernumber:
          suppliers.company.companyid === "05eab5b3-508a-4b32-b28c-c0b24c52848c"
            ? `PB/${fiscalyear}/`
            : `CB/${fiscalyear}/`,
      });
      createPoData.pos = pos;
      console.log("createPoData", createPoData);
      dispatch(setCreatePoLoad());
      const createPrnResp = await updateData("pos/create", createPoData);
      if (createPrnResp.success) {
        setOpen(true);
        seIsSnakbarError(false);
        dispatch(setCreatePoSuccess(createPrnResp.data));
        dispatch(setPoLoad());
        const prns = await updateData("pos");
        if (prns.success) {
          dispatch(setPoSuccess(prns.data));
        } else {
          dispatch(setPoError());
        }
      } else {
        setOpen(true);
        seIsSnakbarError(true);
        dispatch(setCreatePoError());
      }
      dispatch(setUserData());
    } else {
      setShowLoginModal(true);
    }
  };
  const handleModalClose = (loginSuccess) => {
    setShowLoginModal(false);
    if (loginSuccess) {
      onCreatePo();
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleSelectComoany = (event) => {
    const companyData = props.companies.find(
      (company) => company.companyid === event.target.value
    );
    const suppliersData = {
      ...suppliers,
      company: companyData,
    };
    setSuppliers({ ...suppliersData });
  };
  const handleDateChange = (newValue) => {
    setSuppliers((suppliersData) => ({
      ...suppliersData,
      purchaseorderdate: newValue,
    }));
  };
  const onDeletProduct = (productid) => {
    deleteProduct(productid);
  };
  const updateProductsData = (productsData) => {
    if (productsData) {
      let updatedSupplierData = suppliers;
      const productsState = suppliers.productsState.map((product) => {
        if (product.id === productsData.id) {
          product.selectedProductId = productsData.selectedProductId;
          product.name = productsData.name;
          product.quantityRequestd = productsData.quantityRequestd;
          product.quantityTimeLine = productsData.quantityTimeLine;
          product.measurementmetricid = productsData.measurementmetricid;
          product.specifications = productsData.specifications;
        }
        return product;
      });
      console.log("productsState", productsState);
      updatedSupplierData = {
        ...updatedSupplierData,
        productsState,
      };
      setSuppliers({ ...updatedSupplierData });
    }
  };
  const handleTextBoxUpdate = (title, value) => {
    setSuppliers((supply) => ({
      ...supply,
      [title]: value,
    }));
  };
  useEffect(() => {
    const getPrnDetails = async () => {
      const getPrnData = await updateData("prns/details", { prnid });
      const getPrnSuppliers = await updateData("prns/suppliers", { prnid });
      if (getPrnSuppliers.success) {
        setSupplierdData(getPrnSuppliers.data);
        if (getPrnSuppliers.poData) {
          setAllSuppliersData({
            suppliers: getPrnSuppliers.poData.suppliers,
            products: getPrnSuppliers.poData.products,
          });
        }
      }
      if (getPrnData.success) {
        setPrnData(getPrnData.data);
      }
    };
    if (prnid) {
      getPrnDetails();
    }
  }, [prnid]);
  useEffect(() => {
    if (props.createPoSuccess) {
      // navigate(`/po/${props.createPoData.poIds[0]}`);
      window.location.href = `/po/${props.createPoData.poIds[0]}`;
    }
  }, [
    navigate,
    props.createPoData,
    props.createPoError,
    props.createPoSuccess,
  ]);
  return (
    <Container maxWidth="xl" sx={{ marginBottom: "40px" }}>
      {showLoginModal && (
        <LoginComponent
          showLoginModal={showLoginModal}
          handleModalClose={handleModalClose}
        />
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={isSnakbarError ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {isSnakbarError
            ? "Something went wrong while Creating PO !"
            : "PO Successfully Created !!"}
        </Alert>
      </Snackbar>
      <InfoComponent
        title="PO"
        subtitle="Kindly fill the details to create a Product Order."
        showBackButton
      />
      <TitleText>PO:</TitleText>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            id="gstno"
            variant="outlined"
            fullWidth
            disabled
            // onChange={handleQuantity}
            label="Purchase Order Number (Auto-Generated)"
            // value={selectedSupplier ? selectedSupplier.gstnumber : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: "100%" }}
              label="Purchase Order Date"
              value={suppliers.purchaseorderdate}
              onChange={(newValue) => handleDateChange(newValue)}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Company Name</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={suppliers.company ? suppliers.company.companyid : ""}
              label="Company Name"
              // disabled={isDisabled || false}
              onChange={handleSelectComoany}
            >
              {props.companies &&
                props.companies.map((company) => (
                  <MenuItem value={company.companyid}>{company.name}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            id="gstno"
            variant="outlined"
            fullWidth
            disabled
            // onChange={handleQuantity}
            label="GSTIN"
            value={suppliers ? suppliers.company.gstnumber : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            id="gstno"
            variant="outlined"
            fullWidth
            disabled
            // onChange={handleQuantity}
            label="Billing Address (Factory)"
            value={suppliers ? suppliers.company.billingaddress : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            id="gstno"
            variant="outlined"
            fullWidth
            disabled
            // onChange={handleQuantity}
            label="Correspondence Address"
            value={suppliers ? suppliers.company.correspondenceaddress : ""}
          />
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: "30px" }} />
      <Stack spacing={3}>
        <TitleText>
          Supplier :{" "}
          {suppliers.selectedSupplier ? suppliers.selectedSupplier.name : ""}
        </TitleText>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <TextField
              id="contactperson"
              variant="outlined"
              fullWidth
              onChange={(event) =>
                handleTextBoxUpdate("contactperson", event.target.value)
              }
              label="Contact Person"
              value={suppliers.contactperson}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} />
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <TextField
              id="reference"
              variant="outlined"
              fullWidth
              onChange={(event) =>
                handleTextBoxUpdate("reference", event.target.value)
              }
              label="Reference/Quotation/PI"
              value={suppliers.reference}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: "100%" }}
                label="Reference Date"
                value={suppliers.referencedate}
                onChange={(newValue) =>
                  setSuppliers((supply) => ({
                    ...supply,
                    referencedate: newValue,
                  }))
                }
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Choose Supplier
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={
                  suppliers.selectedSupplier
                    ? suppliers.selectedSupplier.supplierid
                    : ""
                }
                label="Choose Category"
                onChange={handleSelectSupplier}
              >
                {supplierdData && supplierdData.length > 0
                  ? supplierdData.map((supplier) => (
                      <MenuItem value={supplier.supplierid}>
                        {supplier.name}
                      </MenuItem>
                    ))
                  : allSuppliersData.suppliers.map((supplier) => (
                      <MenuItem value={supplier.supplierid}>
                        {supplier.name}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="gstno"
              variant="outlined"
              fullWidth
              disabled
              // onChange={handleQuantity}
              label="GST Number"
              value={
                suppliers.selectedSupplier
                  ? suppliers.selectedSupplier.gstnumber
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="email"
              label="Email"
              disabled
              variant="outlined"
              fullWidth
              value={
                suppliers.selectedSupplier
                  ? suppliers.selectedSupplier.email
                  : ""
              }
              // onChange={handleQuantity}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="phone"
              label="Phone number"
              disabled
              variant="outlined"
              fullWidth
              value={
                suppliers.selectedSupplier
                  ? suppliers.selectedSupplier.phone
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              id="address"
              label="Address"
              disabled
              variant="outlined"
              fullWidth
              value={
                suppliers.selectedSupplier
                  ? suppliers.selectedSupplier.address
                  : ""
              }
            />
          </Grid>
        </Grid>
        <Divider sx={{ marginTop: "30px" }} />
        <Stack spacing={2}>
          {suppliers.selectedSupplier &&
            suppliers.productsState &&
            suppliers.productsState.map((product) => (
              <ProductPOForm
                {...product}
                prnData={prnData}
                selectedSupplier={suppliers.selectedSupplier}
                prnproducts={allSuppliersData.products}
                onDeletProduct={onDeletProduct}
                updateProductsData={updateProductsData}
              />
            ))}
        </Stack>
        <Divider sx={{ marginTop: "30px" }} />
        <Button
          variant="outlined"
          size="large"
          onClick={() => addProduct()}
          endIcon={<AddIcon />}
          sx={{ maxWidth: "312px", marginTop: "20px", marginBottom: "30px" }}
        >
          Another Product
        </Button>
      </Stack>
      <Divider sx={{ marginTop: "30px" }} />
      <TitleText>Note:</TitleText>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            id="gstno"
            variant="outlined"
            fullWidth
            onChange={(event) => handleTextBoxUpdate("gst", event.target.value)}
            label="GST"
            value={suppliers.gst}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            id="gstno"
            variant="outlined"
            fullWidth
            onChange={(event) =>
              handleTextBoxUpdate("paymentTerms", event.target.value)
            }
            label="Payment Terms"
            value={suppliers.paymentTerms}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            id="gstno"
            variant="outlined"
            fullWidth
            onChange={(event) =>
              handleTextBoxUpdate("deliveryschedule", event.target.value)
            }
            label="Delivery Schedule"
            value={suppliers.deliveryschedule}
          />
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: "30px" }} />
      <TitleText>Terms and Conditions:</TitleText>
      <ul>
        <TermsText>
          Our Order Number, GST Number must be quoted on all dispatch documents
          like Delivery Challans, Tax Invoice, Guarantee / Warranty cards etc.
        </TermsText>
        <TermsText>
          Delivery Challans and Invoice should be in duplicate.
        </TermsText>
        <TermsText>
          Material will be rejected if it is not as per specification
        </TermsText>
        <TermsText>
          Documents to be provided- Invoice , DC, Test report, Transportation
          Bill, Service manual as applicable
        </TermsText>
      </ul>
      <Divider sx={{ marginTop: "30px" }} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "30px",
        }}
      >
        {/* <Button
          variant="outlined"
          size="large"
          onClick={addNewSupplier}
          endIcon={<AddIcon />}
          sx={{ maxWidth: "312px", marginTop: "20px", marginBottom: "30px" }}
        >
          Another Supplier
        </Button> */}
        <Button
          variant="contained"
          size="large"
          disabled={
            suppliers.selectedSupplierId === "" ||
            props.createPoLoading ||
            suppliers.productsState[0].selectedProductId === ""
          }
          onClick={onCreatePo}
          endIcon={<AddIcon />}
          sx={{ maxWidth: "312px" }}
        >
          {props.createPoLoading ? "Processing..." : "Create"}
        </Button>
      </div>
    </Container>
  );
}
function mapStateToProps(state) {
  const { po, global } = state;
  return { ...po, ...global };
}

export default connect(mapStateToProps)(CreatePO);
