import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import PlaceholderLoading from "react-placeholder-loading";
import Container from "@mui/material/Container";

import {
  setGrnError,
  setGrnSuccess,
  setGrnLoad,
} from "../../redux/reducers/grnReducer";
import { updateData } from "../../utils/apiClient";
import GRNCard from "../../components/GRNCard";
import InfoComponent from "../../components/InfoComponent";


function GRN(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    const getPrnsData = async () => {
      dispatch(setGrnLoad());
      const pos = await updateData("/grns");
      if (pos.success) {
        dispatch(setGrnSuccess(pos.data));
      } else {
        dispatch(setGrnError());
      }
    };
    getPrnsData();
  }, [dispatch]);
  return (
    <Container maxWidth="xl" sx={{ marginBottom: "40px" }}>
      <InfoComponent
        title="GRN Listing"
        backRedirectLink={
          props.department &&
          props.department.username === "store" &&
          `/department/${props.department.username}`
        }
        subtitle="The following are the registered GRNs."
        showBackButton
      />
      {props.grnsLoading && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <PlaceholderLoading shape="rect" width="100%" height="400px" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <PlaceholderLoading shape="rect" width="100%" height="400px" />
          </Grid>
        </Grid>
      )}
      {props.grnsprnsSuccess && (
        <Grid container spacing={2}>
          {props.grns.map((product) => (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <GRNCard product={product} department={props.department} />
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
}
function mapStateToProps(state) {
  const { grn, global } = state;
  return { ...grn, ...global };
}

export default connect(mapStateToProps)(GRN);
