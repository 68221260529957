import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  grnsLoading: false,
  grnsprnsSuccess: false,
  grnsError: false,
  grns: [],
  createGrnsLoading: false,
  createGrnsprnsSuccess: false,
  createGrnsError: false,
  updateGrnsLoading: false,
  updateGrnsprnsSuccess: false,
  updateGrnsError: false,
};

export const grnSlice = createSlice({
  name: "grn",
  initialState,
  reducers: {
    setGrnLoad: (state) => {
      state.grnsLoading = true;
      state.grnsprnsSuccess = false;
      state.grnsError = false;
      state.grns = [];
    },
    setGrnSuccess: (state, action) => {
      state.grnsLoading = false;
      state.grnsprnsSuccess = true;
      state.grnsError = false;
      state.grns = action.payload;
    },
    setGrnError: (state) => {
      state.grnsLoading = false;
      state.grnsprnsSuccess = false;
      state.grnsError = true;
      state.grns = [];
    },
    setCreateGrnLoad: (state) => {
      state.createGrnsLoading = true;
      state.createGrnsprnsSuccess = false;
      state.createGrnsError = false;
    },
    setCreateGrnSuccess: (state) => {
      state.createGrnsLoading = false;
      state.createGrnsprnsSuccess = true;
      state.createGrnsError = false;
    },
    setCreateGrnError: (state) => {
      state.createGrnsLoading = false;
      state.createGrnsprnsSuccess = false;
      state.createGrnsError = true;
    },
    setUpdateGrnLoad: (state) => {
      state.updateGrnsLoading = true;
      state.updateGrnsprnsSuccess = false;
      state.updateGrnsError = false;
    },
    setUpdateGrnSuccess: (state) => {
      state.updateGrnsLoading = false;
      state.updateGrnsprnsSuccess = true;
      state.updateGrnsError = false;
    },
    setUpdateGrnError: (state) => {
      state.updateGrnsLoading = false;
      state.updateGrnsprnsSuccess = false;
      state.updateGrnsError = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setGrnLoad,
  setGrnSuccess,
  setGrnError,
  setCreateGrnLoad,
  setCreateGrnSuccess,
  setCreateGrnError,
  setUpdateGrnLoad,
  setUpdateGrnSuccess,
  setUpdateGrnError,
} = grnSlice.actions;

export default grnSlice.reducer;
