import React, { useEffect } from "react";
import styled from "styled-components";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { updateData } from "../../utils/apiClient";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { convertToNearestToKg } from "../../utils/convetor";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ProductConatainer = styled.div`
  border-radius: 16px;
  border: 1px solid var(--Disabled, #848484);
  background: #fff;
  padding: 24px;
`;
const ProductTitle = styled.p`
  overflow: hidden;
  color: var(--Text, #333);
  text-overflow: ellipsis;
  /* description/bold */
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
const SubtitleText = styled.p`
  color: var(--Text, #333);
  /* note/regular */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;
export default function InventoryCard(props) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [isSnakbarError, seIsSnakbarError] = React.useState(false);
  const [reorderValue, setReorderValue] = React.useState(0);
  const [reorderValueConverted, setReorderValueConverted] = React.useState(0);
  const [isDisbaled, setIsDisbaled] = React.useState(true);
  const [updateCall, setUpdateCall] = React.useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const updateOrderLevel = async () => {
    try {
      setUpdateCall((data) => ({ ...data, isLoading: true }));
      const updateProductData = {
        productid: props.productid,
        reorderLevel: reorderValueConverted,
      };
      const updateResp = await updateData(
        "products/updateReorderLevel",
        updateProductData
      );
      if (updateResp.success) {
        setOpen(true);
        seIsSnakbarError(false);
        setUpdateCall((data) => ({
          ...data,
          isSuccess: true,
          isLoading: false,
        }));
      } else {
        setOpen(true);
        seIsSnakbarError(true);
        setUpdateCall((data) => ({ ...data, isError: true, isLoading: false }));
      }
      setIsDisbaled(true);
    } catch (error) {
      setOpen(true);
      seIsSnakbarError(true);
      setUpdateCall((data) => ({ ...data, isError: true, isLoading: false }));
    }
  };
  const handleReorderLevel = (event) => {
    setReorderValue(event.target.value);
    if (props.measurementmetrics && props.measurementmetrics.name === "grams") {
      const convertedValue = convertToNearestToKg(props.reorderlevel);
      setReorderValueConverted(convertedValue);
    } else {
      setReorderValueConverted(event.target.value);
    }
  };
  useEffect(() => {
    if (props.measurementmetrics && props.measurementmetrics.name === "grams") {
      const convertedValue = convertToNearestToKg(props.reorderlevel);
      setReorderValue(convertedValue);
    } else {
      setReorderValue(props.reorderlevel);
    }
    setReorderValueConverted(props.reorderlevel);
  }, [props.measurementmetrics, props.reorderlevel]);

  let infoText = "";
  let infoColor = "#333";
  let numberToAdd =
    parseFloat(props.productQtyinPo) + parseFloat(props.productQtyinPrn);
  let extParamToAdd = 0;
  if (props.category.name === "Raw Material") {
    extParamToAdd = parseFloat(props.pendingGrnQty);
  }
  if (
    numberToAdd + parseFloat(props.productQtyinGrn) + extParamToAdd >
    parseFloat(props.reorderlevel)
  ) {
    let reorderLevelVal = props.reorderlevel;
    infoText = `The total quantity exceeds ${reorderLevelVal} ${props.measurementmetrics.name} including POs.`;
    infoColor = "#FF852D";
  } else {
    infoText = "Total quantity is below reorder level.";
    infoColor = "#D34836";
  }
  if (parseFloat(props.productQtyinGrn) >= parseFloat(props.reorderlevel)) {
    infoColor = "#24B73C";
    infoText = `Total quantity in GRN matches ${props.reorderlevel} ${props.measurementmetrics.name}.`;
  }
  return (
    <ProductConatainer>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={isSnakbarError ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {isSnakbarError
            ? "Something went wrong while Updating Product Reorder Value !"
            : "Product Reorder Value Successfully Updated !!"}
        </Alert>
      </Snackbar>
      <Stack direction="row" spacing={4}>
        {props.grnproductmappings ? (
          <Carousel showThumbs={false}>
            {props.grnproductmappings &&
              props.grnproductmappings.map((mapping) => (
                <img
                  src={mapping.productimageurl}
                  alt="placeholder"
                  style={{ borderRadius: "8px", maxWidth: "500px" }}
                />
              ))}
          </Carousel>
        ) : (
          <img
            src="https://placehold.co/240x343"
            alt="placeholder"
            style={{ borderRadius: "8px" }}
          />
        )}
        <Stack direction="column" spacing={2} width="100%">
          <ProductTitle>{props.name}</ProductTitle>
          <SubtitleText>{props.category.name}</SubtitleText>
          <Stack direction="row" spacing={2} width="100%">
            <Stack direction="column" spacing={1} width="100%">
              <ProductTitle style={{ fontSize: "16px" }}>
                Total Qty Remaining
              </ProductTitle>
              <SubtitleText style={{ fontSize: "16px", color: infoColor }}>
                {`${parseFloat(props.productQtyinGrn)} ${
                  props.measurementmetrics.name
                }`}
              </SubtitleText>
            </Stack>
            <Stack direction="column" spacing={1} width="100%">
              <ProductTitle style={{ fontSize: "16px" }}>
                Qty Remaining in PRN/POs
              </ProductTitle>
              <SubtitleText style={{ fontSize: "16px", color: infoColor }}>
                {`${
                  parseFloat(props.productQtyinPo) +
                  parseFloat(props.productQtyinPrn)
                } ${props.measurementmetrics.name}`}
              </SubtitleText>
            </Stack>
            {props.category.name === "Raw Material" && (
              <Stack direction="column" spacing={1} width="100%">
                <ProductTitle style={{ fontSize: "16px" }}>
                  Pending GRN Qty
                </ProductTitle>
                <SubtitleText style={{ fontSize: "16px", color: infoColor }}>
                  {`${props.pendingGrnQty} ${props.measurementmetrics.name}`}
                </SubtitleText>
              </Stack>
            )}
          </Stack>
          <Stack direction="column" spacing={1} width="100%">
            <ProductTitle style={{ fontSize: "16px" }}>
              Reorder Level
            </ProductTitle>
            <TextField
              id="standard-basic"
              value={reorderValue}
              onChange={handleReorderLevel}
              variant="standard"
              disabled={isDisbaled}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span style={{ marginRight: "5px" }}>
                      {props.measurementmetrics
                        ? props.measurementmetrics.name === "grams"
                          ? "KG"
                          : props.measurementmetrics.name
                        : ""}
                    </span>
                    {isDisbaled ? (
                      <Button onClick={() => setIsDisbaled(false)}>Edit</Button>
                    ) : (
                      <Button onClick={() => updateOrderLevel()}>
                        {updateCall.isLoading ? "Updating..." : " Update"}
                      </Button>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            {infoText && infoText.length > 0 && (
              <p style={{ color: infoColor }}>{infoText}</p>
            )}
          </Stack>
          <Button
            variant="contained"
            size="large"
            onClick={() => navigate(`/createprn/${props.productid}`)}
            endIcon={<AddIcon />}
            fullWidth
          >
            Create PRN
          </Button>
        </Stack>
      </Stack>
    </ProductConatainer>
  );
}
