import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { store } from "./redux/store";
import { Provider } from "react-redux";

import Header from "./components/Header";
import Home from "./conatainers/Home";
import Department from "./conatainers/Department";
import StorePage from "./conatainers/Store";
import CreatePRNPage from "./conatainers/CreatePRN";
import InventoryPage from "./conatainers/Inventory";
import CreateGRNPage from "./conatainers/CreateGRN";
import CreatePOPage from "./conatainers/CreatePO";
import QcPage from "./conatainers/QC";
import GIIRPage from "./conatainers/GIIR";
import PRNPage from "./conatainers/PRN";
import POPage from "./conatainers/PO";
import GRNPage from "./conatainers/GRN";
import ViewGRNPage from "./conatainers/ViewGRN";
import ViewGIIRPage from "./conatainers/ViewGIIR";
import ViewPRNPage from "./conatainers/ViewPRN";
import ViewPOPage from "./conatainers/ViewPO";
import RawMaterialsPage from "./conatainers/RawMaterials";
import CreateGIIRPage from "./conatainers/CreateGIIR";
import SalesPage from "./conatainers/Sales";
import LoginPage from "./conatainers/Login";
import IncomingPurchaseOrderEntry from "./conatainers/IncomingPurchaseOrderEntry";
import InvoiceEntryPage from "./conatainers/InvoiceEntryPage";
import IncomingPOListing from "./conatainers/IncomingPOListing";
import InvoiceListing from "./conatainers/InvoiceListing";
import POPayReq from "./conatainers/POPayReq";
import POPayAckn from "./conatainers/POPayAckn";
import IncomingPurchaseOrderEntryView from "./conatainers/IncomingPurchaseOrderEntryView";
import IncomingPurchaseOrderEntryEdit from "./conatainers/IncomingPurchaseOrderEntryEdit";
import InvoiceEntryPageView from "./conatainers/InvoiceEntryPageView";
import InvoiceEntryPageEdit from "./conatainers/InvoiceEntryPageEdit";
import Admin from "./conatainers/Admin";

const theme = createTheme({
  palette: {
    primary: { main: "#2E3E8C" },
    secondary: { main: "#4282BF" },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '20px 16px',
          fontSize: '16px',
          fontWeight: 500,
          height: '60px',
          borderRadius: '8px'
        },
      },
    },
  },
  typography: {
    fontFamily: [
      "Open Sans",
      "sans-serif",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/department/:department" element={<Department />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/store" element={<StorePage />} />
            <Route path="/qc" element={<QcPage />} />
            <Route path="/sales" element={<SalesPage />} />
            <Route exact path="/prn" element={<PRNPage />} />
            <Route path="/po" element={<POPage />} />
            <Route path="/giir" element={<GIIRPage />} />
            <Route path="/grn" element={<GRNPage />} />
            <Route path="/inventory" element={<InventoryPage />} />
            <Route path="/rawmaterials" element={<RawMaterialsPage />} />
            <Route path="/grn/:id" element={<ViewGRNPage />} />
            <Route exact path="/createprn/:id" element={<CreatePRNPage />} />
            <Route exact path="/createprn" element={<CreatePRNPage />} />
            <Route exact path="/prn/:id" element={<ViewPRNPage />} />
            <Route path="/grn/create" element={<CreateGRNPage />} />
            <Route path="/po/create/:prnid" element={<CreatePOPage />} />
            <Route path="/po/:id" element={<ViewPOPage />} />
            <Route path="/giir/create/:grnid" element={<CreateGIIRPage />} />
            <Route path="/giir/:id" element={<ViewGIIRPage />} />
            <Route path="/ipoe/create" element={<IncomingPurchaseOrderEntry />} />
            <Route path="/ipoe/view/:id" element={<IncomingPurchaseOrderEntryView />} />
            <Route path="/ipoe/edit/:id" element={<IncomingPurchaseOrderEntryEdit />} />
            <Route path="/ipoe" element={<IncomingPOListing />} />
            <Route path="/iep/create/:ipoid" element={<InvoiceEntryPage />} />
            <Route path="/iep/view/:invoiceid" element={<InvoiceEntryPageView />} />
            <Route path="/iep/edit/:invoiceid" element={<InvoiceEntryPageEdit />} />
            <Route path="/invlist" element={<InvoiceListing />} />
            <Route path="/popayreq" element={<POPayReq />} />
            <Route path="/popayackn" element={<POPayAckn />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="*" element={<LoginPage />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
