import React, { useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import { v4 as uuid } from "uuid";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InputLabel from "@mui/material/InputLabel";
import InfoIcon from "@mui/icons-material/Info";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";

import { Stack } from "@mui/material";

const TitleText = styled.p`
  color: #2e3e8c;
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
`;

const InfoText = styled.p`
  margin: 0px;
  color: var(--success, #24b73c);
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
export default function ProductGRNForm({
  id,
  onDeletProduct,
  updateProductsData,
  isDisabled,
  selectedProductId,
  quantityTimeLine,
  prnData,
  selectedSupplier,
  products,
  prnproducts,
  specifications
}) {
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [specificationsString, setSpecificationsString] =
    React.useState(specifications);
  const [filteredUnits, setFilteredUnits] = React.useState([]);
  const [quantityDetails, setQuantityDetails] =
    React.useState(quantityTimeLine);
  const splitProductQuantity = (event) => {
    event.preventDefault();
    setQuantityDetails((quantaties) =>
      quantaties.concat([
        {
          quantityId: uuid(),
          quantity: "0",
          unit: "",
          deliveryDate: dayjs(new Date()),
          priceperunit:"",
        },
      ])
    );
  };
  const updateProductInParent = () => {
    if (selectedProduct) {
      const productData = {
        id,
        selectedProductId: selectedProduct.productid,
        name: selectedProduct.products.name,
        measurementmetricid: selectedProduct.measurementmetricid,
        quantityamount: selectedProduct.quantityamount,
        quantityTimeLine: quantityDetails,
        specifications: specificationsString
      };
      updateProductsData(productData);
    }
  };
  const handleSelectProduct = (event) => {
    const product = prnData.prnproductsmappings.find(
      (product) => product.productid === event.target.value
    );
    setSelectedProduct(product);
    updateProductInParent();
    if (product && product.products) {
      if (
        product.products.measurementmetrics &&
        product.products.measurementmetrics.name
      ) {
        setFilteredUnits([product.products.measurementmetrics.name]);
      }
      const quantitiesData = quantityDetails.map((quantity) => ({
        ...quantity,
        unit: product.products.measurementmetrics.name,
      }));
      setQuantityDetails([...quantitiesData]);
    }
  };
  const handleSelectUnit = (event, quantityId) => {
    const updatedQuantities = quantityDetails.map((quantity) => {
      if (quantity.quantityId === quantityId) {
        quantity.unit = event.target.value;
      }
      return quantity;
    });
    setQuantityDetails([...updatedQuantities]);
    updateProductInParent();
  };
  const handleQuantity = (event, quantityId) => {
    const updatedQuantities = quantityDetails.map((quantity) => {
      if (quantity.quantityId === quantityId) {
        quantity.quantity = event.target.value;
        quantity.quantityConverted = event.target.value;
      }
      return quantity;
    });
    setQuantityDetails([...updatedQuantities]);
    updateProductInParent();
  };
  const deleteQuantity = (quantityid) => {
    const quantities = quantityDetails.filter(
      (quantity) => quantity.quantityId !== quantityid
    );
    setQuantityDetails([...quantities]);
    updateProductInParent();
  };
  const handleDateChange = (quantityId, newVal) => {
    const updatedQuantities = quantityDetails.map((quantity) => {
      if (quantity.quantityId === quantityId) {
        quantity.deliveryDate = newVal;
      }
      return quantity;
    });
    setQuantityDetails([...updatedQuantities]);
    updateProductInParent();
  };
  const handlePriceChange = (quantityId, newVal) => {
    const updatedQuantities = quantityDetails.map((quantity) => {
      if (quantity.quantityId === quantityId) {
        quantity.priceperunit = newVal;
      }
      return quantity;
    });
    setQuantityDetails([...updatedQuantities]);
    updateProductInParent();
  };
  const handleSpecifications = (event) => {
    setSpecificationsString(event.target.value);
    updateProductInParent()
  }
  const totalQuantityRequest = quantityDetails.reduce((accumulator, object) => {
    return accumulator + parseFloat(object.quantityConverted);
  }, 0);
  let color = "#FF852D";
  let text = "PO Quantity less than PRN";
  if (
    parseFloat(totalQuantityRequest) >
    parseFloat(selectedProduct ? selectedProduct.quantityamount : 0)
  ) {
    color = "#D34836";
    text = "PO Quantity more than PRN";
  } else if (
    parseFloat(totalQuantityRequest) ===
    parseFloat(selectedProduct ? selectedProduct.quantityamount : 0)
  ) {
    color = "#24B73C";
    text = "PO Quantity matches PRN";
  }
  useEffect(() => {
    if (selectedProductId) {
      const product = prnData.prnproductsmappings.find(
        (product) => product.productid === selectedProductId
      );
      if (
        selectedProduct &&
        product &&
        product.productid !== selectedProduct.productid
      ) {
        setSelectedProduct(product);
        if (
          product.products.measurementmetrics &&
          product.products.measurementmetrics.name
        ) {
          setFilteredUnits([product.products.measurementmetrics.name]);
        }
      } else if (product && selectedProduct === null) {
        setSelectedProduct(product);
        if (
          product.products.measurementmetrics &&
          product.products.measurementmetrics.name
        ) {
          setFilteredUnits([product.products.measurementmetrics.name]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <TitleText>
        Product : {selectedProduct ? selectedProduct.products.name : ""}
      </TitleText>
      <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Product</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedProduct ? selectedProduct.productid : ""}
              label="Product"
              disabled={isDisabled || false}
              onChange={handleSelectProduct}
            >
              {selectedSupplier && selectedSupplier.productsuppliermappings
                ? selectedSupplier.productsuppliermappings.map((product) => (
                    <MenuItem value={product.productid}>
                      {product.product.name}
                    </MenuItem>
                  ))
                : prnproducts.map((product) => (
                    <MenuItem value={product.productid}>
                      {product.products.name}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={8}>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center">
              <InfoText>
                <span style={{ color: color }}>{totalQuantityRequest}</span>
                <span style={{ color: "#333" }}>
                  /
                  {selectedProduct &&
                  selectedProduct.products &&
                  selectedProduct.products.measurementmetrics.name
                    ? `${selectedProduct.quantityamount} ${selectedProduct.products.measurementmetrics.name}`
                    : "0"}
                </span>
              </InfoText>
              <InfoIcon
                sx={{ marginLeft: "10px", marginRight: "5px", color: color }}
              />
              <InfoText
                style={{ fontSize: "16px", fontWeight: "300", color: color }}
              >
                {text}
              </InfoText>
            </Stack>
            {!isDisabled && (
              <IconButton
                sx={{ width: "fit-content" }}
                onClick={() => onDeletProduct(id)}
              >
                <DeleteIcon sx={{ color: "#D34836" }} />
              </IconButton>
            )}
          </Stack>
        </Grid>
      </Grid>
      {quantityDetails &&
        quantityDetails.map((quantity, index) => (
          <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <TextField
                id="quantity"
                label="Product Quantity"
                variant="outlined"
                fullWidth
                disabled={isDisabled || false}
                value={quantity.quantity}
                onChange={(event) => handleQuantity(event, quantity.quantityId)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FormControl
                        variant="standard"
                        fullWidth
                        sx={{
                          minWidth: "50px",
                          borderLeft: "1px solid",
                          paddingLeft: "20px",
                        }}
                      >
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={quantity.unit}
                          defaultValue="kg"
                          label="Product"
                          disabled={isDisabled || false}
                          onChange={(event) =>
                            handleSelectUnit(event, quantity.quantityId)
                          }
                        >
                          {filteredUnits.map((unit) => (
                            <MenuItem value={unit}>{unit}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={3}>
              <Stack direction="row" justifyContent="space-between" spacing={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: "100%" }}
                    label="Delivery Date"
                    value={quantity.deliveryDate}
                    disabled={isDisabled || false}
                    onChange={(newValue) =>
                      handleDateChange(quantity.quantityId, newValue)
                    }
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <TextField
                id="quantity"
                label="Price Per Unit"
                variant="outlined"
                fullWidth
                disabled={isDisabled || false}
                value={quantity.priceperunit}
                onChange={(event) =>
                  handlePriceChange(quantity.quantityId, event.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <Stack direction="row" spacing={2}>
                <TextField
                  id="quantity"
                  label="Total Amount"
                  variant="outlined"
                  fullWidth
                  disabled={isDisabled || false}
                  value={
                    parseFloat(quantity.quantity) *
                    parseFloat(quantity.priceperunit)
                  }
                />
                {index > 0 && (
                  <IconButton
                    disabled={isDisabled || false}
                    onClick={() => deleteQuantity(quantity.quantityId)}
                  >
                    <DeleteOutlineOutlinedIcon sx={{ color: "#D34836" }} />
                  </IconButton>
                )}
              </Stack>
            </Grid>
          </Grid>
        ))}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          id="quantity"
          label="Specifications/Remarks"
          variant="outlined"
          fullWidth
          disabled={isDisabled || false}
          value={specificationsString}
          onChange={(event) => handleSpecifications(event)}
        />
      </Grid>
      <Button
        onClick={(event) => splitProductQuantity(event)}
        startIcon={<AddIcon />}
        variant="contained"
        disabled={isDisabled || false}
        sx={{ color: "#fff", minWidth: "200px", marginTop: "30px" }}
      >
        Split
      </Button>
    </div>
  );
}
