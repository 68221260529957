import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import InfoComponent from "../../components/InfoComponent";
import RawMaterialCard from "../../components/RawMaterialCard";

const HistoryDiv = styled.div`
  display: flex;
  padding-top: 24px;
  border-top: 1px solid var(--Disabled, #848484);
  color: var(--Text, #333);
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 24px;
  margin-bottom: 20px;
`;

function RawMaterials(props) {
  return (
    <Container maxWidth="xl" sx={{ marginBottom: "40px" }}>
      <InfoComponent
        title="Raw Materials"
        backRedirectLink="/qc"
        subtitle="Kindly choose the raw material to create GIIR."
        showBackButton
      />
      {props.rawMaterialsSuccess && (
        <div>
          <Grid container spacing={2}>
            {props.rawMaterials &&
              props.rawMaterials.map((product) =>
                product.grnproductmappings
                  .filter((filterdata) => !filterdata.isGiirApprovedOrRejected)
                  .map((mapping) => (
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <RawMaterialCard mapping={mapping} product={product} />
                    </Grid>
                  ))
              )}
          </Grid>
          <HistoryDiv>History</HistoryDiv>{" "}
          <Grid container spacing={2}>
            {props.rawMaterials &&
              props.rawMaterials.map((product) =>
                product.grnproductmappings
                  .filter((filterdata) => filterdata.isGiirApprovedOrRejected)
                  .map((mapping) => (
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <RawMaterialCard
                        mapping={mapping}
                        product={product}
                        giir={mapping.approvedGiir}
                      />
                    </Grid>
                  ))
              )}
          </Grid>
        </div>
      )}
    </Container>
  );
}
function mapStateToProps(state) {
  const { grn, global } = state;
  return { ...grn, ...global };
}

export default connect(mapStateToProps)(RawMaterials);
