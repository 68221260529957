import React, { useEffect } from "react";
import styled from "styled-components";
import PlaceholderLoading from "react-placeholder-loading";
import { connect, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import {
  setGiirError,
  setGiirSuccess,
  setGiirLoad,
} from "../../redux/reducers/giirReducer";
import GiirCard from "../../components/GiirCard";
import { updateData } from "../../utils/apiClient";

import InfoComponent from "../../components/InfoComponent";

const HistoryDiv = styled.div`
  display: flex;
  padding-top: 24px;
  border-top: 1px solid var(--Disabled, #848484);
  color: var(--Text, #333);
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 24px;
  margin-bottom: 20px;
`;

function GIIR(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    const getPrnsData = async () => {
      dispatch(setGiirLoad());
      const giirs = await updateData("/giirs");
      if (giirs.success) {
        dispatch(setGiirSuccess(giirs.data));
      } else {
        dispatch(setGiirError());
      }
    };
    getPrnsData();
  }, [dispatch]);
  return (
    <Container maxWidth="xl" sx={{ marginBottom: "40px" }}>
      <InfoComponent
        title="GIIR Listing"
        backRedirectLink={
          props.department &&
          props.department.username &&
          `/department/${props.department.username}`
        }
        subtitle="The following are the registered Purchase GIIRs."
        showBackButton
      />
      {props.giirsLoading && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <PlaceholderLoading shape="rect" width="100%" height="400px" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <PlaceholderLoading shape="rect" width="100%" height="400px" />
          </Grid>
        </Grid>
      )}
      {props.giirsSuccess && (
        <div>
          <Grid container spacing={2}>
            {props.giirs &&
              props.giirs
                .filter((giir) => giir.isacceptedbystore === null)
                .map((giir) => (
                  <Grid item xs={12} sm={12} md={6} lg={6} key={giir.giirid}>
                    <GiirCard
                      giir={giir}
                      department={props.department}
                      user={props.user}
                      isShowDelete
                    />
                  </Grid>
                ))}
          </Grid>
          <HistoryDiv>History</HistoryDiv>
          <Grid container spacing={2}>
            {props.giirs &&
              props.giirs
                .filter((giir) => giir.isacceptedbystore !== null)
                .map((giir) => (
                  <Grid item xs={12} sm={12} md={6} lg={6} key={giir.giirid}>
                    <GiirCard giir={giir} user={props.user} />
                  </Grid>
                ))}
          </Grid>
        </div>
      )}
    </Container>
  );
}
function mapStateToProps(state) {
  const { giir, global } = state;
  return { ...giir, ...global };
}

export default connect(mapStateToProps)(GIIR);
