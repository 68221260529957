import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import { connect } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";

import InventoryCard from "../../components/InventoryCard";
import InfoComponent from "../../components/InfoComponent";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function Inventory(props) {
  const [category, setCategory] = React.useState(null);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [products, setProducts] = React.useState([]);
  const handleChange = (event, newValue) => {
    const categoryData = props.inventories.find(
      (eachCategory) => eachCategory.productcategoryid === newValue
    );
    setCategory(categoryData);
    setProducts(categoryData.products);
  };
  const handleSearchQuery = (event) => {
    const filterProducts = products.filter((product) =>
      product.name.toLowerCase().includes(event.target.value)
    );
    setSearchQuery(event.target.value);
    if (event.target.value === "") {
      setProducts([...category.products]);
    } else {
      setProducts([...filterProducts]);
    }
  };
  useEffect(() => {
    if (props.inventorySuccess) {
      setCategory(props.inventories[0]);
      setProducts(props.inventories[0].products);
    }
  }, [props.inventories, props.inventorySuccess]);

  return (
    <Container maxWidth="xl" sx={{ marginBottom: "40px" }}>
      <InfoComponent
        subtitle="The following are the categories and their products"
        title="Inventory"
        showBackButton
        backRedirectLink={
          props.department &&
          props.department.username &&
          `/department/${props.department.username}`
        }
      />
      {props.inventorySuccess && (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={category ? category.productcategoryid : ""}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {props.inventories.map((category) => (
                <Tab
                  label={category.name}
                  value={category.productcategoryid}
                  {...a11yProps(0)}
                />
              ))}
            </Tabs>
          </Box>
          {category && (
            <div style={{ marginTop: "30px" }}>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <TextField
                  placeholder="Search with name..."
                  fullWidth
                  value={searchQuery}
                  onChange={(event) => handleSearchQuery(event)}
                  label="Search with name..."
                  sx={{ margin: "20px 10px" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {products &&
                  products.map((product) => (
                    <Grid item xs={12} sm={12} md={4} lg={6}>
                      <InventoryCard {...product} category={category} />
                    </Grid>
                  ))}
              </Grid>
            </div>
          )}
        </Box>
      )}
    </Container>
  );
}
function mapStateToProps(state) {
  const { global } = state;
  return { ...global };
}

export default connect(mapStateToProps)(Inventory);
