import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "@mui/material";

import whatsappIcon from "../../assets/images/whatsapp.png";
import mailIcon from "../../assets/images/mail.png";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
export default function SocialshareModal({ open, handleClose, emailText,whatsappText }) {
  const handleCloseModal = () => {
    if (handleClose) {
      handleClose();
    }
  };
  return (
    <BootstrapDialog
      onClose={handleCloseModal}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Share the PO
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCloseModal}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <div>Share this PO on Whatsapp or Email</div>
        <Stack
          justifyContent="center"
          alignContent="center"
          spacing={2}
          sx={{ marginTop: "20px" }}
          direction="row"
        >
          <img
            src={whatsappIcon}
            alt="X"
            width="45px"
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.open(
                whatsappText,
                "_blank"
              )
            }
          />
          <a
            href={emailText}
            target="_blank"
            rel="noreferrer"
          >
            <img src={mailIcon} alt="X" width="45px" />
          </a>
        </Stack>
      </DialogContent>
    </BootstrapDialog>
  );
}
