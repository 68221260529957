import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  poLoading: false,
  poSuccess: false,
  poError: false,
  pos: [],
  createPoData: {},
  createPoLoading: false,
  createPoSuccess: false,
  createPoError: false,
  updatePoLoading: false,
  updatePoSuccess: false,
  updatePoError: false,
};

export const prnSlice = createSlice({
  name: "po",
  initialState,
  reducers: {
    setPoLoad: (state) => {
      state.poLoading = true;
      state.poSuccess = false;
      state.poError = false;
      state.pos = [];
    },
    setPoSuccess: (state, action) => {
      state.poLoading = false;
      state.poSuccess = true;
      state.poError = false;
      state.pos = action.payload;
    },
    setPoError: (state) => {
      state.poLoading = false;
      state.poSuccess = false;
      state.poError = true;
      state.pos = [];
    },
    setCreatePoLoad: (state) => {
      state.createPoLoading = true;
      state.createPoSuccess = false;
      state.createPoError = false;
      state.createPoData = {};
    },
    setCreatePoSuccess: (state, action) => {
      state.createPoLoading = false;
      state.createPoSuccess = true;
      state.createPoError = false;
      state.createPoData = action.payload;
    },
    setCreatePoError: (state) => {
      state.createPoLoading = false;
      state.createPoSuccess = false;
      state.createPoError = true;
      state.createPoData = {};
    },
    setUpdatePoLoad: (state) => {
      state.updatePoLoading = true;
      state.updatePoSuccess = false;
      state.updatePoError = false;
    },
    setUpdatePoSuccess: (state, action) => {
      state.updatePoLoading = false;
      state.updatePoSuccess = true;
      state.updatePoError = false;
    },
    setUpdatePoError: (state) => {
      state.updatePoLoading = false;
      state.updatePoSuccess = false;
      state.updatePoError = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPoLoad,
  setPoSuccess,
  setPoError,
  setCreatePoLoad,
  setCreatePoSuccess,
  setCreatePoError,
  setUpdatePoLoad,
  setUpdatePoSuccess,
  setUpdatePoError,
} = prnSlice.actions;

export default prnSlice.reducer;
