import { Container, Grid } from "@mui/material";
import { connect } from "react-redux";
import InfoComponent from "../../components/InfoComponent";
import styled from "styled-components";
import Table from '@mui/material/Table';
import React from "react";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import { useLocation } from 'react-router-dom';
import { getData } from "../../utils/apiClient";
import {useState, useEffect} from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from '@mui/material/CircularProgress';
import { formatDay, formatOnlyDate } from "../../utils/helper";
import { downloadPDF, emailPDF, whatsappPDF, uploadPDFtoS3 } from '../../utils/share';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const TitleText = styled.p`
  color: #24B73C;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
`;

const DearText = styled.p`
    color: black;
    font-family: Open Sans;
    font-size: 24px;
    font-weight: bold;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
`;

const DescText = styled.p`
    color: black;
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 500px;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
`;

const DateText = styled.p`
    color: black;
    font-family: Open Sans;
    font-size: 20px;
    font-weight: bold;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: right;
`;

const TitleName = styled.p`
    color: grey;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
`;

const TitleDetails = styled.p`
    color: black;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
`;

const ThankText = styled.p`
    color: black;
    font-family: Open Sans;
    font-size: 16px;
    letter-spacing: 0em;
    text-align: left;
    fontStyle: 'italic';
`;

const AccDeptText = styled.p`
    color: black;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
`;

const DiclaimerText = styled.p`
    color: grey;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: center;
`;

async function fetchInvoiceIdsData(invoiceid) {
    const getIPOData = await getData("invoice/getreceipt?invoiceid="+invoiceid);
    if(getIPOData.success)return getIPOData.data;
    else return [];
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
}); 

function POPayAckn(){
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [snakbarMessage, setSnakbarMessage] = React.useState(null);
    const [isSnakbarError, seIsSnakbarError] = React.useState(false);
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpen(false);
      };

    function convertNumberToWords(number) {
        const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
        const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
        const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
        const thousands = ['', 'Thousand', 'Lakh', 'Crore'];
    
        function convertGroup(number) {
            let result = '';
            if (number > 99) {
                result += units[Math.floor(number / 100)] + ' Hundred ';
                number %= 100;
            }
            if (number >= 10 && number <= 19) {
                result += teens[number - 10] + ' ';
            } else if (number >= 20) {
                result += tens[Math.floor(number / 10)] + ' ';
                number %= 10;
            }
            if (number > 0) {
                result += units[number] + ' ';
            }
            return result;
        }
    
        if (number === 0) return 'Zero';
    
        let result = '';
        if (number < 0) {
            result += 'Minus ';
            number = Math.abs(number);
        }
    
        let groupCount = 0;
        while (number > 0) {
            const group = number % 1000;
            if (group > 0) {
                result = convertGroup(group) + thousands[groupCount] + ' ' + result;
            }
            number = Math.floor(number / 1000);
            groupCount++;
        }
        
        return result.trim() + " Only";
    }

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const invoiceids = queryParams.get('invoice');

    const [data, setData] = useState(null);
    const [invoices, setInvoices] = useState(null);
    const [companyid, setCompanyid] = useState(null);
    const [companyname, setCompanyName] = useState(null);
    const [companygstnumber, setCompanyGstNumber] = useState(null);
    const [billingaddress, setBillingAddress] = useState(null);
    const [correspondenceaddress, setCorrespondenceAddress] = useState(null);
    const [buyerid, setBuyerId] = useState(null);
    const [buyername, setBuyerName] = useState(null);
    const [buyergstnumber, setBuyerGstNumber] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [address, setAddress] = useState(null);
    const [contactperson, setContactPerson] = useState(null);
    const [total, setTotal] = useState(null);
    const [totaltext, setTotalText] = useState(null);

    function calculateDueDate(dateofinvoice, creditdetails){
        if(creditdetails !== "-"){
            const number = Number(creditdetails);
            const resultDate = new Date(dateofinvoice);
            resultDate.setDate(resultDate.getDate() + number);
            return formatOnlyDate(resultDate);
        }
        return "-";
    }

    useEffect(() => {
        fetchInvoiceIdsData(invoiceids).then(data => {
            setData(data);
            const record = data;
            setInvoices(record.invoices);
            setCompanyid(record.companyid);
            setCompanyName(record.companyname);
            setCompanyGstNumber(record.companygstnumber);
            setBillingAddress(record.billingaddress);
            setCorrespondenceAddress(record.correspondenceaddress);
            setBuyerId(record.buyerid);
            setBuyerName(record.buyername);
            setBuyerGstNumber(record.buyergstnumber);
            setEmail(record.email);
            setPhone(record.phone);
            setAddress(record.address);
            setContactPerson(record.contactperson);
            if(record.invoices){
                const sum = record.invoices.reduce((acc, obj) => acc + obj["grossamount"], 0);
                setTotal(sum);
                setTotalText(convertNumberToWords(sum));
            }

            setIsLoading(false);
            setOpen(true);
            seIsSnakbarError(false);
            setSnakbarMessage("Data Loading for Invoice.");
        });
    }, []);

    const handleDownloadPdf = () => {
        downloadPDF("POPayAckn_"+Date.now());
    };

    const handleMailPdf = async () => {
        setIsLoading(true);
        setOpen(true);
        seIsSnakbarError(false);
        setSnakbarMessage("Preparing Email Content... Please wait...");
        const emailResponse = await uploadPDFtoS3();
        if(emailResponse){
            setIsLoading(false);
            if(emailResponse.success && emailResponse.data){
                emailPDF(emailResponse.data.file.fileurl, "PAYMENT ACKNOWLEDGEMENT");
            } else {
                setOpen(true);
                seIsSnakbarError(true);
                setSnakbarMessage("Something went wrong");
            }
        }
    };

    const handleWhatsappPdf = async () => {
        setIsLoading(true);
        setOpen(true);
        seIsSnakbarError(false);
        setSnakbarMessage("Preparing WhatsApp Content... Please wait...");
        const whatsappResponse = await uploadPDFtoS3();
        if(whatsappResponse){
            setIsLoading(false);
            if(whatsappResponse.success && whatsappResponse.data){
                whatsappPDF(whatsappResponse.data.file.fileurl, "PAYMENT ACKNOWLEDGEMENT");
            } else {
                setOpen(true);
                seIsSnakbarError(true);
                setSnakbarMessage("Something went wrong");
            }
        }
    };


    return (
        <Container maxWidth="xl" sx={{ marginBottom: "40px" }}>
            {isLoading && <CircularProgress />}
            <Snackbar
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                <Alert
                onClose={handleClose}
                severity={isSnakbarError ? "error" : "success"}
                sx={{ width: "100%" }}
                >
                {snakbarMessage}
                </Alert>
            </Snackbar>
            {!isLoading && <>
            <InfoComponent
                title="PO"
                subtitle="Kindly check the details to create a Product Order"
                showBackButton
            />
            <TitleText>PAYMENT ACKNOWLEDGEMENT</TitleText>
            <DateText>{formatDay(new Date())}</DateText>
            <Grid container spacing={2} sx={{ height: '500px', overflow: 'auto' }}>
                <Grid item xs={12} sm={12} md={6} lg={6} sx={{padding: '10px'}}>
                        <Grid container spacing={2}
                            sx={{
                                background: "#4282BF14",
                            }}
                        >
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TitleName>Company Name</TitleName>
                                <TitleDetails>{companyname}</TitleDetails>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TitleName>GSTIN</TitleName>
                                <TitleDetails>{companygstnumber}</TitleDetails>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TitleName>Billing Address (Factory)</TitleName>
                                <TitleDetails>{billingaddress}</TitleDetails>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TitleName>Correspondence Address</TitleName>
                                <TitleDetails>{correspondenceaddress}</TitleDetails>
                            </Grid>
                        </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} sx={{padding: '10px'}}>
                        <Grid container spacing={2}
                            sx={{
                                background: "#30A84A14",
                            }}
                        >
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TitleName>Buyer</TitleName>
                                <TitleDetails>{buyername}</TitleDetails>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TitleName>GSTN Number</TitleName>
                                <TitleDetails>{buyergstnumber}</TitleDetails>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TitleName>Contact Person</TitleName>
                                <TitleDetails>{contactperson}</TitleDetails>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TitleName>Email</TitleName>
                                <TitleDetails>{email}</TitleDetails>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TitleName>Phone Number</TitleName>
                                <TitleDetails>{phone}</TitleDetails>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TitleName>Address</TitleName>
                                <TitleDetails>{address}</TitleDetails>
                            </Grid>
                        </Grid>
                </Grid>
            </Grid>
            <DearText>Dear Sir/Madam,</DearText>
            <DescText>This is to inform you that, the following payment is due. Details are as under:</DescText>
            <TableContainer>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{ border: '0.5px solid black', backgroundColor : '#4BB13F', color: 'white', fontSize : '18px', width: '20%' }}>Reference PO</TableCell>
                            <TableCell sx={{ border: '0.5px solid black', backgroundColor : '#4BB13F', color: 'white', fontSize : '18px', width: '20%' }}>Invoice No.</TableCell>
                            <TableCell sx={{ border: '0.5px solid black', backgroundColor : '#4BB13F', color: 'white', fontSize : '18px', width: '20%' }}>Invoice Date</TableCell>
                            <TableCell sx={{ border: '0.5px solid black', backgroundColor : '#4BB13F', color: 'white', fontSize : '18px', width: '20%' }}>Payment Due Date</TableCell>
                            <TableCell sx={{ border: '0.5px solid black', backgroundColor : '#4BB13F', color: 'white', fontSize : '18px', width: '20%' }}>Invoice Amount</TableCell>
                        </TableRow>
                        {invoices && invoices.map((row) => (
                            <TableRow>
                                <TableCell
                                sx={{
                                    border: '0.5px solid black',
                                    fontSize : '18px',
                                    width: '20%'
                                }}
                                >{row.iponumber}</TableCell>
                                <TableCell
                                sx={{
                                    border: '0.5px solid black',
                                    fontSize : '18px',
                                    width: '20%'
                                }}
                                >{row.invoicenumber}</TableCell>
                                <TableCell
                                sx={{
                                    border: '0.5px solid black',
                                    fontSize : '18px',
                                    width: '20%'
                                }}
                                >{formatOnlyDate(row.dateofinvoice)}</TableCell>
                                <TableCell
                                sx={{
                                    border: '0.5px solid black',
                                    fontSize : '18px',
                                    width: '20%'
                                }}
                                >{calculateDueDate(row.dateofinvoice, row.credit)}</TableCell>
                                <TableCell
                                sx={{
                                    border: '0.5px solid black',
                                    fontSize : '18px',
                                    width: '20%'
                                }}
                                >{row.grossamount}/-</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell
                            sx={{ 
                                width: '1049px',
                                border: '0.5px solid black',
                                fontSize : '18px',
                                width: '80%',
                                color: '#2E3E8C'
                            }}>
                                Total : {totaltext}
                            </TableCell>
                            <TableCell
                            sx={{
                                border: '0.5px solid black',
                                color: '#4BB13F',
                                fontSize : '18px',
                                fontWeight: 'bold',
                                width: '20%'
                            }}>
                                {total} /-
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <Grid container spacing={2} sx={{ fontStyle: 'italic' }}>
                <Grid item xs={2}>
                    Account Number
                    <br />
                    -
                </Grid>
                <Grid item xs={2}>
                    Bank Name
                    <br />
                    -
                </Grid>
                <Grid item xs={2}>
                    Bank Branch
                    <br />
                    -
                </Grid>
                <Grid item xs={2}>
                    IFSC Code
                    <br />
                    -
                </Grid>
            </Grid>
            <br />
            <ThankText>
                Thank you, <br />
                Best regards,
            </ThankText>
            <AccDeptText>
                Accounts Department, <br />
                <b>{companyname}</b>
            </AccDeptText>
            <DiclaimerText>
                This is a computer generated document and thus does not require any signature
            </DiclaimerText>
            <Grid container spacing={2}>
                <Grid item xs={1}>
                    <Button
                        variant="contained"
                        size="large"
                        sx={{
                            backgroundColor : 'darkblue',
                            color: 'white',
                            border: '0.5px solid darkblue',
                            borderRadius: '10px',
                            '&:hover': {
                                backgroundColor: 'darkblue',
                                color: 'white',
                            },
                        }}
                        onClick={handleDownloadPdf}
                        fullWidth
                        endIcon={<CloudDownloadIcon />}
                    >
                    </Button>
                </Grid>
                <Grid item xs={1}>
                    <Button
                        variant="contained"
                        size="large"
                        sx={{
                            backgroundColor : 'darkblue',
                            color: 'white',
                            border: '0.5px solid darkblue',
                            borderRadius: '10px',
                            '&:hover': {
                                backgroundColor: 'darkblue',
                                color: 'white',
                            },
                        }}
                        onClick={handleMailPdf}
                        fullWidth
                        endIcon={<AttachEmailIcon />}
                    >
                    </Button>
                </Grid>
                <Grid item xs={1}>
                    <Button
                        variant="contained"
                        size="large"
                        sx={{
                            backgroundColor : 'darkblue',
                            color: 'white',
                            border: '0.5px solid darkblue',
                            borderRadius: '10px',
                            '&:hover': {
                                backgroundColor: 'darkblue',
                                color: 'white',
                            },
                        }}
                        onClick={handleWhatsappPdf}
                        fullWidth
                        endIcon={<WhatsAppIcon />}
                    >
                    </Button>
                </Grid>
            </Grid>
            </> }
        </Container>
    );
}

function mapStateToProps(state) {
    const { po, global } = state;
    return { ...po, ...global };
}
  
export default connect(mapStateToProps)(POPayAckn);