import { configureStore } from "@reduxjs/toolkit";
import globalReducer from "./reducers/globalReducer";
import prnReducer from "./reducers/prnReducer";
import poReducer from "./reducers/poReducer";
import grnReducer from "./reducers/grnReducer";
import giirReducer from "./reducers/giirReducer";

export const store = configureStore({
  reducer: {
    global: globalReducer,
    prn: prnReducer,
    po: poReducer,
    grn: grnReducer,
    giir: giirReducer,
  },
});
