import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import { styled as muiStyled } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import MuiAlert from "@mui/material/Alert";

import LoginComponent from "../../components/LoginComponent";
import InfoComponent from "../../components/InfoComponent";
import { updateData } from "../../utils/apiClient";
import {
  setGiirLoad,
  setGiirSuccess,
  setGiirError,
  setCreateGiirLoad,
  setCreateGiirSuccess,
  setCreateGiirError,
} from "../../redux/reducers/giirReducer";
import { setUserData } from "../../redux/reducers/globalReducer";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledFormControlLabel = muiStyled((props) => (
  <FormControlLabel {...props} />
))(({ checked, color }) => ({
  ".MuiFormControlLabel-label": checked && {
    color: color,
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
}));

function CreateGIIR(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { grnid } = useParams();
  const [showLoginModal, setShowLoginModal] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [showLightBox, setShowLightBox] = React.useState(false);
  const [lightBoxImage, setLightBoxImage] = React.useState(null);
  const [isSnakbarError, seIsSnakbarError] = React.useState(false);
  const [productData, setProductData] = React.useState({
    invoice: "",
    supplier: {},
    product: {},
    purchaseOrder: {},
    colorAppreance: "",
    pH: "",
    brix: "",
    moistureContent: "",
    nitrogenContent: "",
    proteinContent: "",
    saltContent: "",
    ashValue: "",
    remarks: "",
    isApporved: true,
    isRejected: false,
  });
  const handleChange = (event, title) => {
    setProductData((product) => ({ ...product, [title]: event.target.value }));
  };
  const handleRadioChange = (event) => {
    if (event.target.value === "approve") {
      setProductData((product) => ({
        ...product,
        isApporved: true,
        isRejected: false,
      }));
    } else {
      setProductData((product) => ({
        ...product,
        isApporved: false,
        isRejected: true,
      }));
    }
  };
  const onCreateGiir = async () => {
    try {
      if (props.user && props.user.token) {
        dispatch(setCreateGiirLoad());
        const createGiirData = {
          grnid,
          userid: props.user.userid,
          usertoken: props.user.token,
          colourappearance: productData.colorAppreance,
          ph: productData.pH,
          brix: productData.brix,
          moisturecontent: productData.moistureContent,
          nitrogencontent: productData.nitrogenContent,
          proteincontent: productData.proteinContent,
          saltcontent: productData.saltContent,
          ashvalue: productData.ashValue,
          isapproved: productData.isApporved,
          remarks: productData.remarks,
          productid: productData.product.productid,
        };
        const createGiir = await updateData("/giirs/create", createGiirData);
        if (createGiir.success) {
          dispatch(setCreateGiirSuccess());
          dispatch(setGiirLoad());
          const updatedGiirs = await updateData("/giirs");
          if (updatedGiirs.success) {
            dispatch(setGiirSuccess(updatedGiirs.data));
          } else {
            dispatch(setGiirError());
          }
        } else {
          dispatch(setCreateGiirError());
        }
        dispatch(setUserData());
      } else {
        setShowLoginModal(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleModalClose = (loginSuccess) => {
    setShowLoginModal(false);
    if (loginSuccess) {
      onCreateGiir();
    }
  };
  const handleInvoice = (imageUrl) => {
    if (imageUrl.match(/\.(jpg|jpeg|png|gif)$/i)) {
      setShowLightBox(true);
      setLightBoxImage(imageUrl);
    } else {
      window.open(imageUrl, "_blank");
    }
  };
  useEffect(() => {
    const getGrnDetails = async () => {
      const getGrnData = await updateData("/grns/details", { grnid });
      if (getGrnData.success) {
        setProductData((product) => ({
          ...product,
          invoice: getGrnData.data.invoiceimageurl,
          supplier: getGrnData.data.suppliers,
          product: getGrnData.data.grnproductmappings[0].products,
          purchaseOrder: {
            name: `Purchase Order ${getGrnData.data.poid}`,
            id: getGrnData.data.poid,
            receivedQuantity:
              getGrnData.data.grnproductmappings[0].quantityamount,
            recivedQuantityUnit:
              getGrnData.data.grnproductmappings[0].products.measurementmetrics
                .name,
          },
        }));
      }
    };
    getGrnDetails();
  }, [grnid]);
  useEffect(() => {
    if (props.createGiirSuccess) {
      setOpen(true);
      seIsSnakbarError(false);
      // navigate("/giir");
      window.location.href = "/giir";
    }
    if (props.createGiirError) {
      setOpen(true);
      seIsSnakbarError(true);
    }
  }, [navigate, props.createGiirError, props.createGiirSuccess]);
  return (
    <Container maxWidth="xl" sx={{ marginBottom: "40px" }}>
      {showLoginModal && (
        <LoginComponent
          showLoginModal={showLoginModal}
          handleModalClose={handleModalClose}
        />
      )}
      {showLightBox && (
        <Lightbox
          mainSrc={lightBoxImage}
          onCloseRequest={() => setShowLightBox(false)}
        />
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={isSnakbarError ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {isSnakbarError
            ? "Something went wrong while creating GIIR !"
            : "GIIR Successfully Created !!"}
        </Alert>
      </Snackbar>
      <InfoComponent
        title="GIIR"
        subtitle="Kindly fill the details to create GIIR."
        showBackButton
      />
      <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Button
            variant="outlined"
            fullWidth
            endIcon={<ArrowForwardIcon />}
            onClick={() => handleInvoice(productData.invoice)}
          >
            View Invoice
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            id="supplier.name"
            label="Supplier"
            disabled
            variant="outlined"
            fullWidth
            value={
              productData.supplier && productData.supplier.name
                ? productData.supplier.name
                : ""
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            id="product.name"
            label="Product Name"
            disabled
            variant="outlined"
            fullWidth
            value={
              productData.product && productData.product.name
                ? productData.product.name
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Button
            variant="outlined"
            fullWidth
            endIcon={<ArrowForwardIcon />}
            onClick={() => navigate(`/po/${productData.purchaseOrder.id}`)}
          >
            View PO
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            id="receivedQuantity"
            label="Received Quantity"
            disabled
            variant="outlined"
            fullWidth
            value={
              productData.purchaseOrder &&
              productData.purchaseOrder.receivedQuantity
                ? productData.purchaseOrder.receivedQuantity
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            id="colorAppreance"
            label="Colour Appearance"
            variant="outlined"
            fullWidth
            onChange={(event) => handleChange(event, "colorAppreance")}
            value={productData.colorAppreance}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            id="pH"
            label="pH"
            variant="outlined"
            fullWidth
            onChange={(event) => handleChange(event, "pH")}
            value={productData.pH}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            id="brix"
            label="Brix %"
            variant="outlined"
            fullWidth
            value={productData.brix}
            onChange={(event) => handleChange(event, "brix")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            id="moistureContent"
            label="Moisture Content"
            variant="outlined"
            fullWidth
            value={productData.moistureContent}
            onChange={(event) => handleChange(event, "moistureContent")}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            id="nitrogenContent"
            label="Nitrogen Content"
            variant="outlined"
            fullWidth
            value={productData.nitrogenContent}
            onChange={(event) => handleChange(event, "nitrogenContent")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            id="proteinContent"
            label="Protein Content"
            variant="outlined"
            fullWidth
            value={productData.proteinContent}
            onChange={(event) => handleChange(event, "proteinContent")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            id="saltContent"
            label="Salt Content"
            variant="outlined"
            fullWidth
            value={productData.saltContent}
            onChange={(event) => handleChange(event, "saltContent")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            id="ashValue"
            label="Ash Value"
            variant="outlined"
            fullWidth
            value={productData.ashValue}
            onChange={(event) => handleChange(event, "ashValue")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={12}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={handleRadioChange}
            >
              <StyledFormControlLabel
                checked={productData.isApproved}
                value="approve"
                control={
                  <Radio checked={productData.isApproved} color="success" />
                }
                label="Approved"
                color="#2e7d32"
              />
              <StyledFormControlLabel
                checked={productData.isRejected}
                control={
                  <Radio checked={productData.isRejected} color="error" />
                }
                value="reject"
                label="Rejected"
                color="#d32f2f"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={12}>
          <TextField
            id="Remarks"
            label="Remarks"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            value={productData.remarks}
            onChange={(event) => handleChange(event, "remarks")}
          />
        </Grid>
      </Grid>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Button
          variant="contained"
          size="large"
          onClick={onCreateGiir}
          disabled={props.createGiirLoading}
          endIcon={<AddIcon />}
          sx={{ maxWidth: "312px" }}
        >
          {props.createGiirLoading ? "Processing..." : "Create"}
        </Button>
      </div>
    </Container>
  );
}
function mapStateToProps(state) {
  const { giir, global } = state;
  return { ...giir, ...global };
}

export default connect(mapStateToProps)(CreateGIIR);
