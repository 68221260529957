import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

function GIIRTabCreate({alldata}){
    return(
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">GRN ID</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="GRN ID"
                        >
                        {alldata[8].tabledata &&
                            alldata[8].tabledata.map((grn) => (
                            <MenuItem value={grn.grnid}>{grn.grnid}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Supplier ID</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Supplier ID"
                        >
                        {alldata[24].tabledata &&
                            alldata[24].tabledata.map((supplier) => (
                            <MenuItem value={supplier.supplierid}>{supplier.name} - ({supplier.supplierid})</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                        id="contactperson"
                        variant="outlined"
                        fullWidth
                        label="Colour Appearance"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                        id="contactperson"
                        variant="outlined"
                        fullWidth
                        label="PH"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                        id="contactperson"
                        variant="outlined"
                        fullWidth
                        label="Brix"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                        id="contactperson"
                        variant="outlined"
                        fullWidth
                        label="Moisture Content"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                        id="contactperson"
                        variant="outlined"
                        fullWidth
                        label="Nitrogen Content"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                        id="contactperson"
                        variant="outlined"
                        fullWidth
                        label="Protein Content"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                        id="contactperson"
                        variant="outlined"
                        fullWidth
                        label="Salt Content"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                        id="contactperson"
                        variant="outlined"
                        fullWidth
                        label="Ash Value"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">IS Approved</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="IS Approved"
                        >
                            <MenuItem value="1">Yes</MenuItem>
                            <MenuItem value="0">No</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                        id="contactperson"
                        variant="outlined"
                        fullWidth
                        label="Remarks"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">IS Active</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="IS Active"
                        >
                            <MenuItem value="1">Yes</MenuItem>
                            <MenuItem value="0">No</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Product ID</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Product ID"
                        >
                        {alldata[22].tabledata &&
                            alldata[22].tabledata.map((product) => (
                            <MenuItem value={product.productid}>{product.name} - ({product.productid})</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">IS Accepted By Store</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="IS Accepted By Store"
                        >
                            <MenuItem value="1">Yes</MenuItem>
                            <MenuItem value="0">No</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">User ID</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="User ID"
                        >
                        {alldata[26].tabledata &&
                            alldata[26].tabledata.map((user) => (
                            <MenuItem value={user.userid}>{user.username} - ({user.userid})</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={5} lg={5}></Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Button
                        variant="contained"
                        size="large"
                        style={{
                            width: '100%',
                        }}
                        endIcon={<AddIcon />}
                    >
                    Create
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5}></Grid>
            </Grid>
        </div>
    );
}

function mapStateToProps(state) {
    const { po, global } = state;
    return { ...po, ...global };
}
  
export default connect(mapStateToProps)(GIIRTabCreate);