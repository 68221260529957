export const convertFromBaseUnits = (quantity, unit) => {
  if (unit === "KG") {
    quantity = parseFloat(quantity) * 1000;
  } else if (unit === "Ton") {
    quantity = parseFloat(quantity) * 1000000;
  } else if (unit === "Milliliter") {
    quantity = parseFloat(quantity) / 1000;
  }
  return quantity;
};
export const convertToBaseUnits = (quantity, unit) => {
  if (unit === "KG") {
    quantity = parseFloat(quantity) / 1000;
  } else if (unit === "Ton") {
    quantity = parseFloat(quantity) / 1000000;
  } else if (unit === "Milliliter") {
    quantity = parseFloat(quantity) * 1000;
  }
  return quantity;
};
export const convertToNearestTonOrKg = (amount) => {
  // Convert grams to kilograms
  const kilograms = parseFloat(amount) / 1000;
  if (amount < 1000) {
    return `${amount} grams`;
  }
  if (kilograms >= 1000) {
    const nearestTonInTons = parseFloat(amount) / 1000000;
    return `${nearestTonInTons} Ton`;
  } else {
    return `${kilograms} Kg`;
  }
};
export const convertToNearestToKg = (amount) => {
  // Convert grams to kilograms
  const kilograms = parseFloat(amount) / 1000;
  if (amount < 1000) {
    return `${amount}`;
  }
  return kilograms;
};
