import React, { useEffect } from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import { connect, useDispatch } from "react-redux";
import PlaceholderLoading from "react-placeholder-loading";
import Container from "@mui/material/Container";
import "react-image-lightbox/style.css";
import {
  setPrnLoad,
  setPrnSuccess,
  setPrnError,
} from "../../redux/reducers/prnReducer";
import PRNCard from "../../components/PRNCard";
import { updateData } from "../../utils/apiClient";
import InfoComponent from "../../components/InfoComponent";


const HistoryDiv = styled.div`
  display: flex;
  padding-top: 24px;
  border-top: 1px solid var(--Disabled, #848484);
  color: var(--Text, #333);
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 24px;
  margin-bottom: 20px;
`;
function PRN(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    const getPrnsData = async () => {
      dispatch(setPrnLoad());
      const prns = await updateData("/prns");
      if (prns.success) {
        dispatch(setPrnSuccess(prns.data));
      } else {
        dispatch(setPrnError());
      }
    };
    getPrnsData();
  }, [dispatch]);
  return (
    <Container maxWidth="xl" sx={{ marginBottom: "40px" }}>
      <InfoComponent
        title="PRN Listing"
        backRedirectLink={
          props.department && `/department/${props.department.username}`
        }
        subtitle="Kindly choose the Purchase Requisition Note to create Purchase Order."
        showBackButton
      />
      {props.prnsLoading && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <PlaceholderLoading shape="rect" width="100%" height="400px" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <PlaceholderLoading shape="rect" width="100%" height="400px" />
          </Grid>
        </Grid>
      )}
      {props.prnsSuccess && (
        <div>
          <Grid container spacing={2}>
            {props.prns &&
              props.prns
                .filter((prn) => prn.pos === null || prn.pos === undefined)
                .map((produt) => (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <PRNCard produt={produt} isLive />
                  </Grid>
                ))}
          </Grid>
          <HistoryDiv>History</HistoryDiv>
          <Grid container spacing={2}>
            {props.prns &&
              props.prns
                .filter((prn) => prn.pos)
                .map((produt) => (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <PRNCard produt={produt} />
                  </Grid>
                ))}
          </Grid>
        </div>
      )}
    </Container>
  );
}
function mapStateToProps(state) {
  const { prn, global } = state;
  return { ...prn, ...global };
}

export default connect(mapStateToProps)(PRN);
