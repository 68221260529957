import React from "react";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import { styled as muiStyled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import Stack from "@mui/material/Stack";

import LoginComponent from "../../components/LoginComponent";
import LoginComponentDelete from "../../components/LoginComponent";
import { updateData } from "../../utils/apiClient";
import {
  setGiirError,
  setGiirSuccess,
  setGiirLoad,
} from "../../redux/reducers/giirReducer";
import { setUserData } from "../../redux/reducers/globalReducer";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledFormControlLabel = muiStyled((props) => (
  <FormControlLabel {...props} />
))(({ checked, color }) => ({
  ".MuiFormControlLabel-label": checked
    ? {
        color: color,
        fontFamily: "Open Sans",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
      }
    : {
        fontFamily: "Open Sans",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
      },
}));

const ProductTitle = styled.p`
  font-size: 24px;
  font-family: "Open Sans";
  font-weight: 700;
  color: #333;
  margin: 0px;
`;
const ProducSubtTitle = styled.p`
  font-size: 18px;
  font-family: "Open Sans";
  font-weight: 400;
  color: #333;
  margin: 0px;
`;
const PRNContainer = styled.div`
  border-radius: 16px;
  border: 1px solid var(--Disabled, #848484);
  background: #fff;
  padding: 24px;
`;
const StyledLinkText = styled.p`
  color: var(--Text, #333);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
  text-decoration-line: underline;
`;
const StyledLink = styled(Link)`
  color: var(--Text, #333);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration-line: underline;
`;

function GiirCard(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [snakbarMessage, setSnakbarMessage] = React.useState(null);
  const [isSnakbarError, seIsSnakbarError] = React.useState(false);
  const [showLoginModal, setShowLoginModal] = React.useState(false);
  const [showLoginModalDelete, setShowLoginModalDelete] = React.useState(false);
  const [showLightBox, setShowLightBox] = React.useState(false);
  const [lightBoxImage, setLightBoxImage] = React.useState(null);
  const { giir, isShowDelete } = props;
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleInvoice = (imageUrl) => {
    if (imageUrl.match(/\.(jpg|jpeg|png|gif)$/i)) {
      setShowLightBox(true);
      setLightBoxImage(imageUrl);
    } else {
      window.open(imageUrl, "_blank");
    }
  };
  const handleAcceptenceBystore = async () => {
    if (props.user && props.user.token) {
      const acceptGiirData = {
        userid: props.user.userid,
        usertoken: props.user.token,
        giirid: giir.giirid,
        isacceptedbystore: giir.isapproved === 1 ? true : false,
      };
      const updateResp = await updateData(
        "giirs/toggleStoreAcceptence",
        acceptGiirData
      );
      if (updateResp.success) {
        setSnakbarMessage("GIIR Successfully Accepted !");
        setOpen(true);
        seIsSnakbarError(false);
        const giirs = await updateData("giirs");
        if (giirs.success) {
          dispatch(setGiirSuccess(giirs.data));
        } else {
          dispatch(setGiirError());
        }
      } else {
        setSnakbarMessage("Something went wrong while Accepting GIIR !");
        setOpen(true);
        seIsSnakbarError(true);
      }
      dispatch(setUserData());
    } else {
      setShowLoginModal(true);
    }
  };
  const handleModalClose = (loginSuccess) => {
    try {
      setShowLoginModal(false);
      if (loginSuccess) {
        handleAcceptenceBystore();
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleModalCloseDelete = (loginSuccess) => {
    try {
      setShowLoginModalDelete(false);
      if (loginSuccess) {
        handleDeleteGiir();
      } else {
        setDeleteLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setDeleteLoading(false);
    }
  };
  const handleDeleteGiir = async () => {
    setDeleteLoading(true);
    if (props.user && props.user.token) {
      const deleteGiir = await updateData("giirs/delete", {
        giirid: giir.giirid,
        userid: props.user.userid,
        usertoken: props.user.token,
      });
      if (deleteGiir.success) {
        setOpen(true);
        seIsSnakbarError(false);
        setSnakbarMessage("Giir Deleted Successfully !");
        dispatch(setGiirLoad());
        const giirs = await updateData("giirs");
        if (giirs.success) {
          dispatch(setGiirSuccess(giirs.data));
        } else {
          dispatch(setGiirError());
        }
      } else {
        setOpen(true);
        seIsSnakbarError(true);
        setSnakbarMessage("Something went wrong while deleting Giir !");
      }
      dispatch(setUserData());
      setDeleteLoading(false);
    } else {
      setShowLoginModalDelete(true);
    }
  };
  let receivedQunatity = "0";
  if (giir.grns) {
    const product = giir.grns.grnproductmappings.find(
      (mapping) => mapping.productid === giir.productid
    );
    if (product && product.quantityamount) {
      receivedQunatity = `${product.quantityamount} ${giir.product.measurementmetrics.name}`;
    }
  }
  return (
    <PRNContainer>
      {showLightBox && (
        <Lightbox
          mainSrc={lightBoxImage}
          onCloseRequest={() => setShowLightBox(false)}
        />
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={isSnakbarError ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {snakbarMessage}
        </Alert>
      </Snackbar>
      {showLoginModal && (
        <LoginComponent
          showLoginModal={showLoginModal}
          handleModalClose={handleModalClose}
        />
      )}
      {showLoginModalDelete && (
        <LoginComponentDelete
          showLoginModal={showLoginModalDelete}
          handleModalClose={handleModalCloseDelete}
        />
      )}
      <Stack direction="row" spacing={4} alignItems="flex-start">
        <img
          src={giir.grnMapping.productimageurl}
          alt="placeholder"
          width="300px"
          style={{ borderRadius: "8px" }}
        />
        <Stack direction="column" spacing={2} width="100%">
          <ProductTitle>{giir.product && giir.product.name}</ProductTitle>
          <ProducSubtTitle style={{ fontSize: "24px" }}>
            {giir.grns && giir.grns.suppliers && giir.grns.suppliers.name}
          </ProducSubtTitle>
          <Stack direction="row">
            <ProductTitle style={{ fontSize: "18px" }}>
              Received Quantity:
            </ProductTitle>
            <ProducSubtTitle style={{ marginLeft: "5px" }}>
              {receivedQunatity}
            </ProducSubtTitle>
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Stack spacing={3}>
                <Stack direction="row">
                  <ProductTitle style={{ fontSize: "18px" }}>
                    Color
                  </ProductTitle>
                  <ProducSubtTitle style={{ marginLeft: "5px" }}>
                    {giir.colourappearance}
                  </ProducSubtTitle>
                </Stack>
                <Stack direction="row">
                  <ProductTitle style={{ fontSize: "18px" }}>pH :</ProductTitle>
                  <ProducSubtTitle style={{ marginLeft: "5px" }}>
                    {giir.ph}
                  </ProducSubtTitle>
                </Stack>
                <Stack direction="row">
                  <ProductTitle style={{ fontSize: "18px" }}>
                    Brix% :
                  </ProductTitle>
                  <ProducSubtTitle style={{ marginLeft: "5px" }}>
                    {giir.brix}
                  </ProducSubtTitle>
                </Stack>
                <Stack direction="row">
                  <ProductTitle style={{ fontSize: "18px" }}>
                    Moisture:
                  </ProductTitle>
                  <ProducSubtTitle style={{ marginLeft: "5px" }}>
                    {giir.moisturecontent}
                  </ProducSubtTitle>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Stack spacing={3}>
                <Stack direction="row">
                  <ProductTitle style={{ fontSize: "18px" }}>
                    Nitrogen :
                  </ProductTitle>
                  <ProducSubtTitle style={{ marginLeft: "5px" }}>
                    {giir.nitrogencontent}
                  </ProducSubtTitle>
                </Stack>
                <Stack direction="row">
                  <ProductTitle style={{ fontSize: "18px" }}>
                    Protein :
                  </ProductTitle>
                  <ProducSubtTitle style={{ marginLeft: "5px" }}>
                    {giir.proteincontent}
                  </ProducSubtTitle>
                </Stack>
                <Stack direction="row">
                  <ProductTitle style={{ fontSize: "18px" }}>
                    Salt:
                  </ProductTitle>
                  <ProducSubtTitle style={{ marginLeft: "5px" }}>
                    {giir.saltcontent}
                  </ProducSubtTitle>
                </Stack>
                <Stack direction="row">
                  <ProductTitle style={{ fontSize: "18px" }}>Ash:</ProductTitle>
                  <ProducSubtTitle style={{ marginLeft: "5px" }}>
                    {giir.ashvalue}
                  </ProducSubtTitle>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Stack direction="row" spacing={1}>
            <InsertDriveFileOutlinedIcon />
            <StyledLinkText
              onClick={() =>
                handleInvoice(giir.grns ? giir.grns.invoiceimageurl : "")
              }
            >
              View Invoice
            </StyledLinkText>
          </Stack>
          {giir.grns && giir.grns.poid && (
            <Stack direction="row" spacing={1}>
              <PictureAsPdfOutlinedIcon />
              <StyledLink to={`/po/${giir.grns.poid}`}>
                View Purchase Order
              </StyledLink>
            </Stack>
          )}
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <StyledFormControlLabel
                checked={giir.isApproved}
                control={
                  <Radio checked={giir.isapproved === 1} color="success" />
                }
                label="Approved"
                color="#2e7d32"
              />
              <StyledFormControlLabel
                value={giir.isapproved === 0}
                checked={giir.isapproved === 0}
                control={
                  <Radio checked={giir.isapproved === 0} color="error" />
                }
                label="Rejected"
                color="#d32f2f"
              />
            </RadioGroup>
          </FormControl>
          <Stack direction="row" spacing={2}>
            {isShowDelete && (
              <Button
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                onClick={() => navigate(`/giir/${giir.giirid}`)}
                endIcon={<EditIcon />}
              >
                Edit
              </Button>
            )}
            <Button
              variant="contained"
              size="large"
              fullWidth
              color="secondary"
              onClick={() => navigate(`/giir/${giir.giirid}`)}
              endIcon={<VisibilityIcon />}
            >
              View
            </Button>
          </Stack>
          {giir.isacceptedbystore === null ? (
            <div>
              {props.department && props.department.username === "store" ? (
                giir.isapproved === 1 ? (
                  <Button
                    variant="contained"
                    color="success"
                    size="large"
                    fullWidth
                    onClick={handleAcceptenceBystore}
                  >
                    Accept
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="error"
                    size="large"
                    fullWidth
                    onClick={handleAcceptenceBystore}
                  >
                    Replace Product
                  </Button>
                )
              ) : giir.isapproved === 1 ? (
                <Button
                  variant="contained"
                  color="success"
                  size="large"
                  fullWidth
                  disabled
                  onClick={handleAcceptenceBystore}
                >
                  Pending Acceptance by Store
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="error"
                  size="large"
                  fullWidth
                  disabled
                  onClick={handleAcceptenceBystore}
                >
                  Pending Replacement by Store
                </Button>
              )}
            </div>
          ) : giir.isacceptedbystore === 1 ? (
            <Button variant="outlined" color="success" size="large" fullWidth>
              Accepted By Store
            </Button>
          ) : (
            <Button variant="outlined" color="error" size="large" fullWidth>
              Replaced by Store
            </Button>
          )}
          {props.isShowDelete && (
            <Button
              variant="outlined"
              size="large"
              color="error"
              disabled={deleteLoading}
              onClick={handleDeleteGiir}
              fullWidth
              endIcon={<DeleteIcon />}
            >
              {deleteLoading ? "Deleting..." : "Delete GIIR"}
            </Button>
          )}
        </Stack>
      </Stack>
    </PRNContainer>
  );
}
function mapStateToProps(state) {
  const { global } = state;
  return { ...global };
}

export default connect(mapStateToProps)(GiirCard);
