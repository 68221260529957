import React, { useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InfoIcon from "@mui/icons-material/Info";
import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { updateFormData } from "../../utils/apiClient";
import { Stack } from "@mui/material";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";


const Accordion = muiStyled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const VisuallyHiddenInput = muiStyled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const AccordionSummary = muiStyled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#4282BF",
  borderRadius: "8px 8px 0px 0px",
  color: "#F1F1F1",
  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(270deg)",
    color: "#F1F1F1 !important",
  },

  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    color: "#F1F1F1 !important",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const AccordionDetails = muiStyled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  border: "1px solid #4282BF",
  borderRadius: "0px 0px 8px 8px",
}));

const InfoText = styled.p`
  margin: 0px;
  color: var(--success, #24b73c);
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export default function ProductGRNForm({
  selectedPo,
  quantity,
  quantityConverted,
  unit,
  productid,
  delteProduct,
  selectProductValue,
  isDisabled,
  product,
  selectedSupplier,
  productimageurl,
  isView,
}) {
  const [expanded, setExpanded] = React.useState("panel1");
  const [showLightBox, setShowLightBox] = React.useState(false);
  const [lightBoxImage, setLightBoxImage] = React.useState(null);
  const [productImg, setProductImg] = React.useState(null);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [filteredUnits, setFilteredUnits] = React.useState([]);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleSelectProduct = (event) => {
    const getProduct = selectedPo.products.find(
      (product) => product.productid === event.target.value
    );
    if (getProduct) {
      if (
        getProduct.measurementmetricname &&
        getProduct.measurementmetricname
      ) {
        setFilteredUnits([getProduct.measurementmetricname]);
        selectProductValue(productid, getProduct.measurementmetricname, "unit");
      }
      setSelectedProduct(getProduct);
      selectProductValue(productid, getProduct.productid, "product");
      selectProductValue(
        productid,
        getProduct.measurementmetricid,
        "measurementmetricid"
      );
    }
  };
  const handleSelectUnit = (event) => {
    selectProductValue(productid, event.target.value, "unit");
    if (parseFloat(quantity) > 0) {
      selectProductValue(productid, quantity, "quantityConverted");
    }
  };
  const handleQuantity = (event) => {
    selectProductValue(productid, event.target.value, "quantity");
    selectProductValue(productid, event.target.value, "quantityConverted");
  };
  const handleProductsImg = async (event) => {
    try {
      if (event.target.files[0]) {
        const imageUrl = URL.createObjectURL(event.target.files[0]);
        setProductImg({
          name: event.target.files[0].name,
          size: event.target.files[0].size,
          imageUrl,
          s3Url: null,
          uploadFileTrue: true,
          uploadFileSuccess: false,
        });
        const fileUploadData = new FormData();
        fileUploadData.append("file", event.target.files[0]);
        fileUploadData.append("path", "grn/productphoto");
        const fileUploadResp = await updateFormData(
          "files/upload",
          fileUploadData
        );
        if (fileUploadResp.success && fileUploadResp.data) {
          setProductImg((invoice) => ({
            ...invoice,
            s3Url: fileUploadResp.data.file.fileurl,
            uploadFileTrue: false,
            uploadFileSuccess: true,
          }));
          selectProductValue(
            productid,
            fileUploadResp.data.file.fileurl,
            "productimageurl"
          );
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    if (selectedPo) {
      const getProduct = selectedPo.products.find(
        (productData) => productData.productid === product
      );
      if (getProduct) {
        setSelectedProduct(getProduct);
        if (
          getProduct.measurementmetricname &&
          getProduct.measurementmetricname
        ) {
          setFilteredUnits([getProduct.measurementmetricname]);
          selectProductValue(
            productid,
            getProduct.measurementmetricname,
            "unit"
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productid, selectedPo]);
  let pendingAQuantity = 0;
  if (selectedProduct && selectedProduct.poQuantityamount) {
    pendingAQuantity =
      selectedProduct.poQuantityamount - selectedPo.grnQuantity;
  }
  if (isView && selectedProduct && selectedProduct.poQuantityamount) {
    pendingAQuantity = selectedProduct.poQuantityamount;
  }
  let color = "#FF852D";
  let text = "GRN Quantity less than PO";
  if (parseFloat(quantityConverted) > pendingAQuantity) {
    color = "#D34836";
    text = "GRN Quantity more than PO";
  } else if (parseFloat(quantityConverted) === pendingAQuantity) {
    color = "#24B73C";
    text = "GRN Quantity matches PO";
  }
  console.log("selectedPo", selectedPo);
  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      {showLightBox && (
        <Lightbox
          mainSrc={lightBoxImage}
          onCloseRequest={() => setShowLightBox(false)}
        />
      )}
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        {selectedProduct ? selectedProduct.name : " Product - 1"}
        {!isDisabled && (
          <IconButton
            sx={{ width: "fit-content" }}
            onClick={() => delteProduct(productid)}
          >
            <DeleteIcon sx={{ color: "#f1f1f1" }} />
          </IconButton>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Product</InputLabel>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedProduct ? selectedProduct.productid : ""}
                disabled={isDisabled || selectedPo === null}
                label="Product"
                onChange={handleSelectProduct}
              >
                {selectedPo &&
                  selectedPo.products.map((product) => (
                    <MenuItem value={product.productid}>
                      {product.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              id="quantity"
              label="Product Quantity"
              variant="outlined"
              fullWidth
              disabled={isDisabled || false}
              value={quantity}
              onChange={handleQuantity}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FormControl
                      variant="standard"
                      fullWidth
                      sx={{
                        minWidth: "50px",
                        borderLeft: "1px solid",
                        paddingLeft: "20px",
                      }}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={unit}
                        defaultValue="kg"
                        label="Product"
                        onChange={handleSelectUnit}
                      >
                        {filteredUnits &&
                          filteredUnits.map((unit) => (
                            <MenuItem value={unit}>{unit}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Stack direction="row" spacing={2}>
              <Button
                fullWidth
                disabled={productimageurl === ""}
                sx={{ height: "50px" }}
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                onClick={() => {
                  setShowLightBox(true);
                  setLightBoxImage(productimageurl);
                }}
              >
                View Picture
              </Button>
              <Button
                component="label"
                variant="outlined"
                fullWidth
                disabled={isDisabled || false}
                sx={{ height: "50px" }}
                endIcon={<CameraAltOutlinedIcon />}
              >
                Product Photo
                <VisuallyHiddenInput type="file" onChange={handleProductsImg} />
              </Button>
              {productImg && (
                <div style={{ marginTop: "10px" }}>File: {productImg.name}</div>
              )}
            </Stack>
          </Grid>
        </Grid>
        {selectedProduct && (
          <Stack direction="row" alignItems="center" sx={{ marginTop: "20px" }}>
            <InfoText>
              <span style={{ color: color }}>{quantityConverted}</span>
              <span style={{ color: "#333" }}>
                /
                {selectedProduct && selectedProduct.measurementmetricname
                  ? `${pendingAQuantity} ${selectedProduct.measurementmetricname}`
                  : "0"}
              </span>
            </InfoText>
            <InfoIcon
              sx={{ marginLeft: "10px", marginRight: "5px", color: color }}
            />
            <InfoText
              style={{ fontSize: "16px", fontWeight: "300", color: color }}
            >
              {text}
            </InfoText>
          </Stack>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
