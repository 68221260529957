import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notificationsLoading: false,
  notificationsSuccess: false,
  notificationsError: false,
  notifications: null,
  departmentLoginLoading: false,
  departmentLoginSuccess: false,
  departmentLoginError: false,
  department: null,
  user: null,
  userLoginLoading: false,
  userLoginSuccess: false,
  userLoginError: false,
  departmentToken: null,
  departmentLoading: false,
  departmentSuccess: false,
  departmentError: false,
  departments: [],
  productsLoading: false,
  productsSuccess: false,
  productsError: false,
  products: [],
  suppliersLoading: false,
  suppliersSuccess: false,
  suppliersError: false,
  suppliers: [],
  rawMaterialsLoading: false,
  rawMaterialsSuccess: false,
  rawMaterialsError: false,
  rawMaterials: [],
  inventoryLoading: false,
  inventorySuccess: false,
  inventoryError: false,
  inventories: [],
  companyLoading: false,
  companySuccess: false,
  companyError: false,
  companies: [],
  buyerLoading: false,
  buyerSuccess: false,
  buyerError: false,
  buyers: [],
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setDepartmentLoad: (state) => {
      state.departmentLoading = true;
      state.departmentSuccess = false;
      state.departmentError = false;
      state.departments = [];
    },
    setDepartmentSuccess: (state, action) => {
      state.departmentLoading = false;
      state.departmentSuccess = true;
      state.departmentError = false;
      state.departments = action.payload;
    },
    setDepartmentError: (state) => {
      state.departmentLoading = false;
      state.departmentSuccess = false;
      state.departmentError = true;
      state.departments = [];
    },
    setDepartmentDataLoad: (state, action) => {
      state.departmentLoginLoading = true;
      state.departmentLoginSuccess = false;
      state.departmentLoginError = false;
      state.department = null;
      state.departmentToken = null;
    },
    setDepartmentDataSuccess: (state, action) => {
      state.departmentLoginLoading = false;
      state.departmentLoginSuccess = true;
      state.departmentLoginError = false;
      state.department = action.payload.departmenData;
      state.departmentToken = action.payload.token;
    },
    setDepartmentDataError: (state) => {
      state.departmentLoginLoading = false;
      state.departmentLoginSuccess = false;
      state.departmentLoginError = true;
      state.department = null;
      state.departmentToken = null;
    },
    setProductsLoad: (state) => {
      state.productsLoading = true;
      state.productsSuccess = false;
      state.productsError = false;
      state.products = [];
    },
    setProductsSuccess: (state, action) => {
      state.productsLoading = false;
      state.productsSuccess = true;
      state.productsError = false;
      state.products = action.payload;
    },
    setProductsError: (state) => {
      state.productsLoading = false;
      state.productsSuccess = false;
      state.productsError = true;
      state.products = [];
    },
    setSuppliersLoad: (state) => {
      state.suppliersLoading = true;
      state.suppliersSuccess = false;
      state.suppliersError = false;
      state.suppliers = [];
    },
    setSuppliersSuccess: (state, action) => {
      state.suppliersLoading = false;
      state.suppliersSuccess = true;
      state.suppliersError = false;
      state.suppliers = action.payload;
    },
    setSuppliersError: (state) => {
      state.suppliersLoading = false;
      state.suppliersSuccess = false;
      state.suppliersError = true;
      state.suppliers = [];
    },
    setRawMaterialsLoad: (state) => {
      state.rawMaterialsLoading = true;
      state.rawMaterialsSuccess = false;
      state.rawMaterialsError = false;
      state.rawMaterials = [];
    },
    setRawMaterialsSuccess: (state, action) => {
      state.rawMaterialsLoading = false;
      state.rawMaterialsSuccess = true;
      state.rawMaterialsError = false;
      state.rawMaterials = action.payload;
    },
    setRawMaterialsError: (state) => {
      state.rawMaterialsLoading = false;
      state.rawMaterialsSuccess = false;
      state.rawMaterialsError = true;
      state.rawMaterials = [];
    },
    setInventoriesLoad: (state) => {
      state.inventoryLoading = true;
      state.inventorySuccess = false;
      state.inventoryError = false;
      state.inventories = [];
    },
    setInventoriesSuccess: (state, action) => {
      state.inventoryLoading = false;
      state.inventorySuccess = true;
      state.inventoryError = false;
      state.inventories = action.payload;
    },
    setInventoriesError: (state) => {
      state.inventoryLoading = false;
      state.inventorySuccess = false;
      state.inventoryError = true;
      state.inventories = [];
    },
    setUserLoginLoad: (state) => {
      state.userLoginLoading = true;
      state.userLoginSuccess = false;
      state.userLoginError = false;
      state.user = null;
    },
    setUserLoginSuccess: (state, action) => {
      state.userLoginLoading = false;
      state.userLoginSuccess = true;
      state.userLoginError = false;
      state.user = action.payload;
    },
    setUserLoginError: (state) => {
      state.userLoginLoading = false;
      state.userLoginSuccess = false;
      state.userLoginError = true;
      state.user = null;
    },
    setUserData: (state) => {
      state.userLoginLoading = false;
      state.userLoginSuccess = false;
      state.userLoginError = false;
      state.user = null;
    },
    setNotificationsLoad: (state) => {
      state.notificationsLoading = true;
      state.notificationsSuccess = false;
      state.notificationsError = false;
      state.notifications = null;
    },
    setNotificationsSuccess: (state, action) => {
      state.notificationsLoading = false;
      state.notificationsSuccess = true;
      state.notificationsError = false;
      state.notifications = action.payload;
    },
    setNotificationsError: (state) => {
      state.notificationsLoading = false;
      state.notificationsSuccess = false;
      state.notificationsError = true;
      state.notifications = null;
    },
    setCompaniesLoad: (state) => {
      state.companyLoading = true;
      state.companySuccess = false;
      state.companyError = false;
      state.companies = [];
    },
    setCompaniesSuccess: (state, action) => {
      state.companyLoading = false;
      state.companySuccess = true;
      state.companyError = false;
      state.companies = action.payload;
    },
    setCompaniesError: (state) => {
      state.companyLoading = false;
      state.companySuccess = false;
      state.companyError = true;
      state.companies = [];
    },
    setBuyerLoad: (state) => {
      state.buyerLoading = true;
      state.buyerSuccess = false;
      state.buyerError = false;
      state.buyers = [];
    },
    setBuyerSuccess: (state, action) => {
      state.buyerLoading = false;
      state.buyerSuccess = true;
      state.buyerError = false;
      state.buyers = action.payload;
    },
    setBuyerError: (state) => {
      state.buyerLoading = false;
      state.buyerSuccess = false;
      state.buyerError = true;
      state.buyers = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setDepartmentLoad,
  setDepartmentError,
  setDepartmentSuccess,
  setDepartmentDataLoad,
  setDepartmentDataSuccess,
  setDepartmentDataError,
  setProductsLoad,
  setProductsSuccess,
  setProductsError,
  setSuppliersLoad,
  setSuppliersSuccess,
  setSuppliersError,
  setRawMaterialsLoad,
  setRawMaterialsSuccess,
  setRawMaterialsError,
  setInventoriesLoad,
  setInventoriesSuccess,
  setInventoriesError,
  setUserLoginLoad,
  setUserLoginSuccess,
  setUserLoginError,
  setNotificationsLoad,
  setNotificationsSuccess,
  setNotificationsError,
  setUserData,
  setCompaniesLoad,
  setCompaniesSuccess,
  setCompaniesError,
  setBuyerLoad,
  setBuyerSuccess,
  setBuyerError,
} = homeSlice.actions;

export default homeSlice.reducer;
