import React, { useState, useEffect } from 'react';
import {  connect } from "react-redux";
import Container from "@mui/material/Container";
import InfoComponent from "../../components/InfoComponent";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ICTab from '../../components/ICTab';
import { getData } from '../../utils/apiClient';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
function TabPanel(props) {
    const { children, value, index, data, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <ICTab data={data} />
          </Box>
        )}
      </div>
    );
  }

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
}); 

async function fetchInvoiceData() {
    const getInvoiceData = await getData("invoice/all");
    if(getInvoiceData.success)return getInvoiceData.data;
    else return [];
}

function InvoiceListing(props){
    const [data, setData] = useState(null);

    const [open, setOpen] = React.useState(false);
    const [snakbarMessage, setSnakbarMessage] = React.useState(null);
    const [isSnakbarError, seIsSnakbarError] = React.useState(false);
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpen(false);
      };

    useEffect(() => {
        fetchInvoiceData().then(data => {
            setData(data);
            setOpen(true);
            seIsSnakbarError(false);
            setSnakbarMessage("Invoice Loaded.");
        });
    }, []); 

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Container maxWidth="xl" sx={{ marginBottom: "40px" }}>
            <Snackbar
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                <Alert
                onClose={handleClose}
                severity={isSnakbarError ? "error" : "success"}
                sx={{ width: "100%" }}
                >
                {snakbarMessage}
                </Alert>
            </Snackbar>
            <InfoComponent
                title="Invoice Listing"
                subtitle="The Following are the registered invoices"
                showBackButton
            />
            <div>
                <Tabs
                    value={value} 
                    onChange={handleChange} 
                    aria-label="tabs"
                    sx={{
                        borderBottom: '1px solid #4282BF',
                    }}
                >
                    {data &&
                        data.map((company) => (
                            <Tab
                                label={company.companyname}
                                id="tab-0" 
                                sx={{ 
                                    minWidth: '300px',
                                    fontSize: '20px',
                                    backgroundColor: '#D3D3D3',
                                    color: 'black',
                                    borderTopLeftRadius: '8px',
                                    borderTopRightRadius: '8px',
                                    marginLeft: '0.5%',
                                    marginRight: '0.5%',
                                    '&.Mui-selected': {
                                        backgroundColor: '#4282BF',
                                        color: 'white',
                                    },
                                }} 
                            />
                    ))}
                </Tabs>
                {data &&
                        data.map((company, index) => (
                            <TabPanel value={value} index={index} data={company}>
                                {company.companyname}
                            </TabPanel>            
                ))}
            </div>
        </Container>
    );
}
function mapStateToProps(state) {
    const { global } = state;
    return global;
}
export default connect(mapStateToProps)(InvoiceListing);