import React from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import styled from "styled-components";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import Stack from "@mui/material/Stack";
import { useNavigate, Link } from "react-router-dom";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const ProductTitle = styled.p`
  font-size: 36px;
  font-family: "Open Sans";
  font-weight: 700;
  color: #333;
  margin: 0px;
`;
const ProducSubtTitle = styled.p`
  font-size: 18px;
  font-family: "Open Sans";
  font-weight: 400;
  color: #333;
  margin: 0px;
`;
const PRNContainer = styled.div`
  border-radius: 16px;
  border: 1px solid var(--Disabled, #848484);
  background: #fff;
  padding: 24px;
  margin: 10px;
`;
const StyledLink = styled(Link)`
  color: var(--Text, #333);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration-line: underline;
`;

const StyledLinkText = styled.p`
  color: var(--Text, #333);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
  text-decoration-line: underline;
`;
export default function RawMaterialCard({ mapping, product, giir }) {
  const [showLightBox, setShowLightBox] = React.useState(false);
  const [lightBoxImage, setLightBoxImage] = React.useState(null);
  const handleInvoice = (imageUrl) => {
    if (imageUrl.match(/\.(jpg|jpeg|png|gif)$/i)) {
      setShowLightBox(true);
      setLightBoxImage(imageUrl);
    } else {
      window.open(imageUrl, "_blank");
    }
  };
  const navigate = useNavigate();
  return (
    <PRNContainer>
      {showLightBox && (
        <Lightbox
          mainSrc={lightBoxImage}
          onCloseRequest={() => setShowLightBox(false)}
        />
      )}
      <Stack direction="row" spacing={4}>
        <img
          src={mapping.productimageurl}
          alt="placeholder"
          style={{
            borderRadius: "8px",
            maxWidth: "300px",
            objectFit: "contain",
          }}
        />
        <Stack direction="column" spacing={2} width="100%">
          <ProductTitle>{product.name}</ProductTitle>
          <ProducSubtTitle style={{ fontSize: "24px" }}>
            {mapping.grns.suppliers.name}
          </ProducSubtTitle>
          {mapping && (
            <ProductTitle style={{ fontSize: "18px" }}>
              Received Quantity:{" "}
              {mapping.quantityamount ? mapping.quantityamount : "0"}{" "}
              {product.measurementmetrics
                ? product.measurementmetrics.name
                : ""}
            </ProductTitle>
          )}
          {mapping.grns && (
            <Stack direction="row" spacing={1}>
              <InsertDriveFileOutlinedIcon />
              <StyledLinkText
                onClick={() =>
                  handleInvoice(
                    mapping.grns ? mapping.grns.invoiceimageurl : ""
                  )
                }
              >
                View Invoice
              </StyledLinkText>
            </Stack>
          )}
          {mapping.grns &&
            mapping.grns.grnpomappings &&
            mapping.grns.grnpomappings.length > 0 && (
              <Stack direction="row" spacing={1}>
                <PictureAsPdfOutlinedIcon />
                <StyledLink to={`/po/${mapping.grns.grnpomappings[0].poid}`}>
                  View Purchase Order
                </StyledLink>
              </Stack>
            )}
          {mapping && (
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={() => navigate(`/grn/${mapping.grnid}`)}
              color="secondary"
              endIcon={<VisibilityIcon />}
            >
              View GRN
            </Button>
          )}
          {giir ? (
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => navigate(`/giir/${giir.giirid}`)}
              fullWidth
              endIcon={<VisibilityIcon />}
            >
              View GIIR
            </Button>
          ) : (
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => navigate(`/giir/create/${mapping.grnid}`)}
              fullWidth
              endIcon={<AddIcon />}
            >
              Create GIIR
            </Button>
          )}
        </Stack>
      </Stack>
    </PRNContainer>
  );
}
