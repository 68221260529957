import React, { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from '@mui/material/Box';
import { formatDate } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import GIIR from "../../assets/images/GIIR.jpg";
import GRN from "../../assets/images/GRN.jpg";
import PO from "../../assets/images/PO.jpg";
import PRN from "../../assets/images/PRN.jpg";

function Notifications({open, onClose, popupData}) {
  const navigate = useNavigate();
  const notifications = popupData;

  const handleView = (notification) => {
    onClose();
    if(notification){
      if(notification.notificationtype === "prns"){
        navigate('/prn/'+ notification.typeid);
      } else if(notification.notificationtype === "po"){
        navigate('/po/'+ notification.typeid);
      } else if(notification.notificationtype === "grn"){
        navigate('/grn/'+ notification.typeid);
      } else if(notification.notificationtype === "giir"){
        navigate('/giir/'+ notification.typeid);
      }
    }
  };

  const handleCreate = (notification) => {
    onClose();
    if(notification){
      if(notification.notificationtype === "prns"){
        navigate('/po/create/'+ notification.typeid);
      } else if(notification.notificationtype === "po"){
        navigate('/grn/create');
      } else if(notification.notificationtype === "grn"){
        navigate('/giir/create/'+ notification.typeid);
      } else if(notification.notificationtype === "giir"){
        navigate('/giir');
      }
    }
  };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
          <DialogTitle>
            <Grid container spacing={2} sx={{borderBottom: '1px solid black'}}>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <h2 style={{ color: '#2E3E8C' }}>Notifications</h2>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Button onClick={onClose} fullWidth disabled={notifications.length === 0}>
                  Read All X
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            {notifications && notifications.map((notification) => {
              if (notification.notificationtype === 'prns') {
                return (
                  <div>
                    <Grid key={notification.notificationid} container spacing={2} sx={{ backgroundColor: notification.readstatus === 1 ? '#f5f5f5' : 'white', borderBottom: '1px solid black', marginTop : '1px', marginBottom: '1px'}} >
                      <Grid item xs={12} sm={12} md={8} lg={8}>
                        <span>{formatDate(notification.createdon)}</span>
                        <h2 style={{ color: '#2E3E8C' }}>Raised a PRN</h2>
                        <p>Store have raised a PRN, create a PO against PRN.</p>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Button
                                variant="contained"
                                size="large"
                                color="secondary"
                                fullWidth
                                onClick={() => handleView(notification)}
                                endIcon={<VisibilityIcon />}
                                >
                                View
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Button
                              variant="contained"
                              size="large"
                              fullWidth
                              endIcon={<AddIcon />}
                              onClick={() => handleCreate(notification)}
                            >
                              Create
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                          <div
                              style={{
                                  width: '250px',
                                  height: '250px',
                                  overflow: 'hidden',
                                  position: 'relative',
                              }}
                          >
                              <img
                                  src={PRN}
                                  alt="PRN Image"
                                  style={{
                                      width: '100%',
                                      height: 'auto',
                                      position: 'absolute',
                                      top: '0',
                                      objectFit: 'cover',
                                      objectPosition: 'top',
                                  }}
                              />
                          </div>
                      </Grid>
                    </Grid>
                  </div>
                );
              }
              if (notification.notificationtype === 'po') {
                return (
                  <div>
                    <Grid key={notification.notificationid} container spacing={2} sx={{ backgroundColor: notification.readstatus === 1 ? '#f5f5f5' : 'white', borderBottom: '1px solid black', marginTop : '1px', marginBottom: '1px'}} >
                      <Grid item xs={12} sm={12} md={8} lg={8}>
                        <span>{formatDate(notification.createdon)}</span>
                        <h2 style={{ color: '#2E3E8C' }}>Raised a PO</h2>
                        <p>Sales have raised a PO, create a GRN against PO.</p>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Button
                                variant="contained"
                                size="large"
                                color="secondary"
                                fullWidth
                                onClick={() => handleView(notification)}
                                endIcon={<VisibilityIcon />}
                                >
                                View
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Button
                              variant="contained"
                              size="large"
                              fullWidth
                              endIcon={<AddIcon />}
                              onClick={() => handleCreate(notification)}
                            >
                              Create
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                          {/* <Box
                              sx={{
                                  transform: 'scale(0.25)',
                                  transformOrigin: '0 0',
                                  width: '500%',
                                  height: '500%',
                                  overflow: 'hidden'
                              }}
                          >
                              <iframe
                                  src={"http://localhost:3002/po/" + notification.typeid}
                                  style={{ border: 'none', width: '200%', height: '200%'}}
                              ></iframe>
                          </Box> */}
                          <div
                              style={{
                                  width: '250px',
                                  height: '250px',
                                  overflow: 'hidden',
                                  position: 'relative',
                              }}
                          >
                              <img
                                  src={PO}
                                  alt="PO Image"
                                  style={{
                                      width: '100%',
                                      height: 'auto',
                                      position: 'absolute',
                                      top: '0',
                                      objectFit: 'cover',
                                      objectPosition: 'top',
                                  }}
                              />
                          </div>
                      </Grid>
                    </Grid>
                  </div>
                );
              }
              if (notification.notificationtype === 'grn') {
                return (
                  <div>
                    <Grid key={notification.notificationid} container spacing={2} sx={{ backgroundColor: notification.readstatus === 1 ? '#f5f5f5' : 'white', borderBottom: '1px solid black', marginTop : '1px', marginBottom: '1px'}} >
                      <Grid item xs={12} sm={12} md={8} lg={8}>
                        <span>{formatDate(notification.createdon)}</span>
                        <h2 style={{ color: '#2E3E8C' }}>Raised a GRN</h2>
                        <p>Store have raised a GRN, create a GIIR against GRN.</p>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Button
                                variant="contained"
                                size="large"
                                color="secondary"
                                fullWidth
                                onClick={() => handleView(notification)}
                                endIcon={<VisibilityIcon />}
                                >
                                View
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Button
                              variant="contained"
                              size="large"
                              fullWidth
                              endIcon={<AddIcon />}
                              onClick={() => handleCreate(notification)}
                            >
                              Create
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                          <div
                              style={{
                                  width: '250px',
                                  height: '250px',
                                  overflow: 'hidden',
                                  position: 'relative',
                              }}
                          >
                              <img
                                  src={GRN}
                                  alt="GRN Image"
                                  style={{
                                      width: '100%',
                                      height: 'auto',
                                      position: 'absolute',
                                      top: '0',
                                      objectFit: 'cover',
                                      objectPosition: 'top',
                                  }}
                              />
                          </div>
                      </Grid>
                    </Grid>
                  </div>
                );
              }
              if (notification.notificationtype === 'giir') {
                return (
                  <div>
                    <Grid key={notification.notificationid} container spacing={2} sx={{ backgroundColor: notification.readstatus === 1 ? '#f5f5f5' : 'white', borderBottom: '1px solid black', marginTop : '1px', marginBottom: '1px'}} >
                      <Grid item xs={12} sm={12} md={8} lg={8}>
                        <span>{formatDate(notification.createdon)}</span>
                        <h2 style={{ color: '#2E3E8C' }}>Raised a GIIR</h2>
                        <p>QC have raised a GIIR, create a GIR against GIIR.</p>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Button
                                variant="contained"
                                size="large"
                                color="secondary"
                                fullWidth
                                onClick={() => handleView(notification)}
                                endIcon={<VisibilityIcon />}
                                >
                                View
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Button
                              variant="contained"
                              size="large"
                              fullWidth
                              endIcon={<AddIcon />}
                              onClick={() => handleCreate(notification)}
                            >
                              Create
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                          <div
                              style={{
                                  width: '250px',
                                  height: '250px',
                                  overflow: 'hidden',
                                  position: 'relative',
                              }}
                          >
                              <img
                                  src={GIIR}
                                  alt="GIIR Image"
                                  style={{
                                      width: '100%',
                                      height: 'auto',
                                      position: 'absolute',
                                      top: '0',
                                      objectFit: 'cover',
                                      objectPosition: 'top',
                                  }}
                              />
                          </div>
                      </Grid>
                    </Grid>
                  </div>
                );
              }
          })}
            {notifications && notifications.length === 0 &&
              <h1
                style={{
                  color: 'lightgray',
                  textAlign: 'center'
                }}
              >
                No Notifications
              </h1>
            }
          </DialogContent>
        </Dialog>
      );
}

function mapStateToProps(state) {
    const { global } = state;
    return global;
  }
  
  export default connect(mapStateToProps)(Notifications);