import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import { v4 as uuid } from "uuid";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Container from "@mui/material/Container";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";

import ProductPRNForm from "../../components/ProductPRNForm";
import InfoComponent from "../../components/InfoComponent";
import LoginComponent from "../../components/LoginComponent";
import { updateData } from "../../utils/apiClient";
import {
  setCreatePrnLoad,
  setCreatePrnSuccess,
  setCreatePrnError,
  setPrnLoad,
  setPrnSuccess,
  setPrnError,
} from "../../redux/reducers/prnReducer";
import { setUserData } from "../../redux/reducers/globalReducer";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CreatePRN(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [showLoginModal, setShowLoginModal] = React.useState(false);
  const [isSnakbarError, seIsSnakbarError] = React.useState(false);
  const [products, setProducts] = React.useState([
    {
      category: "",
      product: null,
      quantity: "",
      unit: "",
      productid: uuid(),
    },
  ]);
  const addNewProduct = () => {
    setProducts((product) =>
      product.concat([
        {
          category: "",
          product: "",
          quantity: "",
          unit: "",
          productid: uuid(),
        },
      ])
    );
  };
  const delteProduct = (productid) => {
    const updatedProducts = products.filter(
      (product) => product.productid !== productid
    );
    setProducts([...updatedProducts]);
  };
  const selectProductValue = (productid, value, type) => {
    const updatedProducts = products.map((product) => {
      if (product.productid === productid) {
        product[type] = value;
      }
      return product;
    });
    setProducts([...updatedProducts]);
  };
  const onCreatePrn = async () => {
    if (props.user && props.user.token) {
      const createProductsResp = [];
      if (products && products.length > 0) {
        dispatch(setCreatePrnLoad());
        products.forEach((productResp) => {
          if (productResp.product) {
            createProductsResp.push({
              productid: productResp.product.productid,
              quantityamount: productResp.quantity.toString(),
              measurementmetricid: productResp.product.measurementmetricid,
            });
          }
        });
        if (createProductsResp && createProductsResp.length > 0) {
          const createPrnResp = await updateData("/prns/create", {
            products: createProductsResp,
            userid: props.user.userid,
            usertoken: props.user.token,
          });
          if (createPrnResp.success) {
            dispatch(setCreatePrnSuccess());
            dispatch(setPrnLoad());
            const prns = await updateData("/prns");
            if (prns.success) {
              dispatch(setPrnSuccess(prns.data));
            } else {
              dispatch(setPrnError());
            }
          } else {
            dispatch(setCreatePrnError());
          }
          dispatch(setUserData());
        }
      }
    } else {
      setShowLoginModal(true);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleModalClose = (loginSuccess) => {
    setShowLoginModal(false);
    if (loginSuccess) {
      onCreatePrn();
    }
  };
  useEffect(() => {
    if (props.createPrnSuccess) {
      setOpen(true);
      // navigate("/prn");
      window.location.href = "/prn";
    }
    if (props.createPrnError) {
      setOpen(true);
      seIsSnakbarError(true);
    }
  }, [navigate, props.createPrnError, props.createPrnSuccess]);
  useEffect(() => {
    if (id && props.productsSuccess) {
      const findCategory = props.products.find((category) =>
        category.products.find((product) => product.productid === id)
      );
      console.log("props.productsSuccess", findCategory);
      if (findCategory) {
        const product = findCategory.products.find(
          (product) => product.productid === id
        );
        setProducts([
          {
            category: findCategory.productcategoryid,
            product: product ? product : "",
            quantity: "",
            unit: "",
            productid: uuid(),
          },
        ]);
      }
    }
  }, [id, props.products, props.productsSuccess]);

  return (
    <Container maxWidth="xl" sx={{ marginBottom: "40px" }}>
      {showLoginModal && (
        <LoginComponent
          showLoginModal={showLoginModal}
          handleModalClose={handleModalClose}
        />
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={isSnakbarError ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {isSnakbarError
            ? "Something went wrong while Creating PRN !"
            : "PRN Successfully Created !!"}
        </Alert>
      </Snackbar>
      <InfoComponent
        title="PRN"
        subtitle="Kindly fill the details to create a Product Requisition Note."
        showBackButton
        backRedirectLink={
          props.department &&
          props.department.username &&
          `/department/${props.department.username}`
        }
      />
      <Stack spacing={3}>
        {products &&
          products.map((product) => (
            <ProductPRNForm
              {...product}
              delteProduct={delteProduct}
              selectProductValue={selectProductValue}
            />
          ))}
      </Stack>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Button
          variant="outlined"
          size="large"
          onClick={addNewProduct}
          endIcon={<AddIcon />}
          sx={{ maxWidth: "312px", marginTop: "20px", marginBottom: "30px" }}
        >
          Another Product
        </Button>
        <Button
          variant="contained"
          size="large"
          disabled={props.createPrnLoading || products[0].product === null}
          onClick={onCreatePrn}
          endIcon={<AddIcon />}
          sx={{ maxWidth: "312px" }}
        >
          {props.createPrnLoading ? "Processing..." : "Create"}
        </Button>
      </div>
    </Container>
  );
}
function mapStateToProps(state) {
  const { prn, global } = state;
  return { ...prn, ...global };
}

export default connect(mapStateToProps)(CreatePRN);
