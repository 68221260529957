import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { updateFormData } from './apiClient';
import { v4 as uuidv4 } from 'uuid';

export function downloadPDF(title){
    const input = document.body; // Capture the whole body of the webpage
    html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 210; // A4 width in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save(title+'.pdf');
    });
}

export function whatsappPDF(filename, type){
  const message = `
      ${type} Received
      Click here to view the ${filename}
      Thank you!
      PHOENIX GROUP
    `;
  
  const whatsappShareUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
  window.open(whatsappShareUrl, '_blank');
}

export function emailPDF(filename, type){
  const subject = type;
  const body = `
      ${type} Received
      Click here to view the ${filename}
      Thank you!
      PHOENIX GROUP
    `;
  
  // Encode the subject and body
  const encodedSubject = encodeURIComponent(subject);
  const encodedBody = encodeURIComponent(body);

  // Create the mailto URL
  const mailtoUrl = `mailto:?subject=${encodedSubject}&body=${encodedBody}`;

  // Open the mailto URL
  window.location.href = mailtoUrl;
};

export async function uploadPDFtoS3() {
  const canvas = await html2canvas(document.body);
  const imgData = canvas.toDataURL("image/png");
  const pdf = new jsPDF("p", "pt", "a4", true);
  pdf.addImage(imgData, "PNG", 0, 0, 590, 0, undefined, "FAST");
  // pdf.save("download.pdf");
  const pdfFile = pdf.output("blob");
  return uploadToBackend(pdfFile);
};

const uploadToBackend = async (pdfFile) => {
  const fileUploadData = new FormData();
  fileUploadData.append("file", pdfFile);
  fileUploadData.append("path", "ipo/documents");
  const fileUploadResp = await updateFormData(
    "files/upload",
    fileUploadData
  );
  return fileUploadResp;
};