import React from "react";
import styled from "styled-components";
import { connect, useDispatch } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Carousel } from "react-responsive-carousel";
import "react-image-lightbox/style.css";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import Stack from "@mui/material/Stack";

import { convertToNearestTonOrKg } from "../../utils/convetor";
import {
  setPoLoad,
  setPoSuccess,
  setPoError,
} from "../../redux/reducers/poReducer";
import { updateData } from "../../utils/apiClient";
import LoginComponent from "../../components/LoginComponent";
import SocialshareModal from "../../components/SocialshareModal";
import { setUserData } from "../../redux/reducers/globalReducer";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ProductTitle = styled.p`
  font-size: 24px;
  font-family: "Open Sans";
  font-weight: 700;
  color: #333;
  margin: 0px;
`;
const ProducSubtTitle = styled.p`
  font-size: 15px;
  font-family: "Open Sans";
  font-weight: 400;
  color: #333;
  margin: 0px;
`;
const PRNContainer = styled.div`
  border-radius: 16px;
  border: 1px solid var(--Disabled, #848484);
  background: #fff;
  padding: 24px;
`;
function POCard(props) {
  const { product, isEnded } = props;
  const [open, setOpen] = React.useState(false);
  const [openShareModal, setShareModal] = React.useState(false);
  const [showLoginModal, setShowLoginModal] = React.useState(false);
  const [isSnakbarError, seIsSnakbarError] = React.useState(false);
  const dispatch = useDispatch();
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const navigate = useNavigate();
  const handleDeletePo = async () => {
    setDeleteLoading(true);
    if (props.user && props.user.token) {
      const deleteResp = await updateData("pos/delete", {
        poid: product.poid,
        userid: props.user.userid,
        usertoken: props.user.token,
      });
      if (deleteResp.success) {
        setOpen(true);
        seIsSnakbarError(false);
        dispatch(setPoLoad());
        const pos = await updateData("/pos");
        if (pos.success) {
          dispatch(setPoSuccess(pos.data));
        } else {
          dispatch(setPoError());
        }
      } else {
        setOpen(true);
        seIsSnakbarError(true);
      }
      dispatch(setUserData());
      setDeleteLoading(false);
    } else {
      setShowLoginModal(true);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleCloseModal = () => {
    console.log("in handleCloseModal");
    setShareModal(false);
  };
  const handleModalCloseDelete = (loginSuccess) => {
    try {
      setShowLoginModal(false);
      if (loginSuccess) {
        handleDeletePo();
      } else {
        setDeleteLoading(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <PRNContainer>
      {showLoginModal && (
        <LoginComponent
          showLoginModal={showLoginModal}
          handleModalClose={handleModalCloseDelete}
        />
      )}
      <SocialshareModal
        whatsappText={`https://web.whatsapp.com/send?text=Check generated PO Here - ${product.previewpdflink}`}
        emailText={`mailto:?subject=PO%20is%20Generated&body=Check%20this%20PO - ${product.previewpdflink}`}
        open={openShareModal}
        handleClose={handleCloseModal}
      />
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={isSnakbarError ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {isSnakbarError
            ? "Something went wrong while Deleting PO !"
            : "PO Successfully Deleted !!"}
        </Alert>
      </Snackbar>
      <Stack direction="row" spacing={4}>
        {product.productImages && product.productImages.length > 0 ? (
          <Carousel showThumbs={false}>
            {product.productImages &&
              product.productImages.map((mapping) => (
                <img
                  src={mapping.productimageurl}
                  alt="placeholder"
                  style={{
                    borderRadius: "8px",
                    maxWidth: "180px",
                    objectFit: "contain",
                  }}
                />
              ))}
          </Carousel>
        ) : (
          <img
            src="https://placehold.co/240x343"
            alt="placeholder"
            style={{ borderRadius: "8px" }}
          />
        )}
        <Stack direction="column" spacing={2} width="100%">
          <ProductTitle>Purchase Order #{product.poid}</ProductTitle>
          <ProducSubtTitle style={{ fontSize: "24px" }}>
            {product.suppliers && product.suppliers.name}
          </ProducSubtTitle>
          <Grid container spacing={2}>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <ProductTitle style={{ fontSize: "16px" }}>Product</ProductTitle>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <ProductTitle
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                Qty Requested
              </ProductTitle>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <ProductTitle
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                Delivery Date
              </ProductTitle>
            </Grid>
          </Grid>
          {product.poproductmappings &&
            product.poproductmappings.map(
              (productmapping) =>
                productmapping.productdeliveryschedules &&
                productmapping.productdeliveryschedules.map((mapping) => (
                  <Grid container spacing={2}>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <ProducSubtTitle>
                        {productmapping.products.name &&
                          productmapping.products.name}
                      </ProducSubtTitle>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <ProducSubtTitle style={{ textAlign: "center" }}>
                        {productmapping.products.measurementmetrics.name ===
                        "grams"
                          ? convertToNearestTonOrKg(mapping.quantityamount)
                          : `${mapping.quantityamount && mapping.quantityamount}
                                ${
                                  productmapping.products.measurementmetrics
                                    ? productmapping.products.measurementmetrics
                                        .name
                                    : ""
                                }`}
                      </ProducSubtTitle>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <ProducSubtTitle style={{ textAlign: "center" }}>
                        {new Date(mapping.deliverydate).toLocaleDateString()}
                      </ProducSubtTitle>
                    </Grid>
                  </Grid>
                ))
            )}
          {isEnded ? (
            <Stack direction="row" spacing={2} fullWidth>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={() => navigate(`/po/${product.poid}?isEdit=true`)}
                fullWidth
                endIcon={<EditIcon />}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                size="large"
                fullWidth
                onClick={() => navigate(`/po/${product.poid}`)}
                color="secondary"
                endIcon={<VisibilityIcon />}
              >
                View PO
              </Button>
            </Stack>
          ) : (
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate(`/po/${product.poid}`)}
              color="secondary"
              endIcon={<VisibilityIcon />}
            >
              View PO
            </Button>
          )}
          {isEnded ? (
            <Button
              variant="outlined"
              size="large"
              onClick={() => setShareModal(true)}
              endIcon={<NotificationsActiveIcon />}
            >
              Send Remind
            </Button>
          ) : (
            <Button
              variant="outlined"
              size="large"
              disabled
              endIcon={<NotificationsActiveIcon />}
            >
              Delivered
            </Button>
          )}
          {isEnded === true && (
            <Button
              variant="outlined"
              size="large"
              color="error"
              disabled={deleteLoading}
              onClick={() => handleDeletePo()}
              fullWidth
              endIcon={<DeleteIcon />}
            >
              {deleteLoading ? "Deleting..." : "Delete PO"}
            </Button>
          )}
        </Stack>
      </Stack>
    </PRNContainer>
  );
}
function mapStateToProps(state) {
  const { po, global } = state;
  return { ...po, ...global };
}

export default connect(mapStateToProps)(POCard);