import React, { useEffect } from "react";
import { v4 as uuid } from "uuid";
import Stack from "@mui/material/Stack";
import jsPDF from "jspdf";
import { updateFormData } from "../../utils/apiClient";
import html2canvas from "html2canvas";
import TextField from "@mui/material/TextField";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Table from "@mui/material/Table";
import PlaceholderLoading from "react-placeholder-loading";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TableBody from "@mui/material/TableBody";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Divider from "@mui/material/Divider";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { connect, useDispatch } from "react-redux";
import LoginComponent from "../../components/LoginComponent";
import { useParams, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import Container from "@mui/material/Container";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import ProductPOForm from "../../components/ProductPOForm";
import InfoComponent from "../../components/InfoComponent";
import PoInfoComponent from "../../components/PoInfoComponent";
import SocialshareModal from "../../components/SocialshareModal";
import cresentLogo from "../../assets/images/cresent.png";
import phoenixLogo from "../../assets/images/phoenix-logo.png";

import { updateData } from "../../utils/apiClient";
import {
  setUpdatePoLoad,
  setUpdatePoSuccess,
  setUpdatePoError,
  setPoLoad,
  setPoSuccess,
  setPoError,
} from "../../redux/reducers/poReducer";
import { setUserData } from "../../redux/reducers/globalReducer";
import { Grid } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TitleText = styled.p`
  color: #2e3e8c;
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
`;

const InfoConatiner = styled.div`
  background: #4282bf14;
  padding: 16px;
`;
const TermsText = styled.li`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
`;

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2E3E8C",
    color: "#fff",
    fontSize: 18,
    fontFamily: "Open Sans",
    fontWeight: 400,
    textAlign: "left",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
    fontFamily: "Open Sans",
    fontWeight: 400,
    textAlign: "left",
    border: "1px solid #848484",
    boxShadow: "none",
  },
}));
const StyledTableCellCb = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#4BB13F",
    color: "#fff",
    fontSize: 18,
    fontFamily: "Open Sans",
    fontWeight: 400,
    textAlign: "left",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
    fontFamily: "Open Sans",
    fontWeight: 400,
    textAlign: "left",
    border: "1px solid #848484",
    boxShadow: "none",
  },
}));

function CreatePO(props) {
  const dispatch = useDispatch();
  const [openShareModal, setShareModal] = React.useState(false);
  const { id } = useParams();
  const [showLoginModal, setShowLoginModal] = React.useState(false);
  const [poLoading, setShowPoLoading] = React.useState(false);
  const [allSuppliersData, setAllSuppliersData] = React.useState({
    suppliers: [],
    products: [],
  });
  const [searchParams] = useSearchParams();
  const [open, setOpen] = React.useState(false);
  const [isSnakbarError, seIsSnakbarError] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);
  const [prnData, setPrnData] = React.useState(null);
  const [supplierdData, setSupplierdData] = React.useState([]);
  const [suppliers, setSuppliers] = React.useState({
    id: "supplier1",
    totalQuantity: "0",
    selectedSupplier: null,
    purchaseordernumber: null,
    previewpdflink: null,
    purchaseorderdate: dayjs(new Date()),
    contactperson: "",
    reference: "",
    gst: "",
    paymentTerms: "",
    deliveryschedule: "",
    referencedate: "",
    company: {
      companyid: "",
      correspondenceaddress: "",
      gstnumber: "",
      billingaddress: "",
      name: "",
    },
    selectedSupplierId: "",
    productsState: [
      {
        selectedProductId: "",
        name: "",
        id: "product1",
        quantityRequestd: "",
        measurementmetricid: "",
        specifications: "",
        quantityTimeLine: [
          {
            quantityId: uuid(),
            quantity: "0",
            unit: "",
            quantityConverted: "0",
            priceperunit: "",
            deliveryDate: dayjs(new Date()),
          },
        ],
      },
    ],
  });
  const chckeElement = document.querySelector("#popdf");
  const deleteProduct = (supplierid, productid) => {
    const updatedProducts = suppliers.productsState.filter(
      (product) => product.id !== productid
    );
    const updatedSupplier = {
      ...suppliers,
      productsState: updatedProducts,
    };
    setSuppliers({ ...updatedSupplier });
  };
  const handleCloseModal = () => {
    setShareModal(false);
  };
  const addProduct = () => {
    const productsState = suppliers.productsState.concat([
      {
        selectedProductId: "",
        name: "",
        id: `product${suppliers.productsState.length + 1}`,
        quantityRequestd: "",
        measurementmetricid: "",
        specifications: "",
        quantityTimeLine: [
          {
            quantityId: uuid(),
            quantity: "0",
            unit: "",
            priceperunit: "",
            deliveryDate: dayjs(new Date()),
          },
        ],
      },
    ]);
    const updatedSupplier = {
      ...suppliers,
      productsState,
    };
    setSuppliers({ ...updatedSupplier });
  };
  const onUpdatePo = async () => {
    if (props.user && props.user.token) {
      const createPoData = {
        prnid: suppliers.prnid,
        poid: id,
        supplierid: suppliers.selectedSupplierId,
        contactperson: suppliers.contactperson,
        reference: suppliers.reference,
        gst: suppliers.gst,
        paymentterms: suppliers.paymentTerms,
        purchaseorderdate: new Date(suppliers.purchaseorderdate),
        referencedate: new Date(suppliers.referencedate),
        deliveryschedule: suppliers.deliveryschedule,
        companyid: suppliers.company.companyid,
        products: [],
        userid: props.user.userid,
        usertoken: props.user.token,
      };
      const products = [];
      suppliers.productsState.forEach((product) => {
        let productData = {
          productid: product.selectedProductId,
          specifications: product.specifications,
          deliverSchedules: product.quantityTimeLine.map((quantity) => {
            return {
              productdeliveryscheduleid: quantity.productdeliveryscheduleid,
              quantityamount: quantity.quantityConverted.toString(),
              measurementmetricid: product.measurementmetricid,
              deliverydate: new Date(quantity.deliveryDate).toDateString(),
              priceperunit: quantity.priceperunit,
            };
          }),
        };
        if (product.poproductmappingid) {
          productData = {
            ...productData,
            poproductmappingid: product.poproductmappingid,
          };
        }
        products.push(productData);
      });
      createPoData.products = products;
      dispatch(setUpdatePoLoad());
      const createPrnResp = await updateData("pos/update", createPoData);
      if (createPrnResp.success) {
        let totalQuantity = 0;
        suppliers.productsState.forEach((product) => {
          if (product.quantityTimeLine) {
            product.quantityTimeLine.forEach((schedule) => {
              totalQuantity =
                totalQuantity +
                parseFloat(schedule.priceperunit) *
                  parseFloat(schedule.quantity);
            });
          }
        });
        setSuppliers((suppl) => ({ ...suppl, totalQuantity }));
        dispatch(setUpdatePoSuccess());
        setOpen(true);
        dispatch(setPoLoad());
        const prns = await updateData("pos");
        if (prns.success) {
          dispatch(setPoSuccess(prns.data));
          setDisabled(true);
          setTimeout(function () {
            exportPdf();
          }, 5000);
        } else {
          dispatch(setPoError());
        }
        setDisabled(true);
      } else {
        setOpen(true);
        seIsSnakbarError(true);
        dispatch(setUpdatePoError());
      }
      dispatch(setUserData());
    } else {
      setShowLoginModal(true);
    }
  };
  const handleSelectComoany = (event) => {
    const companyData = props.companies.find(
      (company) => company.companyid === event.target.value
    );
    const suppliersData = {
      ...suppliers,
      company: companyData,
    };
    setSuppliers({ ...suppliersData });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleModalClose = (loginSuccess) => {
    setShowLoginModal(false);
    if (loginSuccess) {
      onUpdatePo();
    }
  };
  const exportPdf = async () => {
    console.log("exporting pdf");
    const chckeElement = document.querySelector("#popdf");
    if (chckeElement) {
      html2canvas(document.querySelector("#popdf")).then(async (canvas) => {
        document.body.appendChild(canvas); // if you want see your screenshot in body.
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "pt", "a4", true);
        pdf.addImage(imgData, "PNG", 0, 0, 590, 0, undefined, "FAST");
        // pdf.save("download.pdf");
        const pdfFile = pdf.output("blob");
        const fileUploadData = new FormData();
        fileUploadData.append("file", pdfFile);
        fileUploadData.append("path", `po/${id}`);
        console.log("suppliers", suppliers, suppliers.purchaseordernumber);
        fileUploadData.append(
          "extenstion",
          `${suppliers.purchaseordernumber.replace("/", "-")}.pdf`
        );
        const fileUploadResp = await updateFormData(
          "files/upload",
          fileUploadData
        );
        if (fileUploadResp.success) {
          const checkDepartmentToken = localStorage.getItem(
            "departmentTokenphoneix"
          );
          const uploadPreview = await updateData("pos/previewupdate", {
            token: checkDepartmentToken,
            poid: id,
            previewpdflink: fileUploadResp.data.file.fileurl,
          });
          if (uploadPreview.success) {
            setSuppliers((suppl) => ({
              ...suppl,
              previewpdflink: fileUploadResp.data.file.fileurl,
            }));
          }
        }
      });
    }
  };
  const downLoadPdf = () => {
    const chckeElement = document.querySelector("#popdf");
    if (chckeElement) {
      html2canvas(document.querySelector("#popdf")).then(async (canvas) => {
        document.body.appendChild(canvas); // if you want see your screenshot in body.
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "pt", "a4", true);
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(
          imgData,
          "PNG",
          0,
          0,
          pdfWidth,
          pdfHeight + 50,
          undefined,
          "FAST"
        );
        pdf.save(`${suppliers.purchaseordernumber.replace("/", "-")}.pdf`);
      });
    }
  };
  const handleTextBoxUpdate = (title, value) => {
    setSuppliers((supply) => ({
      ...supply,
      [title]: value,
    }));
  };
  const onDeletProduct = (productid) => {
    deleteProduct(productid);
  };
  const updateProductsData = (productsData) => {
    if (productsData) {
      let updatedSupplierData = suppliers;
      const productsState = suppliers.productsState.map((product) => {
        if (product.id === productsData.id) {
          product.selectedProductId = productsData.selectedProductId;
          product.name = productsData.name;
          product.quantityRequestd = productsData.quantityRequestd;
          product.quantityTimeLine = productsData.quantityTimeLine;
          product.measurementmetricid = productsData.measurementmetricid;
          product.specifications = productsData.specifications;
        }
        return product;
      });
      console.log("productsState", productsState);
      updatedSupplierData = {
        ...updatedSupplierData,
        productsState,
      };
      setSuppliers({ ...updatedSupplierData });
    }
  };
  const handleSelectSupplier = (event) => {
    const suppliers =
      supplierdData.length > 0 ? supplierdData : allSuppliersData.suppliers;
    const supplier = suppliers.find(
      (supplier) => supplier.supplierid === event.target.value
    );
    setSuppliers((supply) => ({
      ...supply,
      selectedSupplierId: supplier.supplierid,
      selectedSupplier: supplier,
    }));
  };
  useEffect(() => {
    const getPoData = async () => {
      setShowPoLoading(true);
      const poResp = await updateData("/pos/details", { poid: id });
      if (poResp.success) {
        const getPrnData = await updateData("/prns/details", {
          prnid: poResp.data.prnid,
        });

        const getPrnSuppliers = await updateData("/prns/suppliers", {
          prnid: poResp.data.prnid,
        });
        if (getPrnSuppliers.success) {
          setSupplierdData(getPrnSuppliers.data);
          if (getPrnSuppliers.poData) {
            setAllSuppliersData({
              suppliers: getPrnSuppliers.poData.suppliers,
              products: getPrnSuppliers.poData.products,
            });
          }
        }
        if (getPrnData.success) {
          setPrnData(getPrnData.data);
        }
        let totalQuantity = 0;
        const products = poResp.data.poproductmappings.map((product) => {
          let schedules = [
            {
              quantityId: uuid(),
              quantity: "0",
              unit: "",
              quantityConverted: "0",
              deliveryDate: dayjs(new Date()),
              specifications: "",
            },
          ];
          if (product.productdeliveryschedules) {
            schedules = product.productdeliveryschedules.map((schedule) => {
              totalQuantity =
                totalQuantity +
                parseFloat(schedule.priceperunit) *
                  parseFloat(schedule.quantityamount);
              return {
                quantityId: schedule.productdeliveryscheduleid,
                quantity: schedule.quantityamount,
                unit: product.products.measurementmetrics.name,
                quantityConverted: schedule.quantityamount,
                deliveryDate: dayjs(new Date(schedule.deliverydate)),
                productdeliveryscheduleid: schedule.productdeliveryscheduleid,
                priceperunit: schedule.priceperunit,
              };
            });
          }
          const getPrnQuantity = getPrnData.data.prnproductsmappings.find(
            (prnProduct) => prnProduct.productid === product.productid
          );
          return {
            selectedProductId: product.productid,
            poproductmappingid: product.poproductmappingid,
            name: product.products.name,
            id: product.productid,
            quantityRequestd: getPrnQuantity.quantityamount || "0",
            measurementmetricid: product.products.measurementmetricid,
            quantityTimeLine: schedules,
            specifications: product.specifications,
          };
        });
        const poData = {
          id: poResp.data.supplierid,
          prnid: poResp.data.prnid,
          totalQuantity,
          selectedSupplierId: poResp.data.supplierid,
          selectedSupplier: poResp.data.suppliers,
          purchaseorderdate: dayjs(new Date(poResp.data.purchaseorderdate)),
          contactperson: poResp.data.contactperson,
          reference: poResp.data.reference,
          gst: poResp.data.gst,
          paymentTerms: poResp.data.paymentterms,
          deliveryschedule: poResp.data.deliveryschedule,
          referencedate: dayjs(new Date(poResp.data.referencedate)),
          company: poResp.data.company,
          productsState: products,
          previewpdflink: poResp.data.previewpdflink,
          purchaseordernumber: poResp.data.purchaseordernumber,
        };
        setSuppliers({ ...poData });
      }
      setShowPoLoading(false);
    };

    getPoData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const isEdit = searchParams.get("isEdit");
    if (isEdit && isEdit === "true") {
      setDisabled(false);
    }
  }, [searchParams]);
  useEffect(() => {
    if (suppliers.selectedSupplier && suppliers.previewpdflink === null) {
      setTimeout(function () {
        exportPdf();
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chckeElement, suppliers.selectedSupplier, suppliers.previewpdflink]);

  let infoConatinerLeftColor = "#4282BF14";
  let infoContainerRightColor = "#1184F214";
  if (suppliers.company.companyid === "e41d2955-78e5-42a5-86da-7c6fa6361c93") {
    infoConatinerLeftColor = "#221C5C14";
    infoContainerRightColor = "#30A84A14";
  }
  return (
    <Container maxWidth="xl" sx={{ marginBottom: "40px" }}>
      {showLoginModal && (
        <LoginComponent
          showLoginModal={showLoginModal}
          handleModalClose={handleModalClose}
        />
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={isSnakbarError ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {isSnakbarError
            ? "Something went wrong while Updatingg PO !"
            : "PO Successfully Updated !!"}
        </Alert>
      </Snackbar>
      <SocialshareModal
        open={openShareModal}
        handleClose={handleCloseModal}
        whatsappText={`https://web.whatsapp.com/send?text=Check generated PO Here - ${suppliers.previewpdflink}`}
        emailText={`mailto:?subject=PO%20is%20Generated&body=Check%20this%20PO - ${suppliers.previewpdflink}`}
      />
      <InfoComponent
        title="PO"
        backRedirectLink="/po"
        subtitle="Kindly fill the details to update a Product Order."
        showBackButton
      />
      {poLoading ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <PlaceholderLoading shape="rect" width="100%" height="400px" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <PlaceholderLoading shape="rect" width="100%" height="400px" />
          </Grid>
        </Grid>
      ) : (
        <div>
          {isDisabled ? (
            <div
              className="popdf"
              id="popdf"
              style={{ padding: "10px", position: "relative" }}
            >
              <TitleText style={{ textAlign: "center" }}>
                PURCHASE ORDER
              </TitleText>
              <div
                style={{
                  position: "absolute",
                  right: "18px",
                  top: "20px",
                }}
              >
                {suppliers.company.companyid !==
                "e41d2955-78e5-42a5-86da-7c6fa6361c93" ? (
                  <img src={cresentLogo} alt="cresent" width="180px" />
                ) : (
                  <img src={phoenixLogo} alt="cresent" width="230px" />
                )}
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <InfoConatiner style={{ background: infoConatinerLeftColor }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <PoInfoComponent
                          title="Company Name"
                          subtitle={suppliers.company.name}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <PoInfoComponent
                          title="GSTIN"
                          subtitle={suppliers.company.gstnumber}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <PoInfoComponent
                          title="Purchase Order Number"
                          subtitle={suppliers.purchaseordernumber}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <PoInfoComponent
                          title="Purchase Order Date"
                          subtitle={suppliers.purchaseorderdate.toString()}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <PoInfoComponent
                          title="Billing Address (Factory)"
                          subtitle={suppliers.company.billingaddress}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <PoInfoComponent
                          title="Correspondence Address"
                          subtitle={suppliers.company.correspondenceaddress}
                        />
                      </Grid>
                    </Grid>
                  </InfoConatiner>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <InfoConatiner
                    style={{ background: infoContainerRightColor }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <PoInfoComponent
                          title="Supplier/Vendor"
                          subtitle={
                            suppliers.selectedSupplier &&
                            suppliers.selectedSupplier.name
                              ? suppliers.selectedSupplier.name
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <PoInfoComponent
                          title="GSTIN"
                          subtitle={
                            suppliers.selectedSupplier &&
                            suppliers.selectedSupplier.gstnumber
                              ? suppliers.selectedSupplier.gstnumber
                              : ""
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <PoInfoComponent
                          title="Contact Person"
                          subtitle={suppliers.contactperson}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <PoInfoComponent
                          title="Reference/Quotation/PI"
                          subtitle={suppliers.reference}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <PoInfoComponent
                          title="Reference Date"
                          subtitle={suppliers.referencedate.toString()}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <PoInfoComponent
                          title="Email"
                          subtitle={
                            suppliers.selectedSupplier &&
                            suppliers.selectedSupplier.email
                              ? suppliers.selectedSupplier.email
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <PoInfoComponent
                          title="Phone Number"
                          subtitle={
                            suppliers.selectedSupplier &&
                            suppliers.selectedSupplier.phone
                              ? suppliers.selectedSupplier.phone
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <PoInfoComponent
                          title="Address"
                          subtitle={
                            suppliers.selectedSupplier &&
                            suppliers.selectedSupplier.address
                              ? suppliers.selectedSupplier.address
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>
                  </InfoConatiner>
                </Grid>
              </Grid>
              <TableContainer component={Paper} sx={{ marginTop: "30px" }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    {suppliers.company.companyid !==
                    "e41d2955-78e5-42a5-86da-7c6fa6361c93" ? (
                      <TableRow>
                        <StyledTableCell sx={{ background: "#4BB13F" }}>
                          Items
                        </StyledTableCell>
                        <StyledTableCell align="right">Specs</StyledTableCell>
                        <StyledTableCell align="right">
                          Delivery Date
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          Quantity
                        </StyledTableCell>
                        <StyledTableCell align="right">Unit</StyledTableCell>
                        <StyledTableCell align="right">
                          Price/Unit
                        </StyledTableCell>
                        <StyledTableCell align="right">Amount</StyledTableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <StyledTableCellCb sx={{ background: "#4BB13F" }}>
                          Items
                        </StyledTableCellCb>
                        <StyledTableCellCb align="right">
                          Specs
                        </StyledTableCellCb>
                        <StyledTableCellCb align="right">
                          Delivery Date
                        </StyledTableCellCb>
                        <StyledTableCellCb align="right">
                          Quantity
                        </StyledTableCellCb>
                        <StyledTableCellCb align="right">
                          Unit
                        </StyledTableCellCb>
                        <StyledTableCellCb align="right">
                          Price/Unit
                        </StyledTableCellCb>
                        <StyledTableCellCb align="right">
                          Amount
                        </StyledTableCellCb>
                      </TableRow>
                    )}
                  </TableHead>
                  <TableBody>
                    {suppliers.productsState.map((product) => (
                      <>
                        <TableRow>
                          <StyledTableCell
                            rowSpan={product.quantityTimeLine.length + 1}
                          >
                            {product.name}
                          </StyledTableCell>
                          <StyledTableCell
                            rowSpan={product.quantityTimeLine.length + 1}
                          >
                            {product.specifications}
                          </StyledTableCell>
                        </TableRow>
                        {product.quantityTimeLine.map((schedule) => (
                          <TableRow key={product.productid}>
                            <StyledTableCell>
                              {schedule.deliveryDate.toString()}
                            </StyledTableCell>
                            <StyledTableCell>
                              {schedule.quantity}
                            </StyledTableCell>
                            <StyledTableCell>{schedule.unit}</StyledTableCell>
                            <StyledTableCell>
                              {schedule.priceperunit} INR
                            </StyledTableCell>
                            <StyledTableCell>
                              {parseFloat(schedule.priceperunit) *
                                parseFloat(schedule.quantity)}{" "}
                              INR
                            </StyledTableCell>
                          </TableRow>
                        ))}
                      </>
                    ))}
                    <TableRow>
                      <StyledTableCell align="center" colSpan={6}>
                        <TitleText style={{ margin: "0px" }}>Total</TitleText>
                      </StyledTableCell>
                      <StyledTableCell align="center" colSpan={1}>
                        {suppliers.totalQuantity} INR
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Stack direction="row" spacing={3} sx={{ marginTop: "30px" }}>
                <PoInfoComponent
                  title="GST"
                  subtitle={suppliers.gst}
                  isItalic
                />
                <PoInfoComponent
                  title="Payment Terms"
                  subtitle={suppliers.paymentTerms}
                  isItalic
                />
                <PoInfoComponent
                  title="Delivery Schedule"
                  subtitle={suppliers.deliveryschedule}
                  isItalic
                />
              </Stack>
              <TitleText>Terms and Conditions:</TitleText>
              <ul>
                <TermsText>
                  Our Order Number, GST Number must be quoted on all dispatch
                  documents like Delivery Challans, Tax Invoice, Guarantee /
                  Warranty cards etc.
                </TermsText>
                <TermsText>
                  Delivery Challans and Invoice should be in duplicate.
                </TermsText>
                <TermsText>
                  Material will be rejected if it is not as per specification
                </TermsText>
                <TermsText>
                  Documents to be provided- Invoice , DC, Test report,
                  Transportation Bill, Service manual as applicable
                </TermsText>
              </ul>
            </div>
          ) : (
            <div>
              <TitleText>PO:</TitleText>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="gstno"
                    variant="outlined"
                    fullWidth
                    disabled
                    // onChange={handleQuantity}
                    label="Purchase Order Number (Auto-Generated)"
                    // value={selectedSupplier ? selectedSupplier.gstnumber : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      label="Purchase Order Date"
                      value={suppliers.purchaseorderdate}
                      onChange={(newValue) =>
                        setSuppliers((supply) => ({
                          ...supply,
                          purchaseorderdate: newValue,
                        }))
                      }
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Company Name
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={
                        suppliers.company ? suppliers.company.companyid : ""
                      }
                      label="Company Name"
                      // disabled={isDisabled || false}
                      onChange={handleSelectComoany}
                    >
                      {props.companies &&
                        props.companies.map((company) => (
                          <MenuItem value={company.companyid}>
                            {company.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="gstno"
                    variant="outlined"
                    fullWidth
                    disabled
                    // onChange={handleQuantity}
                    label="GSTIN"
                    value={suppliers ? suppliers.company.gstnumber : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="gstno"
                    variant="outlined"
                    fullWidth
                    disabled
                    // onChange={handleQuantity}
                    label="Billing Address (Factory)"
                    value={suppliers ? suppliers.company.billingaddress : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="gstno"
                    variant="outlined"
                    fullWidth
                    disabled
                    // onChange={handleQuantity}
                    label="Correspondence Address"
                    value={
                      suppliers ? suppliers.company.correspondenceaddress : ""
                    }
                  />
                </Grid>
              </Grid>
              <Divider sx={{ marginTop: "30px" }} />
              <Stack spacing={3}>
                <TitleText>
                  Supplier :{" "}
                  {suppliers.selectedSupplier
                    ? suppliers.selectedSupplier.name
                    : ""}
                </TitleText>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4} lg={6}>
                    <TextField
                      id="contactperson"
                      variant="outlined"
                      fullWidth
                      onChange={(event) =>
                        handleTextBoxUpdate("contactperson", event.target.value)
                      }
                      label="Contact Person"
                      value={suppliers.contactperson}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} />
                  <Grid item xs={12} sm={12} md={4} lg={6}>
                    <TextField
                      id="reference"
                      variant="outlined"
                      fullWidth
                      onChange={(event) =>
                        handleTextBoxUpdate("reference", event.target.value)
                      }
                      label="Reference/Quotation/PI"
                      value={suppliers.reference}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{ width: "100%" }}
                        label="Reference Date"
                        value={suppliers.referencedate}
                        onChange={(newValue) =>
                          setSuppliers((supply) => ({
                            ...supply,
                            referencedate: newValue,
                          }))
                        }
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Choose Supplier
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={
                          suppliers.selectedSupplier
                            ? suppliers.selectedSupplier.supplierid
                            : ""
                        }
                        label="Choose Category"
                        onChange={handleSelectSupplier}
                      >
                        {supplierdData && supplierdData.length > 0
                          ? supplierdData.map((supplier) => (
                              <MenuItem value={supplier.supplierid}>
                                {supplier.name}
                              </MenuItem>
                            ))
                          : allSuppliersData.suppliers.map((supplier) => (
                              <MenuItem value={supplier.supplierid}>
                                {supplier.name}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="gstno"
                      variant="outlined"
                      fullWidth
                      disabled
                      // onChange={handleQuantity}
                      label="GST Number"
                      value={
                        suppliers.selectedSupplier &&
                        suppliers.selectedSupplier.gstnumber
                          ? suppliers.selectedSupplier.gstnumber
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="email"
                      label="Email"
                      disabled
                      variant="outlined"
                      fullWidth
                      value={
                        suppliers.selectedSupplier &&
                        suppliers.selectedSupplier.email !== null
                          ? suppliers.selectedSupplier.email
                          : ""
                      }
                      // onChange={handleQuantity}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="phone"
                      label="Phone number"
                      disabled
                      variant="outlined"
                      fullWidth
                      value={
                        suppliers.selectedSupplier &&
                        suppliers.selectedSupplier.phone
                          ? suppliers.selectedSupplier.phone
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="address"
                      label="Address"
                      disabled
                      variant="outlined"
                      fullWidth
                      value={
                        suppliers.selectedSupplier &&
                        suppliers.selectedSupplier.address
                          ? suppliers.selectedSupplier.address
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
                <Divider sx={{ marginTop: "30px" }} />
                <Stack spacing={2}>
                  {suppliers.selectedSupplier &&
                    suppliers.productsState &&
                    suppliers.productsState.map((product) => (
                      <ProductPOForm
                        {...product}
                        prnData={prnData}
                        selectedSupplier={suppliers.selectedSupplier}
                        prnproducts={allSuppliersData.products}
                        onDeletProduct={onDeletProduct}
                        updateProductsData={updateProductsData}
                      />
                    ))}
                </Stack>
                <Divider sx={{ marginTop: "30px" }} />
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => addProduct()}
                  // endIcon={<AddIcon />}
                  sx={{
                    maxWidth: "312px",
                    marginTop: "20px",
                    marginBottom: "30px",
                  }}
                >
                  Another Product
                </Button>
              </Stack>
              <Divider sx={{ marginTop: "30px" }} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="gstno"
                    variant="outlined"
                    fullWidth
                    onChange={(event) =>
                      handleTextBoxUpdate("gst", event.target.value)
                    }
                    label="GST"
                    value={suppliers.gst}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="gstno"
                    variant="outlined"
                    fullWidth
                    onChange={(event) =>
                      handleTextBoxUpdate("paymentTerms", event.target.value)
                    }
                    label="Payment Terms"
                    value={suppliers.paymentTerms}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="gstno"
                    variant="outlined"
                    fullWidth
                    onChange={(event) =>
                      handleTextBoxUpdate(
                        "deliveryschedule",
                        event.target.value
                      )
                    }
                    label="Delivery Schedule"
                    value={suppliers.deliveryschedule}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ marginTop: "30px" }} />
              <TitleText>Terms and Conditions:</TitleText>
              <ul>
                <TermsText>
                  Our Order Number, GST Number must be quoted on all dispatch
                  documents like Delivery Challans, Tax Invoice, Guarantee /
                  Warranty cards etc.
                </TermsText>
                <TermsText>
                  Delivery Challans and Invoice should be in duplicate.
                </TermsText>
                <TermsText>
                  Material will be rejected if it is not as per specification
                </TermsText>
                <TermsText>
                  Documents to be provided- Invoice , DC, Test report,
                  Transportation Bill, Service manual as applicable
                </TermsText>
              </ul>
              <Divider sx={{ marginTop: "30px" }} />
            </div>
          )}
          {isDisabled ? (
            <Stack direction="row" spacing={3}>
              <Button
                variant="contained"
                size="large"
                color="secondary"
                onClick={() => setDisabled(false)}
                endIcon={<EditIcon />}
                sx={{ minWidth: "312px", marginTop: "20px" }}
              >
                Edit
              </Button>
              {suppliers.previewpdflink && (
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => setShareModal(true)}
                  color="primary"
                  endIcon={<PictureAsPdfIcon />}
                  sx={{ minWidth: "312px", marginTop: "20px" }}
                >
                  Export
                </Button>
              )}
              <Button
                variant="contained"
                size="large"
                onClick={() => downLoadPdf()}
                color="primary"
                endIcon={<FileDownloadIcon />}
                sx={{ minWidth: "312px", marginTop: "20px" }}
              >
                Download
              </Button>
            </Stack>
          ) : (
            <Button
              variant="contained"
              size="large"
              onClick={onUpdatePo}
              endIcon={<DoneIcon />}
              sx={{ maxWidth: "312px", marginTop: "20px" }}
            >
              Update
            </Button>
          )}
        </div>
      )}
    </Container>
  );
}
function mapStateToProps(state) {
  const { po, global } = state;
  return { ...po, ...global };
}

export default connect(mapStateToProps)(CreatePO);
