import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { connect, useDispatch } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import md5 from "md5";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { updateData } from "../../utils/apiClient";
import {
  setUserLoginLoad,
  setUserLoginSuccess,
  setUserLoginError,
} from "../../redux/reducers/globalReducer";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
    minWidth: "300px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function LoginComponent(props) {
  const { userLoginSuccess, handleModalClose } = props;
  const [open, setOpen] = React.useState(
    props.showLoginModal ? props.showLoginModal : false
  );
  const dispatch = useDispatch();
  const [username, setUsername] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const handleClose = (event, reason) => {
    setOpen(false);
    props.handleModalClose(userLoginSuccess);
  };
  const loginUser = async (event) => {
    console.log("login");
    try {
      event.preventDefault();
      dispatch(setUserLoginLoad());
      const loginData = {
        username: username,
        password: md5(password),
      };
      const loignResp = await updateData("users/login", loginData);
      if (
        loignResp &&
        loignResp.success &&
        loignResp.data &&
        loignResp.data.isValidPassword
      ) {
        dispatch(
          setUserLoginSuccess({
            ...loignResp.data.user,
            token: loignResp.data.token,
          })
        );
        console.log(loignResp);
      } else {
        dispatch(setUserLoginError());
        console.log("failed");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    setOpen(props.showLoginModal);
  }, [props.showLoginModal]);
  useEffect(() => {
    if (userLoginSuccess) {
      handleModalClose(userLoginSuccess);
    }
  }, [userLoginSuccess]);
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Login to Make changes
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <form onSubmit={loginUser}>
            <Stack spacing={3}>
              <Stack
                spacing={2}
                sx={{ marginTop: "40px", width: "100%", maxWidth: "452px" }}
              >
                <TextField
                  id="username"
                  label="Username"
                  variant="outlined"
                  fullWidth
                  value={username}
                  onChange={(event) => setUsername(event.target.value)}
                />
                <TextField
                  id="password"
                  label="Password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </Stack>
              {props.userLoginError && (
                <div style={{ color: "#D34836" }}>
                  Invalid Username or Password
                </div>
              )}
              <Button
                variant="contained"
                size="large"
                type="submit"
                onClick={loginUser}
                endIcon={<ArrowForwardIcon />}
                sx={{ maxWidth: "312px" }}
              >
                {props.userLoginLoading ? "Processing..." : "Login"}
              </Button>
            </Stack>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
function mapStateToProps(state) {
  const { global } = state;
  return { ...global };
}

export default connect(mapStateToProps)(LoginComponent);
