import {  connect } from "react-redux";
import React, {useState, useEffect} from "react";
import Container from "@mui/material/Container";
import InfoComponent from "../../components/InfoComponent";
import IncomingPO from "../../components/IncomingPO";
import Grid from "@mui/material/Grid";
import { getData } from "../../utils/apiClient";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import IncomingPOHistory from "../../components/IncomingPOHistory";

async function fetchIPOData() {
    const getIPOData = await getData("ipoe/all");
    if(getIPOData.success)return getIPOData.data;
    else return [];
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
}); 

function IncomingPOListing(props){
    const [data, setData] = useState(null);
    const [dataHistory, setDataHistory] = useState(null);

    const [open, setOpen] = React.useState(false);
    const [snakbarMessage, setSnakbarMessage] = React.useState(null);
    const [isSnakbarError, seIsSnakbarError] = React.useState(false);
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpen(false);
      };

    useEffect(() => {
        fetchIPOData().then(data => {
            const withInvoice = data.filter(item => item.invoiceid !== null);
            const withoutInvoice = data.filter(item => item.invoiceid === null);
            setData(withoutInvoice);
            setDataHistory(withInvoice);
            setOpen(true);
            seIsSnakbarError(false);
            setSnakbarMessage("Incoming PO Loaded.");
        });
    }, []); 
    return (
        <Container maxWidth="xl" sx={{ marginBottom: "40px" }}>
            <Snackbar
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                <Alert
                onClose={handleClose}
                severity={isSnakbarError ? "error" : "success"}
                sx={{ width: "100%" }}
                >
                {snakbarMessage}
                </Alert>
            </Snackbar>
            <InfoComponent
                title="Incoming PO Listing"
                subtitle="The Following are the registered Purchase Order"
                showBackButton
            />
            <Grid container spacing={2}
                sx={{
                    paddingLeft : "5%",
                    paddingRight : "5%",
                }}
            >
                {data &&
                    data.map((table) => (
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <IncomingPO data={table}></IncomingPO>
                        </Grid>
                ))}
            </Grid>
            <br />
            <hr />
            <h2>History</h2>
            <Grid container spacing={2}
                sx={{
                    paddingLeft : "5%",
                    paddingRight : "5%",
                }}
            >
                {dataHistory &&
                    dataHistory.map((table) => (
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <IncomingPOHistory data={table}></IncomingPOHistory>
                        </Grid>
                ))}
            </Grid>
        </Container>
    );
}
function mapStateToProps(state) {
    const { global } = state;
    return global;
}
export default connect(mapStateToProps)(IncomingPOListing);