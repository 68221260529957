import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import { connect, useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import LoginComponent from "../../components/LoginComponent";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import ProductPRNForm from "../../components/ProductPRNForm";
import InfoComponent from "../../components/InfoComponent";
import { updateData } from "../../utils/apiClient";
import {
  setUpdatePrnLoad,
  setUpdatePrnSuccess,
  setUpdatePrnError,
  setPrnLoad,
  setPrnSuccess,
  setPrnError,
} from "../../redux/reducers/prnReducer";
import { setUserData } from "../../redux/reducers/globalReducer";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ViewPRN(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLoginModal, setShowLoginModal] = React.useState(false);
  const [searchParams] = useSearchParams();
  const [open, setOpen] = React.useState(false);
  const [isSnakbarError, seIsSnakbarError] = React.useState(false);
  const { id } = useParams();
  const [isDisabled, setDisabled] = React.useState(true);
  const [products, setProducts] = React.useState([]);
  const addNewProduct = () => {
    setProducts((product) =>
      product.concat([
        {
          category: "",
          product: "",
          quantity: "",
          unit: "",
          productid: `product${product.length + 1}`,
          prnproductsmappingid: "",
        },
      ])
    );
  };
  const delteProduct = (productid) => {
    const updatedProducts = products.filter(
      (product) => product.productid !== productid
    );
    setProducts([...updatedProducts]);
  };
  const selectProductValue = (productid, value, type) => {
    const updatedProducts = products.map((product) => {
      if (product.productid === productid) {
        product[type] = value;
      }
      return product;
    });
    setProducts([...updatedProducts]);
  };
  const onUpdatePrn = async () => {
    if (props.user && props.user.token) {
      const createProductsResp = [];
      if (products && products.length > 0) {
        dispatch(setUpdatePrnLoad());
        products.forEach((productResp) => {
          if (productResp.product) {
            let productsData = {
              productid: productResp.product.productid,
              quantityamount: productResp.quantity.toString(),
              measurementmetricid: productResp.product.measurementmetricid,
            };
            if (productResp.prnproductsmappingid) {
              productsData = {
                ...productsData,
                prnproductsmappingid: productResp.prnproductsmappingid,
              };
            }
            createProductsResp.push(productsData);
          }
        });
        if (createProductsResp && createProductsResp.length > 0) {
          const createPrnResp = await updateData("prns/update", {
            products: createProductsResp,
            prnid: id,
            userid: props.user.userid,
            usertoken: props.user.token,
          });
          if (createPrnResp.success) {
            setDisabled(false);
            dispatch(setUpdatePrnSuccess());
            setOpen(true);
            seIsSnakbarError(false);
            dispatch(setPrnLoad());
            const prns = await updateData("prns");
            if (prns.success) {
              dispatch(setPrnSuccess(prns.data));
              navigate("/prn");
            } else {
              dispatch(setPrnError());
            }
          } else {
            dispatch(setUpdatePrnError());
            setOpen(true);
            seIsSnakbarError(true);
          }
          dispatch(setUserData());
        }
      }
    } else {
      setShowLoginModal(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleModalClose = (loginSuccess) => {
    setShowLoginModal(false);
    if (loginSuccess) {
      onUpdatePrn();
    }
  };
  useEffect(() => {
    const getPrnDetails = async () => {
      const getPrnData = await updateData("prns/details", { prnid: id });
      if (getPrnData.success) {
        const products = getPrnData.data.prnproductsmappings.map(
          (mapping, index) => {
            return {
              category: mapping.products.productcategoryid,
              product: mapping.products,
              quantity: mapping.quantityamount,
              unit: mapping.products.measurementmetrics.name,
              prnproductsmappingid: mapping.prnproductsmappingid,
              productid: `product${index}`,
            };
          }
        );
        setProducts(products);
      }
    };
    getPrnDetails();
  }, [id]);

  useEffect(() => {
    const isEdit = searchParams.get("isEdit");
    if (isEdit && isEdit === "true") {
      setDisabled(false);
    }
  }, [searchParams]);
  return (
    <Container maxWidth="xl" sx={{ marginBottom: "40px" }}>
      {showLoginModal && (
        <LoginComponent
          showLoginModal={showLoginModal}
          handleModalClose={handleModalClose}
        />
      )}
      <Snackbar
        open={open}
        // autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={isSnakbarError ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {isSnakbarError
            ? "Something went wrong while Updatingg Prn !"
            : "Prn Successfully Updated !!"}
        </Alert>
      </Snackbar>
      <InfoComponent
        title="PRN"
        backRedirectLink="/prn"
        showBackButton
        subtitle="Kindly update the details to update a Product Requisition Note."
      />
      <Stack spacing={3}>
        {products &&
          products.map((productData) => (
            <ProductPRNForm
              {...productData}
              isDisabled={isDisabled}
              delteProduct={delteProduct}
              selectProductValue={selectProductValue}
            />
          ))}
      </Stack>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Button
          variant="outlined"
          size="large"
          onClick={addNewProduct}
          disabled={isDisabled}
          endIcon={<AddIcon />}
          sx={{ maxWidth: "312px", marginTop: "20px", marginBottom: "30px" }}
        >
          Another Product
        </Button>
        {isDisabled ? (
          <Button
            variant="contained"
            size="large"
            onClick={() => setDisabled(false)}
            endIcon={<EditIcon />}
            sx={{ maxWidth: "312px" }}
          >
            Edit
          </Button>
        ) : (
          <Button
            variant="contained"
            size="large"
            onClick={onUpdatePrn}
            endIcon={<DoneIcon />}
            sx={{ maxWidth: "312px" }}
          >
            Update
          </Button>
        )}
      </div>
    </Container>
  );
}
function mapStateToProps(state) {
  const { prn, global } = state;
  return { ...prn, ...global };
}

export default connect(mapStateToProps)(ViewPRN);
