import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ipoLoading: false,
  ipoSuccess: false,
  ipoError: false,
  ipos: [],
  createIpoData: {},
  createIpoLoading: false,
  createIpoSuccess: false,
  createIpoError: false,
  updateIpoLoading: false,
  updateIpoSuccess: false,
  updateIpoError: false,
};

export const prnSlice = createSlice({
  name: "ipo",
  initialState,
  reducers: {
    setIpoLoad: (state) => {
      state.ipoLoading = true;
      state.ipoSuccess = false;
      state.ipoError = false;
      state.ipos = [];
    },
    setIpoSuccess: (state, action) => {
      state.ipoLoading = false;
      state.ipoSuccess = true;
      state.ipoError = false;
      state.ipos = action.payload;
    },
    setIpoError: (state) => {
      state.ipoLoading = false;
      state.ipoSuccess = false;
      state.ipoError = true;
      state.ipos = [];
    },
    setCreateIpoLoad: (state) => {
      state.createIpoLoading = true;
      state.createIpoSuccess = false;
      state.createIpoError = false;
      state.createIpoData = {};
    },
    setCreateIpoSuccess: (state, action) => {
      state.createIpoLoading = false;
      state.createIpoSuccess = true;
      state.createIpoError = false;
      state.createIpoData = action.payload;
    },
    setCreateIpoError: (state) => {
      state.createIpoLoading = false;
      state.createIpoSuccess = false;
      state.createIpoError = true;
      state.createIpoData = {};
    },
    setUpdateIpoLoad: (state) => {
      state.updateIpoLoading = true;
      state.updateIpoSuccess = false;
      state.updateIpoError = false;
    },
    setUpdateIpoSuccess: (state, action) => {
      state.updateIpoLoading = false;
      state.updateIpoSuccess = true;
      state.updateIpoError = false;
    },
    setUpdateIpoError: (state) => {
      state.updateIpoLoading = false;
      state.updateIpoSuccess = false;
      state.updateIpoError = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setIpoLoad,
  setIpoSuccess,
  setIpoError,
  setCreateIpoLoad,
  setCreateIpoSuccess,
  setCreateIpoError,
  setUpdateIpoLoad,
  setUpdateIpoSuccess,
  setUpdateIpoError,
} = prnSlice.actions;

export default prnSlice.reducer;
