import {  connect } from "react-redux";
import styled from "styled-components";
import Table from '@mui/material/Table';
import React from "react";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { updateData } from "../../utils/apiClient";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { formatDate } from "../../utils/helper";
import logo from "../../assets/images/phoneix-logo.png";

const TitleText = styled.p`
    color: #000000;
    font-family: Open Sans;
    font-size: 22px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
`;

const DateText = styled.p`
    color: #000000;
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
`;

const CreditText = styled.p`
    color: #000000;
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
`;

const RemarkText = styled.p`
    color: #000000;
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
`;

const BCText = styled.p`
    color: #000000;
    font-family: Open Sans;
    font-size: 24px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
`;

const ICContainer = styled.div`
    background: #fff;
`;

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
}); 

function InvoiceCard({ data }){
    const navigate = useNavigate();

    function refineCreditPeriod(creditDetails){
        const creditData = creditDetails.split(",");
        if(creditData[0] && creditData[1]) return creditData[0] + " " + creditData[1];
        return creditDetails;
    }

    const [open, setOpen] = React.useState(false);
    const [snakbarMessage, setSnakbarMessage] = React.useState(null);
    const [isSnakbarError, seIsSnakbarError] = React.useState(false);
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpen(false);
      };

    const onDeleteInvoice = async (invoiceid, products) => {
        const deleteIPOData = await updateData("invoice/delete", {"invoiceid" : invoiceid, "products" : products});
        if(deleteIPOData.success){
            setOpen(true);
            seIsSnakbarError(false);
            setSnakbarMessage("Invoice Deleted.");
            setTimeout(() => {
                window.location.href = '/invlist';
            }, 4000);
        } else {
            setOpen(true);
            seIsSnakbarError(true);
            setSnakbarMessage("Failed to Delete Invoice.");
        }
    };
    return (
        <ICContainer>
            <Snackbar
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                <Alert
                onClose={handleClose}
                severity={isSnakbarError ? "error" : "success"}
                sx={{ width: "100%" }}
                >
                {snakbarMessage}
                </Alert>
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <TitleText>Invoice #{data.invoicenumber}</TitleText>
                    <DateText>{formatDate(data.dateofinvoice)}</DateText>
                    <BCText>{data.buyername}</BCText>
                </Grid>
                <Grid item xs={4}>
                    <img
                        src={logo}
                        alt="Company Logo"
                        style={{
                            width: '40%',
                            height: 'auto',
                            margin: '10px',
                            float: 'right'
                        }}
                    />
                </Grid>
            </Grid>
            <div style={{ height: 261, overflow: 'auto' }}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ border: '0.5px solid #CDCDCD', fontWeight : 'bold', padding : 1 }}>Product</TableCell>
                                <TableCell sx={{ border: '0.5px solid #CDCDCD', fontWeight : 'bold', padding : 1 }}>Qty Rqstd</TableCell>
                                <TableCell sx={{ border: '0.5px solid #CDCDCD', fontWeight : 'bold', padding : 1 }}>Delivery Date & Time</TableCell>
                            </TableRow>
                            {data && data.products.map((row) => (
                                <TableRow>
                                    <TableCell
                                    sx={{
                                        borderBottom: 'none',
                                        borderLeft: '0.5px solid #CDCDCD',
                                        padding : 1
                                    }}
                                    >{row.product}</TableCell>
                                    <TableCell
                                    sx={{
                                        borderBottom: 'none',
                                        borderRight: '0.5px solid #CDCDCD',
                                        borderLeft: '0.5px solid #CDCDCD',
                                        padding : 1
                                    }}
                                    >{row.quantity}</TableCell>
                                    <TableCell
                                    sx={{
                                        borderBottom: 'none',
                                        borderRight: '0.5px solid #CDCDCD',
                                        padding : 1
                                    }}
                                    >{formatDate(row.date)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell
                                sx={{
                                    border: '0.5px solid grey',
                                    fontWeight: 'bold',
                                    padding : 1
                                }}
                            >
                            Net Amount
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: '0.5px solid grey',
                                    fontWeight: 'bold',
                                    padding : 1
                                }}
                            >
                            {data.netamount} /-
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                sx={{
                                    border: '0.5px solid grey',
                                    padding : 1
                                }}
                            >
                            Tax
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: '0.5px solid grey',
                                    padding : 1
                                }}
                            >
                            {data.tax} /-
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                sx={{
                                    border: '0.5px solid grey',
                                    fontWeight: 'bold',
                                    color: '#2E3E8C',
                                    padding : 1
                                }}
                            >
                            Gross Amount
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: '0.5px solid grey',
                                    fontWeight: 'bold',
                                    color: '#2E3E8C',
                                    padding : 1
                                }}
                            >
                            {data.grossamount} /-
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <CreditText><b>Credit Period:</b> {refineCreditPeriod(data.credit)} days</CreditText>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={() => navigate(`/iep/view/`+data.invoiceid)}
                        color="secondary"
                        fullWidth
                        endIcon={<VisibilityIcon />}
                    >
                    View
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Button
                        variant="contained"
                        size="large"
                        sx={{
                            backgroundColor : 'white',
                            color: 'darkblue',
                            border: '0.5px solid darkblue',
                            borderRadius: '10px',
                            '&:hover': {
                                backgroundColor: 'white',
                                color: 'darkblue',
                            },
                        }}
                        onClick={() => navigate(`/iep/edit/`+data.invoiceid)}
                        fullWidth
                        endIcon={<EditIcon />}
                    >
                        Edit
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Button
                        variant="outlined"
                        size="large"
                        color="error"
                        fullWidth
                        onClick={() => onDeleteInvoice(data.invoiceid, data.products)}
                        endIcon={<DeleteIcon />}
                    >
                        Delete
                    </Button>
                </Grid>
            </Grid>
        </ICContainer>
    );
}
function mapStateToProps(state) {
    const { po, global } = state;
    return { ...po, ...global };
}
  
export default connect(mapStateToProps)(InvoiceCard);