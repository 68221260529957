export function isNotNullOrEmpty(obj) {
    // Check if the object is null or undefined
    if (obj === null || obj === undefined) {
        console.log(obj);
        return false;
    }

    // Check if the object is a string and not empty
    if (typeof obj === 'string' && obj.trim() === '') {
        console.log(obj);
        return false;
    }

    // Check if the object is an array
    if (Array.isArray(obj)) {
        // Check each element in the array recursively
        for (let i = 0; i < obj.length; i++) {
            if (!isNotNullOrEmpty(obj[i])) {
                console.log(obj, i);
                return false;
            }
        }
        return true;
    }

    // Check if the object is an object
    if (typeof obj === 'object') {
        // Check each property in the object recursively
        for (let key in obj) {
            if (!isNotNullOrEmpty(obj[key])) {
                console.log(obj, key);
                return false;
            }
        }
        return true;
    }

    // For other data types (numbers, booleans, etc.), return true
    return true;
}

export function formatDate(dateString) {
    const date = new Date(dateString);
  
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = String(date.getDate()).padStart(2, '0');
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    
    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const monthAbbr = monthNames[month];
    
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12 AM
    
    return `${day}-${monthAbbr}-${year} ${formattedHours}:${minutes}:${seconds} ${ampm}`;
}

export function formatOnlyDate(dateString) {
    const date = new Date(dateString);
  
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = String(date.getDate()).padStart(2, '0');
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    
    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const monthAbbr = monthNames[month];
    
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12 AM
    
    return `${day}-${monthAbbr}-${year}`;
}

export function formatDay(dateString) {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = date.getMonth();
    const day = String(date.getDate()).padStart(2, '0');

    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const monthAbbr = monthNames[month];

    return `${day}-${monthAbbr}-${year}`;
}

export function activeState(isactive) {
    if(isactive === 0) return "NO";
    return "YES";
}