import React, { useEffect } from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import { connect, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import Stack from "@mui/material/Stack";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { convertToNearestTonOrKg } from "../../utils/convetor";
import {
  setGrnError,
  setGrnSuccess,
  setGrnLoad,
} from "../../redux/reducers/grnReducer";
import { updateData } from "../../utils/apiClient";
import { setUserData } from "../../redux/reducers/globalReducer";
import LoginComponent from "../../components/LoginComponent";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ProductTitle = styled.p`
  font-size: 24px;
  font-family: "Open Sans";
  font-weight: 700;
  color: #333;
  margin: 0px;
`;
const ProducSubtTitle = styled.p`
  font-size: 18px;
  font-family: "Open Sans";
  font-weight: 400;
  color: #333;
  margin: 0px;
`;
const PRNContainer = styled.div`
  border-radius: 16px;
  border: 1px solid var(--Disabled, #848484);
  background: #fff;
  padding: 24px;
`;
const StyledLink = styled(Link)`
  color: var(--Text, #333);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration-line: underline;
`;
const StyledLinkText = styled.p`
  color: var(--Text, #333);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
  text-decoration-line: underline;
`;
function GRNCard(props) {
  const { product } = props;
  const navigate = useNavigate();
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [showLoginModal, setShowLoginModal] = React.useState(false);
  const [isSnakbarError, seIsSnakbarError] = React.useState(false);
  const [showLightBox, setShowLightBox] = React.useState(false);
  const [lightBoxImage, setLightBoxImage] = React.useState(null);
  const handleInvoice = (imageUrl) => {
    if (imageUrl.match(/\.(jpg|jpeg|png|gif)$/i)) {
      setShowLightBox(true);
      setLightBoxImage(imageUrl);
    } else {
      window.open(imageUrl, "_blank");
    }
  };
  const handleDeleteGrn = async () => {
    if (props.user && props.user.token) {
      setDeleteLoading(true);
      const deleteResp = await updateData("grns/delete", {
        grnid: product.grnid,
        userid: props.user.userid,
        usertoken: props.user.token,
      });
      if (deleteResp.success) {
        setOpen(true);
        seIsSnakbarError(false);
        dispatch(setGrnLoad());
        const pos = await updateData("/grns");
        if (pos.success) {
          dispatch(setGrnSuccess(pos.data));
        } else {
          dispatch(setGrnError());
        }
      } else {
        seIsSnakbarError(true);
        setOpen(true);
      }
      dispatch(setUserData());
      setDeleteLoading(false);
    } else {
      setShowLoginModal(true);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
    const handleModalCloseDelete = (loginSuccess) => {
      try {
        setShowLoginModal(false);
        if (loginSuccess) {
          handleDeleteGrn();
        } else {
          setDeleteLoading(false);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
  useEffect(() => {
    let showDeleteFlag = true;
    if (product.grnproductmappings) {
      product.grnproductmappings &&
        product.grnproductmappings.forEach((mapping) => {
          if (mapping.giir.isPresent) {
            showDeleteFlag = false;
          }
        });
    }
    setShowDelete(showDeleteFlag);
  }, [product]);

  return (
    <PRNContainer>
      {showLoginModal && (
        <LoginComponent
          showLoginModal={showLoginModal}
          handleModalClose={handleModalCloseDelete}
        />
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={isSnakbarError ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {isSnakbarError
            ? "Something went wrong while Deleting GRN !"
            : "GRN Successfully Deleted !!"}
        </Alert>
      </Snackbar>
      {showLightBox && (
        <Lightbox
          mainSrc={lightBoxImage}
          onCloseRequest={() => setShowLightBox(false)}
        />
      )}
      <Stack direction="row" spacing={4}>
        {product.grnproductmappings ? (
          <Carousel showThumbs={false}>
            {product.grnproductmappings &&
              product.grnproductmappings.map((mapping) => (
                <img
                  src={mapping.productimageurl}
                  alt="placeholder"
                  style={{
                    borderRadius: "8px",
                    maxWidth: "300px",
                    objectFit: "contain",
                  }}
                />
              ))}
          </Carousel>
        ) : (
          <img
            src="https://placehold.co/240x343"
            alt="placeholder"
            style={{ borderRadius: "8px" }}
          />
        )}
        <Stack direction="column" spacing={2} width="100%">
          <ProductTitle>
            {product.suppliers && product.suppliers.name}
          </ProductTitle>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Stack direction="column" spacing={2} width="100%">
                {product.grnproductmappings &&
                  product.grnproductmappings.map((mapping) => (
                    <ProductTitle style={{ fontSize: "18px" }}>
                      {mapping.products && mapping.products.name}
                    </ProductTitle>
                  ))}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Stack direction="column" spacing={2} width="100%">
                {product.grnproductmappings &&
                  product.grnproductmappings.map((mapping) => (
                    <ProducSubtTitle style={{ fontSize: "18px" }}>
                      Qty Rcd:{" "}
                      {mapping.products.measurementmetrics.name === "grams"
                        ? convertToNearestTonOrKg(mapping.quantityamount)
                        : `${mapping.quantityamount && mapping.quantityamount}
                                ${
                                  mapping.products.measurementmetrics
                                    ? mapping.products.measurementmetrics.name
                                    : ""
                                }`}
                    </ProducSubtTitle>
                  ))}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              {product.grnproductmappings &&
                product.grnproductmappings.map((mapping) =>
                  mapping.giir.isRequired ? (
                    props.department && props.department.username === "qc" ? (
                      !mapping.giir.isPresent ? (
                        <Button
                          variant="contained"
                          onClick={() =>
                            navigate(`/giir/create/${mapping.grnid}`)
                          }
                        >
                          Create Giir
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          onClick={() =>
                            navigate(`/giir/${mapping.giir.giir.giirid}`)
                          }
                        >
                          View Giir
                        </Button>
                      )
                    ) : !mapping.giir.isPresent ? (
                      <Button variant="contained" size="small" disabled>
                        Pending GIIR
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        onClick={() =>
                          navigate(`/giir/${mapping.giir.giir.giirid}`)
                        }
                      >
                        View Giir
                      </Button>
                    )
                  ) : (
                    <div />
                  )
                )}
            </Grid>
          </Grid>
          <Stack direction="row" spacing={1}>
            <InsertDriveFileOutlinedIcon />
            <StyledLinkText
              onClick={() => handleInvoice(product.invoiceimageurl)}
            >
              View Invoice
            </StyledLinkText>
          </Stack>
          <Stack direction="row" spacing={1}>
            <PictureAsPdfOutlinedIcon />
            <StyledLink to={`/po/${product.poid}`}>
              View Purchase Order
            </StyledLink>
          </Stack>
          <Stack direction="row" spacing={2} fullWidth>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => navigate(`/grn/${product.grnid}?isEdit=true`)}
              fullWidth
              endIcon={<EditIcon />}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={() => navigate(`/grn/${product.grnid}`)}
              color="secondary"
              endIcon={<VisibilityIcon />}
            >
              View
            </Button>
          </Stack>
          {showDelete && (
            <Button
              variant="outlined"
              size="large"
              color="error"
              disabled={deleteLoading}
              onClick={handleDeleteGrn}
              fullWidth
              endIcon={<DeleteIcon />}
            >
              {deleteLoading ? "Deleting..." : "Delete GRN"}
            </Button>
          )}
        </Stack>
      </Stack>
    </PRNContainer>
  );
}
function mapStateToProps(state) {
  const { po, global } = state;
  return { ...po, ...global };
}

export default connect(mapStateToProps)(GRNCard);