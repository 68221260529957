import {  connect } from "react-redux";
import React, { useEffect } from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import InvoiceCard from "../InvoiceCard";
import InvoiceCardDone from "../InvoiceCardDone";
import { useState } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Box from '@mui/material/Box';

const TitleText = styled.p`
    color: #000000;
    font-family: Open Sans;
    font-size: 22px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
`;

const DateText = styled.p`
    color: #000000;
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
`;

const ICContainer = styled.div`
    background: #fff;
    padding: 5px;
`;

const Filter = styled.div`
    border-radius: 16px;
    border: 1px solid black;
    padding: 15px;
`;

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
}); 

function ICTab({ data }){
    const navigate = useNavigate();

    function defaultView(id) {
        setSelectedLabel(id);
        const invoiceDetails = data.buyers.find(
            (buyer) => buyer.buyerid === id
        );
        setViewTableData(invoiceDetails);
    }

    const filters = data.buyers.map(({buyerid, buyername})=>({buyerid, buyername}));
    const defaultSelectedLabelId = filters[0].buyerid;
    const [selectedLabel, setSelectedLabel] = useState(defaultSelectedLabelId);
    const [viewTableData, setViewTableData] = useState(null);

    useEffect(() => {
        defaultView(defaultSelectedLabelId);
    }, []);

    const handleLabelClick = (id) => {
        setSelectedLabel(id);
        const invoiceDetails = data.buyers.find(
            (buyer) => buyer.buyerid === id
        );
        setViewTableData(invoiceDetails);
    };

    const modifyInvoiceId = (array, value) => {
        const index = array.indexOf(value);
        if (index !== -1) array.splice(index, 1);
        else array.push(value);
        return array;
      };

    const [selectedInvoice, setIsClicked] = useState([]);
    const queryParams = new URLSearchParams();

    const handleClick = (invoiceid) => {
        const selectedInvoices = modifyInvoiceId(selectedInvoice, invoiceid);
        setIsClicked([...selectedInvoices]);
    };

    const [open, setOpen] = React.useState(false);
    const [snakbarMessage, setSnakbarMessage] = React.useState(null);
    const [isSnakbarError, seIsSnakbarError] = React.useState(false);
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpen(false);
      };

    const navigatePopayReq = () => {
        if(selectedInvoice.length > 0){
            navigate(`/popayreq?invoice=${selectedInvoice.toString()}`)
        } else {
            setOpen(true);
            seIsSnakbarError(true);
            setSnakbarMessage("Please Select at least one invoice.");
        }
    }

    const navigatePoackReq = () => {
        if(selectedInvoice.length > 0){
            navigate(`/popayackn?invoice=${selectedInvoice.toString()}`)
        } else {
            setOpen(true);
            seIsSnakbarError(true);
            setSnakbarMessage("Please Select at least one invoice.");
        }
    }

    return (
        <ICContainer>
            <Snackbar
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                <Alert
                onClose={handleClose}
                severity={isSnakbarError ? "error" : "success"}
                sx={{ width: "100%" }}
                >
                {snakbarMessage}
                </Alert>
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Filter>
                        <TitleText>Filters</TitleText>
                        <DateText>Select buyer to filter invoices</DateText>
                        <hr />
                        {filters.map(({ buyerid, buyername }) => (
                            <div 
                                style={{
                                    fontSize: '20px', 
                                    marginTop: '10px',
                                    color: selectedLabel === buyerid ? '#4282BF' : 'black', 
                                    fontWeight: selectedLabel === buyerid ? 'bold' : 'normal', 
                                    cursor: 'pointer',
                                }}
                                key={buyerid}
                                onClick={() => handleLabelClick(buyerid)} 
                            >{buyername}</div>
                        ))}
                    </Filter>
                </Grid>
                <Grid item xs={9}>
                    <Grid container spacing={1}>
                        {viewTableData &&
                            viewTableData.invoices.map((table) => {
                                if (table.paymentstatus === 'pending' && selectedInvoice.indexOf(table.invoiceid) !== -1){
                                    return (
                                        <Grid item xs={12} sm={12} md={6} lg={6} onClick={() => handleClick(table.invoiceid)}>
                                            <Box
                                                sx={{
                                                position: 'relative',
                                                border: '2px solid #2E3E8C',
                                                padding: '20px',
                                                borderRadius: '16px',
                                                }}
                                            >
                                                <CheckCircleIcon 
                                                    sx={{
                                                        color: "#2E3E8C",
                                                        fontSize: 40,
                                                        position: 'absolute',
                                                        top: '-15px',
                                                        right: '-15px'
                                                    }} 
                                                />
                                                <InvoiceCard data={table}/>
                                            </Box>
                                        </Grid>
                                    );
                                }
                                if (table.paymentstatus === 'pending' && selectedInvoice.indexOf(table.invoiceid) === -1){
                                    return (
                                        <Grid item xs={12} sm={12} md={6} lg={6} onClick={() => handleClick(table.invoiceid)}>
                                            <div style={{ border : '2px solid black', padding: '20px', borderRadius: '16px', }}>
                                                <InvoiceCard data={table}/>
                                            </div>
                                        </Grid>
                                    );
                                }
                                if (table.paymentstatus === 'done'){
                                    return (
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <InvoiceCardDone data={table}/>
                                        </Grid>
                                    );
                                }
                                return null;
                            })}
                    </Grid>
                </Grid>
            </Grid>
            < br />
            < br />
            <Grid container spacing={2}
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    backgroundColor: 'background.paper',
                    zIndex: 1000,
                    p: 2,
                }}
            >
                <Grid item xs={3}></Grid>
                <Grid item xs={9}>
                    <Grid container spacing={2}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5}>
                            <Button
                                variant="outlined"
                                size="large"
                                color="error"
                                fullWidth
                                onClick={() => navigatePopayReq()}
                                endIcon={<TaskOutlinedIcon />}
                            >
                                Send Payment Reminder
                            </Button>
                        </Grid>
                        <Grid item xs={5}>
                            <Button
                                variant="contained"
                                size="large"
                                onClick={() => navigatePoackReq()}
                                sx={{
                                    backgroundColor : 'white',
                                    color: '#24B73C',
                                    border: '0.5px solid #24B73C',
                                    borderRadius: '10px',
                                    '&:hover': {
                                        backgroundColor: 'white',
                                        color: '#24B73C',
                                    },
                                }}
                                fullWidth
                                endIcon={<TaskOutlinedIcon />}
                            >
                            Send Payment Acknowledgment
                            </Button>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ICContainer>
    );
}

function mapStateToProps(state) {
    const { po, global } = state;
    return { ...po, ...global };
}
  
export default connect(mapStateToProps)(ICTab);