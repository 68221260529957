/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
// @ts-nocheck

import axios from 'axios';

export const updateData = async (endPoints, bodyData) => {
  const defaultAPiLink = `https://api.phoenixbiologix.com/${endPoints}`;
  const settings = {
    method: 'POST',
    // mode: 'no-cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  };
  try {
    const response = await fetch(defaultAPiLink, settings);
    const json = await response.json();
    return json;
  } catch (error) {
    return error;
  }
};
export const updateFormData = async (endPoints, bodyData) => {
  const defaultAPiLink = `https://api.phoenixbiologix.com/${endPoints}`;
  const settings = {
    method: 'POST',
    body: bodyData,
  };
  try {
    const response = await fetch(defaultAPiLink, settings);
    const json = await response.json();
    return json;
  } catch (error) {
    return error;
  }
};
export const uploadFile = async file => {
  const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
  // we gather a local file for this example, but any valid readStream source will work here.
  const data = new FormData();
  data.append('file', file);
  try {
    const response = await axios.post(url, data, {
      maxBodyLength: 'Infinity', // this is needed to prevent axios from erroring out with large files
      headers: {
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        pinata_api_key: '329c8b940f52af53fc5b',
        pinata_secret_api_key:
          'bdc1deb50a989dfb7b303bee9b4bd907c2d212f9d86897570a5360a9e60d6c3e',
      },
    });
    if (response.status === 200) {
      return {
        success: true,
        data: {
          ...response.data,
          fileUrl: `https://metria.mypinata.cloud/ipfs/${
            response.data.IpfsHash
          }`,
        },
      };
    }
    return {
      success: false,
    };
  } catch (err) {
    console.error(err);
    return {
      success: false,
      error: err,
    };
  }
};
export const getData = async (endPoints) => {
  const defaultAPiLink = `https://api.phoenixbiologix.com/${endPoints}`;
  const settings = {
    method: 'GET',
    // mode: 'no-cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  try {
    const response = await fetch(defaultAPiLink, settings);
    const json = await response.json();
    return json;
  } catch (error) {
    return error;
  }
};
