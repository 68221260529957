import React from "react";
import styled from "styled-components";

const TitleText = styled.p`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #5a5a5a;
  margin: 0px;
`;

const SubTitleText = styled.p`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #222222;
  margin: 0px;
`;
export default function PoInfoComponent({ title, subtitle, isItalic }) {
  return (
    <div>
      <TitleText style={{ fontStyle: isItalic ? "italic" : "normal" }}>
        {title}
      </TitleText>
      <SubTitleText style={{ fontStyle: isItalic ? "italic" : "normal" }}>
        {subtitle}
      </SubTitleText>
    </div>
  );
}
