import React from "react";
import { useDispatch, connect } from "react-redux";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import md5 from "md5";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import {
  setDepartmentDataLoad,
  setDepartmentDataSuccess,
  setDepartmentDataError,
} from "../../redux/reducers/globalReducer";
import InfoComponent from "../../components/InfoComponent";

import loginImage from "../../assets/images/loginImage.png";
import { updateData } from "../../utils/apiClient";

function Login(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const loginDepartment = async (event) => {
    try {
      event.preventDefault()
      dispatch(setDepartmentDataLoad());
      const loginData = {
        username: username,
        password: md5(password),
      };
      const loignResp = await updateData("departments/login", loginData);
      if (
        loignResp &&
        loignResp.success &&
        loignResp.data &&
        loignResp.data.isValidPassword
      ) {
        dispatch(
          setDepartmentDataSuccess({
            departmenData: loignResp.data.departmenData,
            token: loignResp.data.token,
          })
        );
        localStorage.setItem("departmentTokenphoneix", loignResp.data.token);
        if(loignResp.data.departmenData.username === 'admin'){
          navigate(`/admin`);
        } else {
          navigate(`/department/${loignResp.data.departmenData.username}`);
        }
      } else {
        dispatch(setDepartmentDataError());
      }
    } catch (error) {
      dispatch(setDepartmentDataError());
      console.log("error", error);
    }
  };  
  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Stack spacing={3}>
            <InfoComponent
              isShowLogo
              title="Login / Authenticate"
              subtitle="Kindly login to authenticate"
            />
            <form onSubmit={loginDepartment}>
              <Stack spacing={3}>
                <Stack
                  spacing={2}
                  sx={{ marginTop: "40px", width: "100%", maxWidth: "452px" }}
                >
                  <TextField
                    id="username"
                    label="Email ID / Username"
                    variant="outlined"
                    fullWidth
                    value={username}
                    onChange={(event) => setUsername(event.target.value)}
                  />
                  <TextField
                    id="password"
                    label="Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </Stack>
                {props.departmentLoginError && (
                  <div style={{ color: "#D34836" }}>
                    Invalid Username or Password
                  </div>
                )}
                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  onClick={loginDepartment}
                  endIcon={<ArrowForwardIcon />}
                  sx={{ maxWidth: "312px" }}
                >
                  {props.departmentLoginLoading ? "Processing..." : "Login"}
                </Button>
              </Stack>
            </form>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <img src={loginImage} alt="Login" width="100%" />
        </Grid>
      </Grid>
    </Container>
  );
}
function mapStateToProps(state) {
  const { global } = state;
  return { ...global };
}

export default connect(mapStateToProps)(Login);
