import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import { styled as muiStyled } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import LoginComponent from "../../components/LoginComponent";
import RadioGroup from "@mui/material/RadioGroup";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import Snackbar from "@mui/material/Snackbar";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import MuiAlert from "@mui/material/Alert";

import InfoComponent from "../../components/InfoComponent";
import { updateData } from "../../utils/apiClient";
import {
  setGiirLoad,
  setGiirSuccess,
  setGiirError,
  setUpdateGiirLoad,
  setUpdateGiirSuccess,
  setUpdateGiirError,
} from "../../redux/reducers/giirReducer";
import { setUserData } from "../../redux/reducers/globalReducer";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledFormControlLabel = muiStyled((props) => (
  <FormControlLabel {...props} />
))(({ checked, color }) => ({
  ".MuiFormControlLabel-label": checked && {
    color: color,
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
}));

function ViewGIIR(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showLoginModal, setShowLoginModal] = React.useState(false);
  const [searchParams] = useSearchParams();
  const [isDisabled, setDisabled] = React.useState(true);
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [showLightBox, setShowLightBox] = React.useState(false);
  const [lightBoxImage, setLightBoxImage] = React.useState(null);
  const [isSnakbarError, seIsSnakbarError] = React.useState(false);
  const [productData, setProductData] = React.useState({
    invoice: "",
    supplier: { name: "" },
    product: { name: "" },
    purchaseOrder: { poid: "" },
    colorAppreance: "",
    pH: "",
    brix: "",
    moistureContent: "",
    nitrogenContent: "",
    proteinContent: "",
    saltContent: "",
    ashValue: "",
    remarks: "",
    isApporved: true,
    isRejected: false,
    radioValue: "",
    grnid: "",
    isacceptedbystore: 0,
  });
  const handleInvoice = (imageUrl) => {
    if (imageUrl.match(/\.(jpg|jpeg|png|gif)$/i)) {
      setShowLightBox(true);
      setLightBoxImage(imageUrl);
    } else {
      window.open(imageUrl, "_blank");
    }
  };
  const handleChange = (event, title) => {
    setProductData((product) => ({ ...product, [title]: event.target.value }));
  };
  const handleRadioChange = (event) => {
    if (event.target.value === "approve") {
      setProductData((product) => ({
        ...product,
        isApporved: true,
        isRejected: false,
        radioValue: event.target.value,
      }));
    } else {
      setProductData((product) => ({
        ...product,
        isApporved: false,
        isRejected: true,
        radioValue: event.target.value,
      }));
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const onUpdateGiir = async () => {
    try {
      if (props.user && props.user.token) {
        dispatch(setUpdateGiirLoad());
        const createGiirData = {
          grnid: productData.grnid,
          giirid: id,
          colourappearance: productData.colorAppreance,
          ph: productData.pH,
          brix: productData.brix,
          moisturecontent: productData.moistureContent,
          nitrogencontent: productData.nitrogenContent,
          proteincontent: productData.proteinContent,
          saltcontent: productData.saltContent,
          ashvalue: productData.ashValue,
          isapproved: productData.isApporved,
          remarks: productData.remarks,
          productid: productData.product.productid,
          userid: props.user.userid,
          usertoken: props.user.token,
        };
        const createGiir = await updateData("/giirs/update", createGiirData);
        if (createGiir.success) {
          dispatch(setUpdateGiirSuccess());
          setOpen(true);
          seIsSnakbarError(false);
          dispatch(setGiirLoad());
          const updatedGiirs = await updateData("/giirs");
          if (updatedGiirs.success) {
            dispatch(setGiirSuccess(updatedGiirs.data));
            navigate("/giir");
          } else {
            dispatch(setGiirError());
          }
          setDisabled(true);
        } else {
          dispatch(setUpdateGiirError());
          setOpen(true);
          seIsSnakbarError(true);
        }
        dispatch(setUserData());
      } else {
        setShowLoginModal(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleModalClose = (loginSuccess) => {
    setShowLoginModal(false);
    if (loginSuccess) {
      onUpdateGiir();
    }
  };
  useEffect(() => {
    const getGiirData = async () => {
      const giirData = await updateData("/giirs/details", { giirid: id });
      if (giirData.success) {
        setProductData({
          grnid: giirData.data.grnid,
          invoice: giirData.data.grns.invoiceimageurl,
          supplier: giirData.data.grns.suppliers,
          product: giirData.data.product,
          purchaseOrder: giirData.data.grns.grnpomappings[0],
          colorAppreance: giirData.data.colourappearance,
          pH: giirData.data.ph,
          brix: giirData.data.brix,
          moistureContent: giirData.data.moisturecontent,
          nitrogenContent: giirData.data.nitrogencontent,
          proteinContent: giirData.data.proteincontent,
          saltContent: giirData.data.saltcontent,
          ashValue: giirData.data.ashvalue,
          remarks: giirData.data.remarks,
          isApporved: giirData.data.isapproved === 1,
          isRejected: giirData.data.isapproved === 1 ? false : true,
          radioValue: giirData.data.isapproved === 1 ? "approve" : "reject",
          isacceptedbystore: giirData.data.isacceptedbystore,
        });
      }
    };
    getGiirData();
  }, [id]);
  useEffect(() => {
    const isEdit = searchParams.get("isEdit");
    if (isEdit && isEdit === "true") {
      setDisabled(false);
    }
  }, [searchParams]);
  return (
    <Container maxWidth="xl" sx={{ marginBottom: "40px" }}>
      {showLightBox && (
        <Lightbox
          mainSrc={lightBoxImage}
          onCloseRequest={() => setShowLightBox(false)}
        />
      )}
      {showLoginModal && (
        <LoginComponent
          showLoginModal={showLoginModal}
          handleModalClose={handleModalClose}
        />
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={isSnakbarError ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {isSnakbarError
            ? "Something went wrong while updating GIIR !"
            : "GIIR Successfully Updated !!"}
        </Alert>
      </Snackbar>
      <InfoComponent
        subtitle="Kindly update the details to update GIIR"
        title="GIIR"
        showBackButton
        backRedirectLink="/giir"
      />
      <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Button
            variant="outlined"
            fullWidth
            endIcon={<ArrowForwardIcon />}
            onClick={() => handleInvoice(productData.invoice)}
          >
            View Invoice
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            id="supplier.name"
            label="Supplier"
            disabled
            variant="outlined"
            fullWidth
            value={productData.supplier.name}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            id="product.name"
            label="Product Name"
            disabled
            variant="outlined"
            fullWidth
            value={productData.product.name}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Button
            variant="outlined"
            fullWidth
            endIcon={<ArrowForwardIcon />}
            onClick={() => navigate(`/po/${productData.purchaseOrder.poid}`)}
          >
            View PO
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            id="receivedQuantity"
            label="Received Quantity"
            disabled
            variant="outlined"
            fullWidth
            value={productData.purchaseOrder.receivedQuantity}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            id="colorAppreance"
            label="Colour Appearance"
            variant="outlined"
            fullWidth
            disabled={isDisabled}
            onChange={(event) => handleChange(event, "colorAppreance")}
            value={productData.colorAppreance}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            id="pH"
            label="pH"
            variant="outlined"
            fullWidth
            disabled={isDisabled}
            onChange={(event) => handleChange(event, "pH")}
            value={productData.pH}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            id="brix"
            label="Brix %"
            variant="outlined"
            fullWidth
            disabled={isDisabled}
            value={productData.brix}
            onChange={(event) => handleChange(event, "brix")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            id="moistureContent"
            label="Moisture Content"
            variant="outlined"
            fullWidth
            disabled={isDisabled}
            value={productData.moistureContent}
            onChange={(event) => handleChange(event, "moistureContent")}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            id="nitrogenContent"
            label="Nitrogen Content"
            variant="outlined"
            fullWidth
            disabled={isDisabled}
            value={productData.nitrogenContent}
            onChange={(event) => handleChange(event, "nitrogenContent")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            id="proteinContent"
            label="Protein Content"
            variant="outlined"
            fullWidth
            disabled={isDisabled}
            value={productData.proteinContent}
            onChange={(event) => handleChange(event, "proteinContent")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            id="saltContent"
            label="Salt Content"
            variant="outlined"
            fullWidth
            disabled={isDisabled}
            value={productData.saltContent}
            onChange={(event) => handleChange(event, "saltContent")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            id="ashValue"
            label="Ash Value"
            variant="outlined"
            fullWidth
            disabled={isDisabled}
            value={productData.ashValue}
            onChange={(event) => handleChange(event, "ashValue")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={12}>
          <FormControl>
            <RadioGroup
              row
              disabled={isDisabled}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={handleRadioChange}
              value={productData.radioValue}
            >
              <StyledFormControlLabel
                checked={productData.isApproved}
                value="approve"
                control={
                  <Radio
                    // checked={productData.isApproved}
                    color="success"
                  />
                }
                label="Approved"
                color="#2e7d32"
              />
              <StyledFormControlLabel
                value="reject"
                control={
                  <Radio
                    // checked={productData.isRejected}
                    color="error"
                  />
                }
                // value="reject"
                label="Rejected"
                color="#d32f2f"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={12}>
          <TextField
            id="Remarks"
            label="Remarks"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            disabled={isDisabled}
            onChange={(event) => handleChange(event, "remarks")}
            value={productData.remarks}
          />
        </Grid>
      </Grid>
      {productData.isacceptedbystore === 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {isDisabled ? (
            <Button
              variant="contained"
              size="large"
              disabled={props.updateGiirLoading}
              onClick={() => setDisabled(false)}
              endIcon={<EditIcon />}
              sx={{ maxWidth: "312px" }}
            >
              {props.updateGiirLoading ? "Processing..." : "Edit"}
            </Button>
          ) : (
            <Button
              variant="contained"
              size="large"
              onClick={onUpdateGiir}
              endIcon={<DoneIcon />}
              sx={{ maxWidth: "312px" }}
            >
              Update
            </Button>
          )}
        </div>
      )}
    </Container>
  );
}
function mapStateToProps(state) {
  const { giir, global } = state;
  return { ...giir, ...global };
}

export default connect(mapStateToProps)(ViewGIIR);
