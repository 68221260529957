import React from "react";
import styled from "styled-components";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoginComponent from "../../components/LoginComponent";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { Carousel } from "react-responsive-carousel";
import "react-image-lightbox/style.css";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import {
  setPrnLoad,
  setPrnSuccess,
  setPrnError,
} from "../../redux/reducers/prnReducer";
import { updateData } from "../../utils/apiClient";
import { setUserData } from "../../redux/reducers/globalReducer";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ProductTitle = styled.p`
  font-size: 36px;
  font-family: "Open Sans";
  font-weight: 700;
  color: #333;
  margin: 0px;
`;
const ProducSubtTitle = styled.p`
  font-size: 18px;
  font-family: "Open Sans";
  font-weight: 400;
  color: #333;
  margin: 0px;
`;
const PRNContainer = styled.div`
  border-radius: 16px;
  border: 1px solid var(--Disabled, #848484);
  background: #fff;
  padding: 24px;
`;
function PRNCard(props) {
  const { produt, isLive } = props;
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [showLoginModal, setShowLoginModal] = React.useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [isSnakbarError, seIsSnakbarError] = React.useState(false);
  const navigate = useNavigate();
  const handleDeletePrn = async () => {
    if (props.user && props.user.token) {
      setDeleteLoading(true);
      const deletResp = await updateData("prns/delete", {
        prnid: produt.prnid,
        userid: props.user.userid,
        usertoken: props.user.token,
      });
      if (deletResp.success) {
        dispatch(setPrnLoad());
        setOpen(true);
        seIsSnakbarError(false);
        setDeleteLoading(false);
        const prns = await updateData("prns");
        if (prns.success) {
          dispatch(setPrnSuccess(prns.data));
        } else {
          dispatch(setPrnError());
        }
      } else {
        setOpen(true);
        seIsSnakbarError(true);
        setDeleteLoading(false);
      }
      dispatch(setUserData());
    } else {
      setShowLoginModal(true);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleModalCloseDelete = (loginSuccess) => {
    try {
      setShowLoginModal(false);
      if (loginSuccess) {
        handleDeletePrn();
      } else {
        setDeleteLoading(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <PRNContainer>
      {showLoginModal && (
        <LoginComponent
          showLoginModal={showLoginModal}
          handleModalClose={handleModalCloseDelete}
        />
      )}
      <Stack direction="row" spacing={4}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity={isSnakbarError ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {isSnakbarError
              ? "Something went wrong while Deleting PRN !"
              : "PRN Successfully Deleted !!"}
          </Alert>
        </Snackbar>
        {produt.productImages && produt.productImages.length > 0 ? (
          <Carousel showThumbs={false}>
            {produt.productImages &&
              produt.productImages.map((mapping) => (
                <img
                  src={mapping.productimageurl}
                  alt="placeholder"
                  style={{
                    borderRadius: "8px",
                    maxWidth: "300px",
                    objectFit: "contain",
                  }}
                />
              ))}
          </Carousel>
        ) : (
          <img
            src="https://placehold.co/240x343"
            alt="placeholder"
            style={{ borderRadius: "8px" }}
          />
        )}
        <Stack direction="column" spacing={2} width="100%">
          <ProductTitle>Product Listing</ProductTitle>
          <Stack direction="row" justifyContent="space-between">
            <ProductTitle style={{ fontSize: "18px" }}>Product</ProductTitle>
            <ProductTitle style={{ fontSize: "18px" }}>
              Qty Requested
            </ProductTitle>
          </Stack>
          {produt.prnproductsmappings &&
            produt.prnproductsmappings.map((product) => (
              <Stack direction="row" justifyContent="space-between">
                <ProducSubtTitle>
                  {product.products && product.products.name}
                </ProducSubtTitle>
                <ProducSubtTitle>
                  {`${product.quantityamount && product.quantityamount}
                                ${
                                  product.products.measurementmetrics
                                    ? product.products.measurementmetrics.name
                                    : ""
                                }`}
                </ProducSubtTitle>
              </Stack>
            ))}
          {props.department && props.department.username !== "sales" ? (
            <Stack spacing={2}>
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => navigate(`/prn/${produt.prnid}?isEdit=true`)}
                  size="large"
                  endIcon={<AddIcon />}
                >
                  Edit PRN
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  color="secondary"
                  onClick={() => navigate(`/prn/${produt.prnid}`)}
                  endIcon={<VisibilityIcon />}
                >
                  View PRN
                </Button>
              </Stack>
              {produt.pos ? (
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => navigate(`/po/${produt.pos[0].poid}`)}
                  size="large"
                >
                  View PO
                </Button>
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  disabled
                  onClick={() => navigate("/po/create")}
                  size="large"
                >
                  Pending PO Creation
                </Button>
              )}
            </Stack>
          ) : (
            <Stack spacing={2}>
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => navigate(`/prn/${produt.prnid}?isEdit=true`)}
                  size="large"
                  endIcon={<AddIcon />}
                >
                  Edit PRN
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  color="secondary"
                  onClick={() => navigate(`/prn/${produt.prnid}`)}
                  endIcon={<VisibilityIcon />}
                >
                  View PRN
                </Button>
              </Stack>
              {produt.pos ? (
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => navigate(`/po/${produt.pos[0].poid}`)}
                  size="large"
                >
                  View PO
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => navigate(`/po/create/${produt.prnid}`)}
                  size="large"
                  endIcon={<AddIcon />}
                >
                  Create PO
                </Button>
              )}
            </Stack>
          )}
          {isLive && (
            <Button
              variant="outlined"
              size="large"
              color="error"
              disabled={deleteLoading}
              onClick={() => handleDeletePrn()}
              fullWidth
              endIcon={<DeleteIcon />}
            >
              {deleteLoading ? "Deleting..." : "Delete PRN"}
            </Button>
          )}
        </Stack>
      </Stack>
    </PRNContainer>
  );
}
function mapStateToProps(state) {
  const { global } = state;
  return { ...global };
}

export default connect(mapStateToProps)(PRNCard);
