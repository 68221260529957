import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import { connect, useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LoginComponent from "../../components/LoginComponent";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { styled as muiStyled } from "@mui/material/styles";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import Container from "@mui/material/Container";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import ProductGRNForm from "../../components/ProductGRNForm";
import InfoComponent from "../../components/InfoComponent";
import { updateData } from "../../utils/apiClient";
import {
  setUpdateGrnLoad,
  setUpdateGrnSuccess,
  setUpdateGrnError,
  setGrnLoad,
  setGrnSuccess,
  setGrnError,
} from "../../redux/reducers/grnReducer";
import { setUserData } from "../../redux/reducers/globalReducer";

const VisuallyHiddenInput = muiStyled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function ViewGRN(props) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showLoginModal, setShowLoginModal] = React.useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [isSnakbarError, seIsSnakbarError] = React.useState(false);
  const [suppliers, setSuppliers] = React.useState([]);
  const [isDisabled, setDisabled] = React.useState(true);
  const [invoiceImg, setInvoiceImg] = React.useState(null);
  const [showLightBox, setShowLightBox] = React.useState(false);
  const [lightBoxImage, setLightBoxImage] = React.useState(null);
  const [goodsImg, setGoodsImg] = React.useState(null);
  const [selectedPo, setselectedPo] = React.useState(null);
  const [products, setProducts] = React.useState([
    {
      category: "",
      grnproductmappingid: "",
      product: "",
      quantity: "",
      poid: "",
      quantityConverted: "",
      measurementmetricid: "",
      unit: "",
      productimageurl: "",
      productid: "product1",
    },
  ]);
  const [selectedSupplier, setSelectedSupplier] = React.useState(null);
  const handleSelectSupplier = (event) => {
    suppliers.forEach((supplier) => {
      if (supplier.supplierid === event.target.value) {
        setSelectedSupplier(supplier);
      }
    });
  };
  const handleSelectPo = (event) => {
    const findPo = selectedSupplier.pos.find(
      (po) => po.poid === event.target.value
    );
    setselectedPo(findPo);
  };
  const addNewProduct = () => {
    setProducts((product) =>
      product.concat([
        {
          category: "",
          product: "",
          quantity: "",
          grnproductmappingid: "",
          unit: "",
          poid: "",
          productimageurl: "",
          quantityConverted: "",
          measurementmetricid: "",
          poQuantityamount: "0",
          productid: `product${product.length + 1}`,
        },
      ])
    );
  };
  const delteProduct = (productid) => {
    const updatedProducts = products.filter(
      (product) => product.productid !== productid
    );
    setProducts([...updatedProducts]);
  };
  const selectProductValue = (productid, value, type) => {
    const updatedProducts = products.map((product) => {
      if (product.productid === productid) {
        product[type] = value;
      }
      return product;
    });
    setProducts([...updatedProducts]);
  };
  const updateGrnData = async () => {
    try {
      if (props.user && props.user.token) {
        dispatch(setUpdateGrnLoad());
        const createGrnData = {
          grnid: id,
          invoiceimageurl: invoiceImg.s3Url,
          goodsreceivedimageurl: goodsImg.s3Url,
          supplierid: selectedSupplier.supplierid,
          poid: selectedPo.poid,
          userid: props.user.userid,
          usertoken: props.user.token,
          products: products.map((product) => {
            let productData = {
              productid: product.product,
              quantityamount: product.quantityConverted.toString(),
              measurementmetricid: product.measurementmetricid,
              productimageurl: product.productimageurl,
            };
            if (
              product.grnproductmappingid &&
              product.grnproductmappingid !== ""
            ) {
              productData = {
                ...productData,
                grnproductmappingid: product.grnproductmappingid,
              };
            }
            return productData;
          }),
        };
        const createGrnResp = await updateData("grns/update", createGrnData);
        if (createGrnResp.success) {
          dispatch(setUpdateGrnSuccess());
          setOpen(true);
          seIsSnakbarError(false);
          dispatch(setGrnLoad());
          const prns = await updateData("grns");
          if (prns.success) {
            dispatch(setGrnSuccess(prns.data));
            navigate("/grn");
          } else {
            dispatch(setGrnError());
          }
        } else {
          dispatch(setUpdateGrnError());
          setOpen(true);
          seIsSnakbarError(true);
        }
        dispatch(setUserData());
      } else {
        setShowLoginModal(true);
      }
      setDisabled(true);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleModalClose = (loginSuccess) => {
    setShowLoginModal(false);
    if (loginSuccess) {
      updateGrnData();
    }
  };
  useEffect(() => {
    const getPrnDetails = async () => {
      const getSuppliersData = await updateData("suppliers/view/pos");
      const getPrnData = await updateData("grns/details", { grnid: id });
      if (getPrnData.success) {
        if (getSuppliersData.success) {
          const supplier = getSuppliersData.data.find(
            (supplier) => supplier.supplierid === getPrnData.data.supplierid
          );
          const selectedPoData = supplier.pos.find(
            (po) => po.poid === getPrnData.data.poid
          );
          setSelectedSupplier(supplier);
          setselectedPo(selectedPoData);
          setSuppliers([...getSuppliersData.data]);
          setGoodsImg({
            s3Url: getPrnData.data.goodsreceivedimageurl,
          });
          setInvoiceImg({
            s3Url: getPrnData.data.invoiceimageurl,
          });
        }
        if (getPrnData.data && getPrnData.data.grnproductmappings) {
          const productsData = getPrnData.data.grnproductmappings.map(
            (mappiing, index) => {
              let unitData = "unit";
              if (
                mappiing.products.measurementmetrics &&
                mappiing.products.measurementmetrics.name === "grams"
              ) {
                unitData = "grams";
              } else if (
                mappiing.products.measurementmetricname &&
                mappiing.products.measurementmetricname === "litres"
              ) {
                unitData = "litres";
              }
              return {
                category: "",
                product: mappiing.productid,
                quantity: mappiing.quantityamount,
                unit: unitData,
                poid: "",
                productimageurl: mappiing.productimageurl,
                quantityConverted: mappiing.quantityamount,
                measurementmetricid: mappiing.measurementmetricid,
                grnproductmappingid: mappiing.grnproductmappingid,
                productid: `product${index + 1}`,
              };
            }
          );
          setProducts([...productsData]);
        }
      }
    };
    getPrnDetails();
  }, [id]);
  useEffect(() => {
    const isEdit = searchParams.get("isEdit");
    if (isEdit && isEdit === "true") {
      setDisabled(false);
    }
  }, [searchParams]);
  console.log("goodsImg", goodsImg);
  return (
    <Container maxWidth="xl" sx={{ marginBottom: "40px" }}>
      {showLoginModal && (
        <LoginComponent
          showLoginModal={showLoginModal}
          handleModalClose={handleModalClose}
        />
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={isSnakbarError ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {isSnakbarError
            ? "Something went wrong while Updatingg GRN !"
            : "GRN Successfully Updated !!"}
        </Alert>
      </Snackbar>
      <InfoComponent
        title="GRN"
        subtitle="Update details to update a GRN."
        showBackButton
        backRedirectLink="/grn"
      />
      {showLightBox && (
        <Lightbox
          mainSrc={lightBoxImage}
          onCloseRequest={() => setShowLightBox(false)}
        />
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={4} lg={6}>
          <Stack spacing={2} direction="row">
            <Button
              variant="contained"
              fullWidth
              endIcon={<ArrowForwardIcon />}
              onClick={() => {
                setShowLightBox(true);
                setLightBoxImage(invoiceImg.s3Url);
              }}
            >
              View Invoice
            </Button>
            <Button
              component="label"
              variant="outlined"
              fullWidth
              disabled={isDisabled}
              endIcon={<CameraAltOutlinedIcon />}
            >
              Update Invoice
              <VisuallyHiddenInput type="file" />
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={6}>
          <Stack spacing={2} direction="row">
            <Button
              variant="contained"
              fullWidth
              endIcon={<ArrowForwardIcon />}
              onClick={() => {
                setShowLightBox(true);
                setLightBoxImage(goodsImg.s3Url);
              }}
            >
              View Goods Image
            </Button>
            <Button
              component="label"
              variant="outlined"
              fullWidth
              disabled={isDisabled}
              endIcon={<CameraAltOutlinedIcon />}
            >
              Upload Goods Received
              <VisuallyHiddenInput type="file" />
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Choose Supplier
            </InputLabel>
            {selectedSupplier && (
              <Select
                disabled={isDisabled}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedSupplier && selectedSupplier.supplierid}
                label="Choose Supplier"
                onChange={handleSelectSupplier}
              >
                {suppliers &&
                  suppliers.length > 0 &&
                  suppliers.map((supplier) => (
                    <MenuItem value={supplier.supplierid}>
                      {supplier.name}
                    </MenuItem>
                  ))}
              </Select>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={6}>
          <FormControl
            fullWidth
            disabled={selectedSupplier === null || isDisabled}
          >
            <InputLabel id="demo-simple-select-label">Choose PO</InputLabel>
            {selectedPo && (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedPo && selectedPo.poid}
                label="Choose Category"
                fullWidth
                onChange={handleSelectPo}
              >
                {selectedSupplier &&
                  selectedSupplier.pos.map((po) => (
                    <MenuItem value={po.poid}>
                      PO For -{" "}
                      {po.products.map((product) => product.name).join("-")}
                    </MenuItem>
                  ))}
              </Select>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Stack spacing={3} sx={{ marginTop: "20px" }}>
        {products &&
          products.map((product) => (
            <ProductGRNForm
              {...product}
              selectedSupplier={selectedSupplier}
              selectedPo={selectedPo}
              isDisabled={isDisabled}
              isView
              delteProduct={delteProduct}
              disabled={isDisabled}
              selectProductValue={selectProductValue}
            />
          ))}
      </Stack>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Button
          variant="outlined"
          size="large"
          onClick={addNewProduct}
          disabled={isDisabled}
          endIcon={<AddIcon />}
          sx={{ maxWidth: "312px", marginTop: "20px", marginBottom: "30px" }}
        >
          Another Product
        </Button>
        {isDisabled ? (
          <Button
            variant="contained"
            size="large"
            onClick={() => setDisabled(false)}
            endIcon={<EditIcon />}
            sx={{ maxWidth: "312px" }}
          >
            Edit
          </Button>
        ) : (
          <Button
            variant="contained"
            size="large"
            onClick={updateGrnData}
            endIcon={<DoneIcon />}
            sx={{ maxWidth: "312px" }}
          >
            Update
          </Button>
        )}
      </div>
    </Container>
  );
}
function mapStateToProps(state) {
  const { grn, global } = state;
  return { ...grn, ...global };
}

export default connect(mapStateToProps)(ViewGRN);
