import {  connect } from "react-redux";
import styled from "styled-components";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { formatDate } from "../../utils/helper";
import logo from "../../assets/images/phoneix-logo.png";

const TitleText = styled.p`
    color: #000000;
    font-family: Open Sans;
    font-size: 22px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
`;

const DateText = styled.p`
    color: #000000;
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
`;

const CreditText = styled.p`
    color: #000000;
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
`;

const RemarkText = styled.p`
    color: #000000;
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
`;

const BCText = styled.p`
    color: #000000;
    font-family: Open Sans;
    font-size: 24px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
`;

const ICContainer = styled.div`
    background: #fff;
    padding: 20px;
    border-radius: 16px;
    border: 2px solid black;
`;

function InvoiceCardDone({ data }){
    const navigate = useNavigate();

    function refineCreditPeriod(creditDetails){
        const creditData = creditDetails.split(",");
        if(creditData[0] && creditData[1]) return creditData[0] + " " + creditData[1];
        return creditDetails;
    }

    return (
        <ICContainer>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <TitleText>Invoice #{data.invoicenumber}</TitleText>
                    <DateText>{formatDate(data.dateofinvoice)}</DateText>
                    <BCText>{data.buyername}</BCText>
                </Grid>
                <Grid item xs={4}>
                    <img
                        src={logo}
                        alt="Company Logo"
                        style={{
                            width: '40%',
                            height: 'auto',
                            margin: '10px',
                            float: 'right'
                        }}
                    />
                </Grid>
            </Grid>
            <div style={{ height: 261, overflow: 'auto' }}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ border: '0.5px solid #CDCDCD', fontWeight : 'bold', padding : 1}}>Product</TableCell>
                                <TableCell sx={{ border: '0.5px solid #CDCDCD', fontWeight : 'bold', padding : 1}}>Qty Rqstd</TableCell>
                                <TableCell sx={{ border: '0.5px solid #CDCDCD', fontWeight : 'bold', padding : 1}}>Delivery Date & Time</TableCell>
                            </TableRow>
                            {data && data.products.map((row) => (
                                <TableRow>
                                    <TableCell
                                    sx={{
                                        borderBottom: 'none',
                                        borderLeft: '0.5px solid #CDCDCD',
                                        padding : 1
                                    }}
                                    >{row.product}</TableCell>
                                    <TableCell
                                    sx={{
                                        borderBottom: 'none',
                                        borderRight: '0.5px solid #CDCDCD',
                                        borderLeft: '0.5px solid #CDCDCD',
                                        padding : 1
                                    }}
                                    >{row.quantity}</TableCell>
                                    <TableCell
                                    sx={{
                                        borderBottom: 'none',
                                        borderRight: '0.5px solid #CDCDCD',
                                        padding : 1
                                    }}
                                    >{formatDate(row.date)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell
                                sx={{
                                    border: '0.5px solid grey',
                                    fontWeight: 'bold',
                                    padding : 1
                                }}
                            >
                            Net Amount
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: '0.5px solid grey',
                                    fontWeight: 'bold',
                                    padding : 1
                                }}
                            >
                            {data.netamount} /-
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                sx={{
                                    border: '0.5px solid grey',
                                    padding : 1
                                }}
                            >
                            Tax
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: '0.5px solid grey',
                                    padding : 1
                                }}
                            >
                            {data.tax} /-
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                sx={{
                                    border: '0.5px solid grey',
                                    fontWeight: 'bold',
                                    color: '#2E3E8C',
                                    padding : 1
                                }}
                            >
                            Gross Amount
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: '0.5px solid grey',
                                    fontWeight: 'bold',
                                    color: '#2E3E8C',
                                    padding : 1
                                }}
                            >
                            {data.grossamount} /-
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <CreditText><b>Credit Period:</b><br /> {refineCreditPeriod(data.credit)} days</CreditText>
            <RemarkText><b>Remarks:</b><br /> {data.remarksinvoice}</RemarkText>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={() => navigate(`/iep/view/`+data.invoiceid)}
                        color="secondary"
                        fullWidth
                        endIcon={<VisibilityIcon />}
                    >
                    View
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={() => navigate(`/popayackn?invoice=`+data.invoiceid)}
                        sx={{
                            backgroundColor : 'white',
                            color: '#24B73C',
                            border: '0.5px solid #24B73C',
                            borderRadius: '10px',
                            '&:hover': {
                                backgroundColor: 'white',
                                color: '#24B73C',
                            },
                        }}
                        fullWidth
                        endIcon={<TaskOutlinedIcon />}
                    >
                    View Payment Acknowledgment
                    </Button>
                </Grid>
            </Grid>
        </ICContainer>
    );
}
function mapStateToProps(state) {
    const { po, global } = state;
    return { ...po, ...global };
}
  
export default connect(mapStateToProps)(InvoiceCardDone);