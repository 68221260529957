import React, { useEffect } from "react";
import styled from "styled-components";
import { connect, useDispatch } from "react-redux";
import PlaceholderLoading from "react-placeholder-loading";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import {
  setPoLoad,
  setPoSuccess,
  setPoError,
} from "../../redux/reducers/poReducer";
import POCard from '../../components/POCard'
import { updateData } from "../../utils/apiClient";
import InfoComponent from "../../components/InfoComponent";
const HistoryDiv = styled.div`
  display: flex;
  padding-top: 24px;
  border-top: 1px solid var(--Disabled, #848484);
  color: var(--Text, #333);
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 24px;
  margin-bottom: 20px;
`;

function PO(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    const getPrnsData = async () => {
      dispatch(setPoLoad());
      const pos = await updateData("/pos");
      if (pos.success) {
        dispatch(setPoSuccess(pos.data));
      } else {
        dispatch(setPoError());
      }
    };
    getPrnsData();
  }, [dispatch]);
  return (
    <Container maxWidth="xl" sx={{ marginBottom: "40px" }}>
      <InfoComponent
        title="PO Listing"
        subtitle="The following are the registered Purchase Orders."
        showBackButton
        backRedirectLink={
          props.department &&
          props.department.username &&
          `/department/${props.department.username}`
        }
      />
      {props.poLoading && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <PlaceholderLoading shape="rect" width="100%" height="400px" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <PlaceholderLoading shape="rect" width="100%" height="400px" />
          </Grid>
        </Grid>
      )}
      {props.poSuccess && (
        <div>
          <Grid container spacing={2}>
            {props.pos &&
              props.pos
                .filter((po) => po.grnQuantity < po.qunantityRequested)
                .map((product) => (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <POCard product={product} isEnded />
                  </Grid>
                ))}
          </Grid>
          <HistoryDiv>History</HistoryDiv>
          <Grid container spacing={2}>
            {props.pos &&
              props.pos
                .filter((po) => po.grnQuantity >= po.qunantityRequested)
                .map((product) => (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <POCard product={product}  />
                  </Grid>
                ))}
          </Grid>
        </div>
      )}
    </Container>
  );
}
function mapStateToProps(state) {
  const { po, global } = state;
  return { ...po, ...global };
}

export default connect(mapStateToProps)(PO);
