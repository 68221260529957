import {  connect } from "react-redux";
import React from "react";
import { useDispatch } from "react-redux";
import {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Route, Link, useParams } from 'react-router-dom';
import Container from "@mui/material/Container";
import InfoComponent from "../../components/InfoComponent";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { getData, updateData } from "../../utils/apiClient";
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs from 'dayjs';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { isNotNullOrEmpty } from "../../utils/helper";
import Switch from "@mui/material/Switch";
import CircularProgress from '@mui/material/CircularProgress';
import { InputAdornment, FormControlLabel } from "@mui/material";

const TitleText = styled.p`
  color: #2e3e8c;
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
`;

async function fetchInoiceData(invoiceid) {
    const getIPOData = await getData("invoice/get?invoiceid="+invoiceid);
    if(getIPOData.success)return getIPOData.data;
    else return [];
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
}); 


function InvoiceEntryPageEdit(props){
    const [isLoading, setIsLoading] = useState(false);
    let { invoiceid } = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState(null);
    const [currency, setCurrency] = React.useState("inr");
    const [invoicenumber, setInvoiceId] = useState(null);
    const [ipoid, setIpoId] = useState(null);
    const [dateofinvoice, setDateOfInvoice] = useState(null);
    const [datetimeofentry, setDateTimeOfEntry] = useState(null);
    const [companyid, setCompanyId] = useState(null);
    const [iponumber, setIpoNumber] = useState(null);
    const [ipodate, setIpoDate] = useState(null);
    const [buyerid, setBuyerId] = useState(null);
    const [credit, setCredit] = useState(null);
    const [remarksinvoice, setRemarksInvoice] = useState(null);
    const [contactperson, setContactPerson] = useState(null);
    const [netamount, setNetAmount] = useState(0);
    const [grossamount, setGrossAmount] = useState(0);
    const [isCreditEnabled, setIsCreditEnabled] = useState(true);

    const handleToggle = (event) => {
        setIsCreditEnabled(event.target.checked);
    };

    const [open, setOpen] = React.useState(false);
    const [snakbarMessage, setSnakbarMessage] = React.useState(null);
    const [isSnakbarError, seIsSnakbarError] = React.useState(false);
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpen(false);
      };

    useEffect(() => {
        fetchInoiceData(invoiceid).then(data => {
            setData(data);
            const record = data[0];
            setInvoiceId(record.invoicenumber);
            setIpoId(record.ipoid);
            setDateOfInvoice(dayjs(record.dateofinvoice));
            setDateTimeOfEntry(dayjs(record.datetimeofentry));
            setCompanyId(record.companyid);
            setContactPerson(record.contactperson);
            setIpoNumber(record.iponumber);
            setIpoDate(dayjs(record.ipodate));
            setBuyerId(record.buyerid);
            setCredit(record.credit);
            if(record.credit === "-") setIsCreditEnabled(false);

            setRemarksInvoice(record.remarksinvoice);
            setProducts([...record.products]);
            const netamounttemp = record.products.reduce((accumulator, currentValue) => {
                return accumulator + parseFloat(currentValue.producttotal);
              }, 0);
            const grossamounttemp = record.products.reduce((accumulator, currentValue) => {
                return accumulator + parseFloat(currentValue.producttotal) + parseFloat(currentValue.producttaxamount);
              }, 0);
            setNetAmount(netamounttemp);
            setGrossAmount(grossamounttemp);

            setOpen(true);
            seIsSnakbarError(false);
            setSnakbarMessage("Data Loading for Invoice.");
        });
    }, []);

    const [products, setProducts] = React.useState([
        {
            category: "-",
            product: "-",
            productid: "",
            sgst: "0",
            cgst: "0",
            igst: "0",
            producttaxamount: "0",
            producttotal: "0",
            productdetail : [
                {
                    quantity: "",
                    unit : "",
                    date: "",
                    ppu:"",
                    totalamount:0
                },
            ],
            remark: ""
        },
    ]);


    const handleSelectCompany = (event) => {
        
    };
    const handleDateChange = (newValue) => {
        
    };

    const handleDeleteProduct = (index) => {
        const product = products.filter((_, i) => i !== index);
        if(product.length === 0){
            product.push({
                category: "-",
                product: "-",
                productid: "",
                sgst: "0",
                cgst: "0",
                igst: "0",
                producttaxamount: "0",
                producttotal: "0",
                productdetail : [
                    {
                        quantity: "",
                        unit : "",
                        date: "",
                        ppu:"0",
                        totalamount:"0"
                    },
                ],
                remark: ""
            });
        }
        setProducts([...product]);
    };

    const handleDeleteSplitProduct = (index1, index2) => {
        const array = products[index1].productdetail;
        const product = array.filter((_, i) => i !== index2);
        if(product.length === 0){
            product.push(
                {
                    quantity: "",
                    unit : "",
                    date: "",
                    ppu: "0",
                    totalamount:"0"
                }
            );
        }
        products[index1].productdetail = product;
        setProducts([...products]);
    };

    const addNewProduct = () => {
        setProducts([...products, 
            {
                category: "-",
                product: "-",
                productid: "",
                sgst: "0",
                cgst: "0",
                igst: "0",
                producttaxamount: "0",
                producttotal: "0",
                productdetail : [
                    {
                        quantity: "",
                        unit : "",
                        date: "",
                        ppu:"0",
                        totalamount:"0"
                    },
                ],
                remark: ""
            },
        ])
    }

    const handleProductUnit = (index1, index2) => (event) => {
        products[index1].productdetail[index2].unit = event.target.value;
        setProducts([...products]);
    };

    const handleDateChangeProduct = (index1, index2, newVal) => {
        products[index1].productdetail[index2].date = new Date(newVal);
        setProducts([...products]);
    };

    const handleProductQuantity = (index1, index2) => (event) => {
        products[index1].productdetail[index2].quantity = event.target.value;
        products[index1].productdetail[index2].totalamount = parseFloat(products[index1].productdetail[index2].quantity)*parseFloat(products[index1].productdetail[index2].ppu);
        products[index1].productdetail[index2].totalamount = products[index1].productdetail[index2].totalamount.toString();
        const productslist = products[index1].productdetail;
        products[index1].producttotal = productslist.reduce((acc, curr) => acc + parseFloat(curr.totalamount), 0);
        products[index1].producttotal = products[index1].producttotal.toString();
        const totaltaxamount = ((parseFloat(products[index1].sgst) + parseFloat(products[index1].cgst) + parseFloat(products[index1].igst)) * parseFloat(products[index1].producttotal))/100;
        products[index1].producttaxamount = totaltaxamount.toString();
        setProducts([...products]);
        const net = products.reduce((acc, curr) => acc + parseFloat(curr.producttotal), 0);
        setNetAmount(net);
        const gross = products.reduce((acc, curr) => acc + parseFloat(curr.producttaxamount), 0);
        setGrossAmount(net + gross);
    };

    const addSplitProductHandler = (index) => {
        const unit = products[index]?.productdetail[0]?.unit;
        products[index].productdetail.push(
            {
                quantity: "",
                unit : unit,
                date: "",
                ppu: "0",
                totalamount:"0"
            }
        );
        setProducts([...products]);
    };

    const handleSGST = (index) => (event) => {
        products[index].sgst = event.target.value;
        const productslist = products[index].productdetail;
        const totalamountofallsubproducts = productslist.reduce((acc, curr) => acc + parseFloat(curr.totalamount), 0);
        const totaltaxamount = ((parseFloat(products[index].sgst) + parseFloat(products[index].cgst) + parseFloat(products[index].igst)) * parseFloat(totalamountofallsubproducts))/100;
        products[index].producttaxamount = totaltaxamount.toString();
        setProducts([...products]);
        const net = products.reduce((acc, curr) => acc + curr.producttotal, 0);
        const gross = products.reduce((acc, curr) => acc + parseFloat(curr.producttaxamount), 0);
        setGrossAmount(net + gross);
    };

    const handleIGST = (index) => (event) => {
        products[index].igst = event.target.value;
        const productslist = products[index].productdetail;
        const totalamountofallsubproducts = productslist.reduce((acc, curr) => acc + parseFloat(curr.totalamount), 0);
        const totaltaxamount = ((parseFloat(products[index].sgst) + parseFloat(products[index].cgst) + parseFloat(products[index].igst)) * parseFloat(totalamountofallsubproducts))/100;
        products[index].producttaxamount = totaltaxamount.toString();
        setProducts([...products]);
        const net = products.reduce((acc, curr) => acc + curr.producttotal, 0);
        const gross = products.reduce((acc, curr) => acc + parseFloat(curr.producttaxamount), 0);
        setGrossAmount(net + gross);
    };

    const handleCGST = (index) => (event) => {
        products[index].cgst = event.target.value;
        const productslist = products[index].productdetail;
        const totalamountofallsubproducts = productslist.reduce((acc, curr) => acc + parseFloat(curr.totalamount), 0);
        const totaltaxamount = ((parseFloat(products[index].sgst) + parseFloat(products[index].cgst) + parseFloat(products[index].igst)) * parseFloat(totalamountofallsubproducts))/100;
        products[index].producttaxamount = totaltaxamount.toString();
        setProducts([...products]);
        const net = products.reduce((acc, curr) => acc + curr.producttotal, 0);
        const gross = products.reduce((acc, curr) => acc + parseFloat(curr.producttaxamount), 0);
        setGrossAmount(net + gross);
    };

    const handleRemark = (index) => (event) => {
        products[index].remark = event.target.value;
        setProducts([...products]);
    };

    const handlePricePerUnit = (index1, index2) => (event) => {
        products[index1].productdetail[index2].ppu = event.target.value;
        products[index1].productdetail[index2].totalamount = parseFloat(products[index1].productdetail[index2].quantity)*parseFloat(products[index1].productdetail[index2].ppu);
        products[index1].productdetail[index2].totalamount = products[index1].productdetail[index2].totalamount.toString();
        const productslist = products[index1].productdetail;
        products[index1].producttotal = productslist.reduce((acc, curr) => acc + parseFloat(curr.totalamount), 0);
        products[index1].producttotal = products[index1].producttotal.toString();
        const totaltaxamount = ((parseFloat(products[index1].sgst) + parseFloat(products[index1].cgst) + parseFloat(products[index1].igst)) * parseFloat(products[index1].producttotal))/100;
        products[index1].producttaxamount = totaltaxamount.toString();
        setProducts([...products]);
        const net = products.reduce((acc, curr) => acc + parseFloat(curr.producttotal), 0);
        setNetAmount(net);
        const gross = products.reduce((acc, curr) => acc + parseFloat(curr.producttaxamount), 0);
        setGrossAmount(net + gross);
    };

    const handleSelectProduct = (index) => (event) => {
        const categoryDetail = props.products.find(
            (category) => category.products.find(
                (product) => product.productid === event.target.value
            )
        );
        products[index].productid = event.target.value;
        products[index].category = categoryDetail.productcategoryid;
        const product = props.products.reduce((foundProduct, category) => {
            return foundProduct || category.products.find(product => product.productid === event.target.value);
          }, null);
        for(let index2=0;index2<products[index].productdetail.length;index2++){
            products[index].productdetail[index2].unit = product?.measurementmetrics?.name;
        }
        setProducts([...products]);
    };

    const handleAddIPO = async () => {
        setIsLoading(true);
        if(!isCreditEnabled) setCredit("-");
        try {
        const createInvoiceData = {
            invoiceid: invoiceid,
            ipoid: ipoid,
            invoicenumber: invoicenumber,
            dateofinvoice : new Date(dateofinvoice),
            datetimeofentry : new Date(datetimeofentry),
            companyid : companyid,
            contactperson : contactperson,
            iponumber: iponumber,
            ipodate: new Date(ipodate),
            buyerid : buyerid,
            products : products,
            credit : credit,
            remarksinvoice : remarksinvoice
        };   

        if(isNotNullOrEmpty(createInvoiceData)){
            const createInvoiceResp = await updateData("invoice/update", createInvoiceData);
            if (createInvoiceResp.success) {
                setIsLoading(false);
                setOpen(true);
                seIsSnakbarError(false);
                setSnakbarMessage("Invoice Created.");
                setTimeout(() => {
                    navigate('/invlist');
                }, 4000);
            } else {
                setIsLoading(false);
                setOpen(true);
                seIsSnakbarError(true);
                setSnakbarMessage("All fields are required.");
            }
        } else {
            setIsLoading(false);
            setOpen(true);
            seIsSnakbarError(true);
            setSnakbarMessage("All fields are required.");
        }
    } catch (error) {
        setIsLoading(false);
        setOpen(true);
        seIsSnakbarError(true);
        setSnakbarMessage("All fields are required.");
    }
    };

    return (
        <Container maxWidth="xl" sx={{ marginBottom: "40px" }}>
            {isLoading && <CircularProgress />}
            <Snackbar
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                <Alert
                onClose={handleClose}
                severity={isSnakbarError ? "error" : "success"}
                sx={{ width: "100%" }}
                >
                {snakbarMessage}
                </Alert>
            </Snackbar>
            {!isLoading && <>
            <InfoComponent
                title="Invoice Entry Page"
                subtitle="Kindly fill the details to create the invoice"
                showBackButton
            />
            <TitleText>Invoice:</TitleText>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        id="invoicenumber"
                        variant="outlined"
                        fullWidth
                        value={invoicenumber}
                        onChange={(event) => setInvoiceId(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                        sx={{ width: "100%" }}
                        label="Date Time of Entry"
                        value={datetimeofentry}
                        disabled={true}
                        onChange={(newValue) => handleDateChange(newValue)}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                        sx={{ width: "100%" }}
                        label="Date of Invoice"
                        value={dayjs(dateofinvoice)}
                        onChange={(newValue) => setDateOfInvoice(newValue)}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControl fullWidth>
                        <Select
                        id="demo-simple-select"
                        label="Company Name"
                        value={companyid}
                        disabled={true}
                        onChange={handleSelectCompany}
                        >
                        {props.companies &&
                            props.companies.map((company) => (
                            <MenuItem value={company.companyid}>{company.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <br />
            <div style={{ width: '100%', borderTop: '1px solid #D3D3D3' }}></div>
            <TitleText>Buyer/Customer:</TitleText>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        id="contactperson"
                        fullWidth
                        value={contactperson}
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControl fullWidth>
                        <TextField
                            id="demo-simple-select"
                            fullWidth
                            disabled={true}
                            value={iponumber}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                        sx={{ width: "100%" }}
                        label="Incoming PO Date"
                        disabled={true}
                        value={dayjs(ipodate)}
                        onChange={(newValue) => handleDateChange(newValue)}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControl fullWidth>
                        <Select
                        id="demo-simple-select"
                        label="Buyer/Customer"
                        onChange={handleSelectCompany}
                        value={buyerid}
                        disabled={true}
                        >
                        {props.buyers &&
                            props.buyers.map((buyer) => (
                            <MenuItem value={buyer.buyerid}>{buyer.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <br />
            <div style={{ width: '100%', borderTop: '1px solid #D3D3D3' }}></div>
            <TitleText>Product:</TitleText>
            <Stack spacing={3}>
                {products &&
                    products.map((product, index) => (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Product</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Product"
                                            value={products[index].productid}
                                            onChange={handleSelectProduct(index)}
                                            >
                                            {props.products &&
                                                props.products.map((products) => (
                                                    products.products.map((product) => (
                                                        <MenuItem value={product.productid}>{product.name} - ({products.name})</MenuItem>
                                                    )
                                                )))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={7}></Grid>
                                    <Grid item xs={1}>
                                        <DeleteIcon style={{ color: 'red', cursor: 'pointer', paddingTop: '20%' }} onClick={() => handleDeleteProduct(index)}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <br />
                                {product.productdetail &&
                                    product.productdetail.map((productData, innerIndex) => (
                                            <Grid container spacing={2} style={{ margin: '3px'}}>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        label="Product Quantity"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={products[index].productdetail[innerIndex].quantity}
                                                        onChange={handleProductQuantity(index, innerIndex)}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <TextField
                                                                    variant="standard"
                                                                    value={products[index].productdetail[innerIndex].unit}
                                                                    disabled
                                                                    />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            sx={{ width: "100%" }}
                                                            label="Delivery Date"
                                                            value={dayjs(products[index].productdetail[innerIndex].date)}
                                                            onChange={(newValue) =>
                                                                handleDateChangeProduct(index, innerIndex, newValue)
                                                            }
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        label="Price Per Unit"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={products[index].productdetail[innerIndex].ppu}
                                                        onChange={handlePricePerUnit(index, innerIndex)}
                                                        InputProps={{
                                                            endAdornment: (
                                                            <TextField
                                                                select
                                                                variant="standard"
                                                                sx={{ width: '25%' }}
                                                                value={currency}
                                                                onChange={(event) => setCurrency(event.target.value)}
                                                            >
                                                                <MenuItem value="inr">INR</MenuItem>
                                                                <MenuItem value="usd">USD</MenuItem>
                                                            </TextField>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        label="Total Amount"
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled={true}
                                                        value={products[index].productdetail[innerIndex].totalamount}
                                                        InputProps={{
                                                            endAdornment: (
                                                            <TextField
                                                                select
                                                                disabled={true}
                                                                value={currency}
                                                                sx={{ width: '25%' }}
                                                                variant="standard"
                                                                onChange={(event) => setCurrency(event.target.value)}
                                                            >
                                                                <MenuItem value="inr">INR</MenuItem>
                                                                <MenuItem value="usd">USD</MenuItem>
                                                            </TextField>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <DeleteIcon style={{ color: 'red', cursor: 'pointer', paddingTop: '20%' }} onClick={() => handleDeleteSplitProduct(index, innerIndex)}/>
                                                </Grid>
                                            </Grid>
                                    ))}
                            <br />
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    id="incomingponumber"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleRemark(index)}
                                    value={products[index].remark}
                                    label="Specifications/Remarks"
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                            <Button
                                variant="contained"
                                size="large"
                                color="secondary"
                                style={{
                                    width: '300px',
                                    marginLeft : '15px',
                                }}
                                onClick={() => addSplitProductHandler(index)}
                                endIcon={<AddIcon />}
                                sx={{ maxWidth: "312px", marginTop: "20px", marginBottom: "10px"  }}
                                >
                                Split
                            </Button>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="incomingponumber"
                                            variant="outlined"
                                            fullWidth
                                            value={products[index].sgst}
                                            label="SGST%"
                                            onChange={handleSGST(index)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="incomingponumber"
                                            variant="outlined"
                                            fullWidth
                                            value={products[index].cgst}
                                            label="CGST%"
                                            onChange={handleCGST(index)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="incomingponumber"
                                            variant="outlined"
                                            fullWidth
                                            value={products[index].igst}
                                            label="IGST%"
                                            onChange={handleIGST(index)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Product Tax Amount"
                                            variant="outlined"
                                            fullWidth
                                            value={products[index].producttaxamount}
                                            disabled={true}
                                            InputProps={{
                                                endAdornment: (
                                                <TextField
                                                    select
                                                    variant="standard"
                                                    sx={{ width: '25%' }}
                                                    value={currency}
                                                    onChange={(event) => setCurrency(event.target.value)}
                                                    disabled={true}
                                                >
                                                    <MenuItem value="inr">INR</MenuItem>
                                                    <MenuItem value="usd">USD</MenuItem>
                                                </TextField>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={9}></Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Product Total"
                                            disabled={true}
                                            variant="outlined"
                                            fullWidth
                                            value={products[index].producttotal}
                                            InputProps={{
                                                endAdornment: (
                                                <TextField
                                                    select
                                                    disabled={true}
                                                    sx={{ width: '25%' }}
                                                    value={currency}
                                                    variant="standard"
                                                    onChange={(event) => setCurrency(event.target.value)}
                                                >
                                                    <MenuItem value="inr">INR</MenuItem>
                                                    <MenuItem value="usd">USD</MenuItem>
                                                </TextField>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            < hr />
                        </Grid>
            ))}            
            </Stack>
            <br />
            <Button
                variant="outlined"
                size="large"
                style={{
                    width: '300px',
                }}
                onClick={() => addNewProduct()}
                endIcon={<AddIcon />}
                sx={{ maxWidth: "312px", marginTop: "20px", marginBottom: "20px" }}
            >
            Another Product
            </Button>
            <br />
            <div style={{ width: '100%', borderTop: '1px solid #D3D3D3' }}></div>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={3} style={{ textAlign: 'right', marginTop : '1%' }}>
                            Net Amount (Before Tax)
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Net Amount"
                                variant="outlined"
                                fullWidth
                                value={netamount}
                                disabled={true}
                                InputProps={{
                                    endAdornment: (
                                    <TextField
                                        select
                                        variant="standard"
                                        sx={{ width: '25%' }}
                                        value={currency}
                                        onChange={(event) => setCurrency(event.target.value)}
                                        disabled={true}
                                    >
                                        <MenuItem value="inr">INR</MenuItem>
                                        <MenuItem value="usd">USD</MenuItem>
                                    </TextField>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={3} style={{ textAlign: 'right', marginTop : '1%' }}>
                            Gross Amount (After Tax)
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Gross Amount"
                                variant="outlined"
                                disabled={true}
                                value={grossamount}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                    <TextField
                                        select
                                        disabled={true}
                                        sx={{ width: '25%' }}
                                        value={currency}
                                        onChange={(event) => setCurrency(event.target.value)}
                                        variant="standard"
                                    >
                                        <MenuItem value="inr">INR</MenuItem>
                                        <MenuItem value="usd">USD</MenuItem>
                                    </TextField>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <br />
            <div style={{ width: '100%', borderTop: '1px solid #D3D3D3' }}></div>
            <TitleText>Note:</TitleText>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={1} lg={1}>
                    <FormControl component="fieldset">
                        <FormControlLabel
                        control={<Switch checked={isCreditEnabled} onChange={handleToggle} />}
                        label="Credit"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                    {isCreditEnabled && (
                        <TextField
                            id="contactperson"
                            variant="outlined"
                            fullWidth
                            value={credit}
                            onChange={(event) => setCredit(event.target.value)}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                    {isCreditEnabled && (
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Credit Unit</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Credit Unit"
                            value="Days"
                            >
                                <MenuItem value="Days">Days</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                        id="contactperson"
                        variant="outlined"
                        value={remarksinvoice}
                        fullWidth
                        onChange={(event) => setRemarksInvoice(event.target.value)}
                        multiline
                        rows={4}
                    />
                </Grid>
            </Grid>
            <br />
            <div style={{ width: '100%', borderTop: '1px solid #D3D3D3' }}></div>
            <Button
                variant="contained"
                size="large"
                style={{
                    width: '300px',
                }}
                onClick={() => handleAddIPO()}
                endIcon={<AddIcon />}
                sx={{ maxWidth: "312px", marginTop: "20px", marginBottom: "30px" }}
            >
            Update
            </Button>
            </> }
        </Container>
    );
}
function mapStateToProps(state) {
    const { global } = state;
    return global;
}
export default connect(mapStateToProps)(InvoiceEntryPageEdit);