import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import React from "react";

function BuyersTypeCreate({alldata}){
    const [buyerName, setBuyerName] = React.useState(null);
    const [address, setAddress] = React.useState(null);
    const [email, setEmail] = React.useState(null);
    const [phone, setPhone] = React.useState(null);
    const [isActive, setIsActive] = React.useState(1);
    const [gstNumber, setGSTNumber] = React.useState(null);

    const handleAdd = async () => {
        const request = {
            name : buyerName,
            address : address,
            email : email,
            phone : phone,
            isactive : isActive,
            gstnumber : gstNumber
        };
        console.log(request);
    };

    return(
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                        id="contactperson"
                        variant="outlined"
                        fullWidth
                        label="Buyer Name"
                        onChange={(event) => setBuyerName(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                        id="contactperson"
                        variant="outlined"
                        fullWidth
                        label="GST Number"
                        onChange={(event) => setGSTNumber(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                        id="contactperson"
                        variant="outlined"
                        fullWidth
                        label="Address"
                        onChange={(event) => setAddress(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                        id="contactperson"
                        variant="outlined"
                        fullWidth
                        label="Email"
                        onChange={(event) => setEmail(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                        id="contactperson"
                        variant="outlined"
                        fullWidth
                        label="Phone"
                        onChange={(event) => setPhone(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">IS Active</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="IS Active"
                        onChange={(event) => setIsActive(event.target.value)}
                        >
                            <MenuItem value="1">Yes</MenuItem>
                            <MenuItem value="0">No</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={5} lg={5}></Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Button
                        variant="contained"
                        size="large"
                        style={{
                            width: '100%',
                        }}
                        endIcon={<AddIcon />}
                        onClick={() => handleAdd()}
                    >
                    Create
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5}></Grid>
            </Grid>
        </div>
    );
}

function mapStateToProps(state) {
    const { po, global } = state;
    return { ...po, ...global };
}
  
export default connect(mapStateToProps)(BuyersTypeCreate);