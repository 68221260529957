import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  giirsLoading: false,
  giirsSuccess: false,
  giirsError: false,
  giirs: [],
  createGiirLoading: false,
  createGiirSuccess: false,
  createGiirError: false,
  updateGiirLoading: false,
  updateGiirSuccess: false,
  updateGiirError: false,
};

export const giirSlice = createSlice({
  name: "giir",
  initialState,
  reducers: {
    setGiirLoad: (state) => {
      state.giirsLoading = true;
      state.giirsSuccess = false;
      state.giirsError = false;
      state.giirs = [];
    },
    setGiirSuccess: (state, action) => {
      state.giirsLoading = false;
      state.giirsSuccess = true;
      state.giirsError = false;
      state.giirs = action.payload;
    },
    setGiirError: (state) => {
      state.giirsLoading = false;
      state.giirsSuccess = false;
      state.giirsError = true;
      state.giirs = [];
    },
    setCreateGiirLoad: (state) => {
      state.createGiirLoading = true;
      state.createGiirSuccess = false;
      state.createGiirError = false;
    },
    setCreateGiirSuccess: (state) => {
      state.createGiirLoading = false;
      state.createGiirSuccess = true;
      state.createGiirError = false;
    },
    setCreateGiirError: (state) => {
      state.createGiirLoading = false;
      state.createGiirSuccess = false;
      state.createGiirError = true;
    },
    setUpdateGiirLoad: (state) => {
      state.updateGiirLoading = true;
      state.updateGiirSuccess = false;
      state.updateGiirError = false;
    },
    setUpdateGiirSuccess: (state) => {
      state.updateGiirLoading = false;
      state.updateGiirSuccess = true;
      state.updateGiirError = false;
    },
    setUpdateGiirError: (state) => {
      state.updateGiirLoading = false;
      state.updateGiirSuccess = false;
      state.updateGiirError = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setGiirLoad,
  setGiirSuccess,
  setGiirError,
  setCreateGiirLoad,
  setCreateGiirSuccess,
  setCreateGiirError,
  setUpdateGiirLoad,
  setUpdateGiirSuccess,
  setUpdateGiirError,
} = giirSlice.actions;

export default giirSlice.reducer;
