import React, { useEffect } from "react";
import styled from "styled-components";
import LogoutIcon from "@mui/icons-material/Logout";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Button from "@mui/material/Button";

import InfoComponent from "../../components/InfoComponent";
import { updateData } from "../../utils/apiClient";
import {
  setNotificationsLoad,
  setNotificationsSuccess,
  setNotificationsError,
} from "../../redux/reducers/globalReducer";

const NotificationDot = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  color: #fff;
  background: var(--Error, #d34836);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
`;

function Department(props) {
  const { department } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [action, setAction] = React.useState("");
  const [departmentData, setDepartmentData] = React.useState(null);
  const handleChange = (event) => {
    setAction(event.target.value);
  };
  const getNotificationCount = (type) => {
    if (props.notifications) {
      if (type === "View GRNs") {
        return parseFloat(props.notifications.grnCount);
      } else if (type === "View PRNs") {
        return parseFloat(props.notifications.prnCount);
      } else if (type === "View GIIRs") {
        return parseFloat(props.notifications.giirCount);
      } else if (type === "View POs") {
        return parseFloat(props.notifications.poCount);
      }
    }
    return 0;
  };
  const logOutDepartment = () => {
    localStorage.removeItem("departmentTokenphoneix");
    navigate('/login');
  }
  useEffect(() => {
    if (props.departmentSuccess && props.departments) {
      const departmentResp = props.departments.find(
        (eachDepartment) => eachDepartment.username === department
      );
      setDepartmentData(departmentResp);
    }
  }, [department, props.departmentSuccess, props.departments]);
  useEffect(() => {
    const getCount = async () => {
      dispatch(setNotificationsLoad());
      const notifications = await updateData("departments/getNotifications");
      if (notifications.success) {
        dispatch(setNotificationsSuccess(notifications.data));
      } else {
        dispatch(setNotificationsError());
      }
    };
    getCount();
  }, [dispatch]);

  return (
    <div>
      {departmentData ? (
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Stack spacing={3}>
                <InfoComponent
                  isShowLogo
                  title={`Welcome to ${departmentData.name}`}
                  subtitle="Select the action you would like to perform"
                />
                {departmentData.username !== "qc" && (
                  <Stack spacing={3}>
                    <FormControl fullWidth sx={{ maxWidth: "452px" }}>
                      <InputLabel id="demo-simple-select-label">
                        Choose Action
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={action}
                        label="Choose Action"
                        onChange={handleChange}
                      >
                        {departmentData.roles &&
                          departmentData.roles
                            .filter((role) => role.routepath !== null)
                            .map((role) => (
                              <MenuItem value={`/${role.routepath}`}>
                                <Stack
                                  width="100%"
                                  spacing={2}
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  {role.accesstypename}
                                  {getNotificationCount(role.accesstypename) >
                                    0 && (
                                    <NotificationDot>
                                      {getNotificationCount(
                                        role.accesstypename
                                      )}
                                    </NotificationDot>
                                  )}
                                </Stack>
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                    <Button
                      variant="contained"
                      size="large"
                      disabled={action === ""}
                      endIcon={<ArrowForwardIcon />}
                      onClick={() => navigate(action)}
                      sx={{ maxWidth: "312px" }}
                    >
                      Proceed
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={logOutDepartment}
                      endIcon={<LogoutIcon />}
                      sx={{ maxWidth: "312px" }}
                    >
                      Log Out
                    </Button>
                  </Stack>
                )}
                {departmentData.username === "qc" && (
                  <Stack spacing={3}>
                    <Button
                      variant="contained"
                      size="large"
                      onClick={() => navigate("/rawmaterials")}
                      endIcon={<InsertDriveFileOutlinedIcon />}
                      sx={{ maxWidth: "312px" }}
                    >
                      View Raw Materials
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      color="secondary"
                      onClick={() => navigate("/giir")}
                      endIcon={<DifferenceOutlinedIcon />}
                      sx={{ maxWidth: "312px" }}
                    >
                      View GIIRs
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={logOutDepartment}
                      endIcon={<LogoutIcon />}
                      sx={{ maxWidth: "312px" }}
                    >
                      Log Out
                    </Button>
                  </Stack>
                )}
                <Divider
                  variant="middle"
                  sx={{ maxWidth: "452px", background: "#848484" }}
                />
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  fullWidth
                  onClick={() => navigate("/inventory")}
                  endIcon={<VisibilityIcon />}
                  sx={{ maxWidth: "312px" }}
                >
                  View Inventory
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Stack alignItems="center" justifyContent="center" height="100%">
                <img src={departmentData.imageurl} alt="Login" width="100%" />
              </Stack>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <div />
      )}
    </div>
  );
}
function mapStateToProps(state) {
  const { global } = state;
  return global;
}

export default connect(mapStateToProps)(Department);
