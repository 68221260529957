import { connect } from "react-redux";
import TableBody from '@mui/material/TableBody';
import React from "react";
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { useState } from "react";
import TextField from '@mui/material/TextField';
import { Container, Grid } from "@mui/material";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { activeState, formatDate, formatOnlyDate, isNotNullOrEmpty } from "../../../utils/helper";
import { updateData } from "../../../utils/apiClient";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Tabs, Tab, Box } from '@mui/material';
import AccessTypeCreate from "../../AdminCreate/AccessTypeCreate";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});  

const TitleText = styled.p`
  color: #2e3e8c;
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
`;

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
            <div>{children}</div>
            </Box>
        )}
        </div>
    );
};

function AccessType({ data, alldata }){
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredData = data.tabledata.filter((row) => {
        // Filter based on searchQuery
        return Object.values(row).some((value) =>
        value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
    });

    const [open, setOpen] = React.useState(false);
    const [snakbarMessage, setSnakbarMessage] = React.useState(null);
    const [isSnakbarError, seIsSnakbarError] = React.useState(false);
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpen(false);
      };

    const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const showTab = (tabName) => {
    if (tabName === 'CREATE') {
        setSelectedTab(0);
    } else if (tabName === 'UPDATE') {
        setSelectedTab(1);
    }
    };

    return (
        <>
        <div>
            <Snackbar
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                <Alert
                onClose={handleClose}
                severity={isSnakbarError ? "error" : "success"}
                sx={{ width: "100%" }}
                >
                {snakbarMessage}
                </Alert>
            </Snackbar>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 2 }}>
                <TextField
                label="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                fullWidth
                sx={{ width: '25%' }}
                />
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{ fontWeight : 'bold', minWidth : '200px' }}>Access Type ID</TableCell>
                            <TableCell sx={{ fontWeight : 'bold', minWidth : '200px' }}>Access Type Name</TableCell>
                            <TableCell sx={{ fontWeight : 'bold', minWidth : '200px' }}>Is Active</TableCell>
                            <TableCell sx={{ fontWeight : 'bold', minWidth : '200px' }}>Created ON</TableCell>
                            <TableCell sx={{ fontWeight : 'bold', minWidth : '200px' }}>Modified ON</TableCell>
                            <TableCell sx={{ fontWeight : 'bold', minWidth : '200px' }}>Routepath</TableCell>
                            <TableCell sx={{ fontWeight : 'bold', minWidth : '200px' }}>Order</TableCell>
                        </TableRow>
                    </TableBody>
                    <TableBody>
                        {filteredData && filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <TableRow>
                                <TableCell>{ row.accesstypeid }</TableCell>
                                <TableCell>{ row.accesstypename }</TableCell>
                                <TableCell>{ activeState(row.isactive) }</TableCell>
                                <TableCell>{ formatDate(row.createdon) }</TableCell>
                                <TableCell>{ formatDate(row.modifiedon) }</TableCell>
                                <TableCell>{ row.routepath }</TableCell>
                                <TableCell>{ row.order }</TableCell>
                            </TableRow>  
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
        <div>
            <Tabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="CREATE" />
                {/* <Tab label="UPDATE" /> */}
            </Tabs>
            <TabPanel value={selectedTab} index={0}>
                <AccessTypeCreate alldata={alldata}/>
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
                UPDATE
            </TabPanel>
        </div>
      </>
    );
}

function mapStateToProps(state) {
    const { po, global } = state;
    return { ...po, ...global };
}
  
export default connect(mapStateToProps)(AccessType);