import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import custstyled from "styled-components";
import PlaceholderLoading from "react-placeholder-loading";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { styled as muiStyled } from "@mui/material/styles";
import { connect, useDispatch } from "react-redux";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import Container from "@mui/material/Container";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import LoginComponent from "../../components/LoginComponent";
import ProductGRNForm from "../../components/ProductGRNForm";
import InfoComponent from "../../components/InfoComponent";
import { updateData, updateFormData } from "../../utils/apiClient";
import {
  setCreateGrnLoad,
  setCreateGrnSuccess,
  setCreateGrnError,
  setGrnLoad,
  setGrnSuccess,
  setGrnError,
} from "../../redux/reducers/grnReducer";
import { setUserData } from "../../redux/reducers/globalReducer";

const TitleText = custstyled.p`
  font-size: 24px;
  font-family: "Open Sans";
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  margin: 0px;
`;
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const VisuallyHiddenInput = muiStyled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
function CreateGRN(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLightBox, setShowLightBox] = React.useState(false);
  const [posLoading, setPosLoading] = React.useState(false);
  const [lightBoxImage, setLightBoxImage] = React.useState(null);
  const [suppliers, setSuppliers] = React.useState([]);
  const [selectedPo, setselectedPo] = React.useState(null);
  const [showLoginModal, setShowLoginModal] = React.useState(false);
  const [invoiceImg, setInvoiceImg] = React.useState(null);
  const [goodsImg, setGoodsImg] = React.useState(null);
  const [selectedSupplier, setSelectedSupplier] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [isSnakbarError, seIsSnakbarError] = React.useState(false);
  const [products, setProducts] = React.useState([
    {
      category: "",
      product: "",
      quantity: "",
      poid: "",
      quantityConverted: "",
      measurementmetricid: "",
      unit: "",
      productimageurl: "",
      productid: "product1",
    },
  ]);
  const addNewProduct = () => {
    setProducts((product) =>
      product.concat([
        {
          category: "",
          product: "",
          quantity: "",
          unit: "",
          poid: "",
          productimageurl: "",
          quantityConverted: "",
          measurementmetricid: "",
          productid: `product${product.length + 1}`,
        },
      ])
    );
  };
  const delteProduct = (productid) => {
    const updatedProducts = products.filter(
      (product) => product.productid !== productid
    );
    setProducts([...updatedProducts]);
  };
  const selectProductValue = (productid, value, type) => {
    const updatedProducts = products.map((product) => {
      if (product.productid === productid) {
        product[type] = value;
      }
      return product;
    });
    setProducts([...updatedProducts]);
  };
  const handleSelectSupplier = (event) => {
    suppliers.forEach((supplier) => {
      if (supplier.supplierid === event.target.value) {
        setSelectedSupplier(supplier);
      }
    });
  };
  const handleInvoice = async (event) => {
    try {
      if (event.target.files[0]) {
        const imageUrl = URL.createObjectURL(event.target.files[0]);
        setInvoiceImg({
          name: event.target.files[0].name,
          size: event.target.files[0].size,
          imageUrl,
          s3Url: null,
          uploadFileTrue: true,
          uploadFileSuccess: false,
        });
        const fileUploadData = new FormData();
        fileUploadData.append("file", event.target.files[0]);
        fileUploadData.append("path", "grn/invoice");
        const fileUploadResp = await updateFormData(
          "files/upload",
          fileUploadData
        );
        if (fileUploadResp.success && fileUploadResp.data) {
          setInvoiceImg((invoice) => ({
            ...invoice,
            s3Url: fileUploadResp.data.file.fileurl,
            uploadFileTrue: false,
            uploadFileSuccess: true,
          }));
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleGoodsImg = async (event) => {
    try {
      if (event.target.files[0]) {
        const imageUrl = URL.createObjectURL(event.target.files[0]);
        setGoodsImg({
          name: event.target.files[0].name,
          size: event.target.files[0].size,
          imageUrl,
          s3Url: null,
          uploadFileTrue: true,
          uploadFileSuccess: false,
        });
        const fileUploadData = new FormData();
        fileUploadData.append("file", event.target.files[0]);
        fileUploadData.append("path", "grn/goodsreceived");
        const fileUploadResp = await updateFormData(
          "files/upload",
          fileUploadData
        );
        if (fileUploadResp.success && fileUploadResp.data) {
          setGoodsImg((invoice) => ({
            ...invoice,
            s3Url: fileUploadResp.data.file.fileurl,
            uploadFileTrue: false,
            uploadFileSuccess: true,
          }));
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleSelectPo = (event) => {
    const findPo = selectedSupplier.pos.find(
      (po) => po.poid === event.target.value
    );
    setselectedPo(findPo);
  };
  const handleAddGRN = async () => {
    try {
      if (props.user && props.user.token) {
        dispatch(setCreateGrnLoad());
        const createGrnData = {
          invoiceimageurl: invoiceImg.s3Url,
          goodsreceivedimageurl: goodsImg.s3Url,
          supplierid: selectedSupplier.supplierid,
          poid: selectedPo.poid,
          userid: props.user.userid,
          usertoken: props.user.token,
          products: products.map((product) => ({
            productid: product.product,
            quantityamount: product.quantityConverted.toString(),
            measurementmetricid: product.measurementmetricid,
            productimageurl: product.productimageurl,
          })),
        };
        const createGrnResp = await updateData("grns/create", createGrnData);
        if (createGrnResp.success) {
          dispatch(setCreateGrnSuccess());
          dispatch(setGrnLoad());
          const prns = await updateData("/grns");
          if (prns.success) {
            dispatch(setGrnSuccess(prns.data));
          } else {
            dispatch(setGrnError());
          }
        } else {
          dispatch(setCreateGrnError());
        }
        dispatch(setUserData());
      } else {
        setShowLoginModal(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleModalClose = (loginSuccess) => {
    setShowLoginModal(false);
    if (loginSuccess) {
      handleAddGRN();
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  useEffect(() => {
    const suppliersData = async () => {
      setPosLoading(true);
      const getSuppliersData = await updateData("/suppliers/pos");
      if (getSuppliersData.success) {
        setSuppliers([...getSuppliersData.data]);
      }
      setPosLoading(false);
    };
    suppliersData();
  }, []);
  useEffect(() => {
    if (props.createGrnsprnsSuccess) {
      setOpen(true);
      seIsSnakbarError(false);
      // navigate("/grn");
      window.location.href = "/grn";
    }
    if (props.createGrnsError) {
      setOpen(true);
      seIsSnakbarError(true);
    }
  }, [navigate, props.createGrnsError, props.createGrnsprnsSuccess]);
  return (
    <Container maxWidth="xl" sx={{ marginBottom: "40px" }}>
      {showLightBox && (
        <Lightbox
          mainSrc={lightBoxImage}
          onCloseRequest={() => setShowLightBox(false)}
        />
      )}
      {showLoginModal && (
        <LoginComponent
          showLoginModal={showLoginModal}
          handleModalClose={handleModalClose}
        />
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={isSnakbarError ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {isSnakbarError
            ? "Something went wrong while Creating GRN !"
            : "Grn Successfully Created !!"}
        </Alert>
      </Snackbar>
      <InfoComponent
        title="GRN"
        subtitle="Kindly fill the details to create a GRN."
        showBackButton
        backRedirectLink={
          props.department &&
          props.department.username &&
          `/department/${props.department.username}`
        }
      />
      {posLoading ? (
        <div>
          <PlaceholderLoading shape="rect" width="100%" height="400px" />
        </div>
      ) : (
        <div>
          {suppliers && suppliers.length === 0 ? (
            <TitleText>There are no pending Pos to create GRN</TitleText>
          ) : (
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={4} lg={6}>
                  <Stack direction="row" spacing={2}>
                    <Button
                      fullWidth
                      disabled={invoiceImg === null || invoiceImg.s3Url === ""}
                      sx={{ height: "50px" }}
                      variant="contained"
                      endIcon={<ArrowForwardIcon />}
                      onClick={() => {
                        setShowLightBox(true);
                        setLightBoxImage(invoiceImg.s3Url);
                      }}
                    >
                      View Picture
                    </Button>
                    <div style={{ width: "100%" }}>
                      <Button
                        component="label"
                        variant="outlined"
                        fullWidth
                        endIcon={<CameraAltOutlinedIcon />}
                      >
                        Upload Invoice
                        <VisuallyHiddenInput
                          type="file"
                          onChange={handleInvoice}
                        />
                      </Button>
                      {invoiceImg && (
                        <div style={{ marginTop: "10px" }}>
                          File: {invoiceImg.name}
                        </div>
                      )}
                    </div>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={6}>
                  <Stack direction="row" spacing={2}>
                    <Button
                      fullWidth
                      disabled={goodsImg === null || goodsImg.s3Url === ""}
                      sx={{ height: "50px" }}
                      variant="contained"
                      endIcon={<ArrowForwardIcon />}
                      onClick={() => {
                        setShowLightBox(true);
                        setLightBoxImage(goodsImg.s3Url);
                      }}
                    >
                      View Picture
                    </Button>
                    <div style={{ width: "100%" }}>
                      <Button
                        component="label"
                        variant="outlined"
                        fullWidth
                        endIcon={<CameraAltOutlinedIcon />}
                      >
                        Upload Goods Received
                        <VisuallyHiddenInput
                          type="file"
                          onChange={handleGoodsImg}
                        />
                      </Button>
                      {goodsImg && (
                        <div style={{ marginTop: "10px" }}>
                          File: {goodsImg.name}
                        </div>
                      )}
                    </div>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Choose Supplier
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      fullWidth
                      value={selectedSupplier && selectedSupplier.supplierid}
                      label="Choose Supplier"
                      onChange={handleSelectSupplier}
                    >
                      {suppliers &&
                        suppliers.length > 0 &&
                        suppliers.map((supplier) => (
                          <MenuItem value={supplier.supplierid}>
                            {supplier.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Choose PO
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedPo && selectedPo.poid}
                      disabled={selectedSupplier === null}
                      label="Choose Category"
                      fullWidth
                      onChange={handleSelectPo}
                    >
                      {selectedSupplier &&
                        selectedSupplier.pos.map((po) => (
                          <MenuItem value={po.poid}>
                            PO For -{" "}
                            {po.products
                              .map((product) => product.name)
                              .join("-")}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Stack spacing={3} sx={{ marginTop: "20px" }}>
                {products &&
                  products.map((product) => (
                    <ProductGRNForm
                      {...product}
                      selectedSupplier={selectedSupplier}
                      selectedPo={selectedPo}
                      delteProduct={delteProduct}
                      selectProductValue={selectProductValue}
                    />
                  ))}
              </Stack>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Button
                  variant="outlined"
                  size="large"
                  onClick={addNewProduct}
                  endIcon={<AddIcon />}
                  sx={{
                    maxWidth: "312px",
                    marginTop: "20px",
                    marginBottom: "30px",
                  }}
                >
                  Another Product
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  // disabled={props.createGrnsLoading}
                  onClick={handleAddGRN}
                  endIcon={<AddIcon />}
                  sx={{ maxWidth: "312px" }}
                >
                  {props.createGrnsLoading ? "Processing..." : "Create"}
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </Container>
  );
}
function mapStateToProps(state) {
  const { grn, global } = state;
  return { ...grn, ...global };
}

export default connect(mapStateToProps)(CreateGRN);
