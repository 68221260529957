import React, { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import headerLogo from "../../assets/images/headerLogo.png";
import {
  setDepartmentLoad,
  setDepartmentSuccess,
  setDepartmentError,
  setDepartmentDataSuccess,
  setProductsLoad,
  setProductsSuccess,
  setProductsError,
  setSuppliersLoad,
  setSuppliersSuccess,
  setSuppliersError,
  setRawMaterialsLoad,
  setRawMaterialsSuccess,
  setRawMaterialsError,
  setInventoriesLoad,
  setInventoriesSuccess,
  setInventoriesError,
  setCompaniesLoad,
  setCompaniesSuccess,
  setCompaniesError,
  setBuyerLoad,
  setBuyerSuccess,
  setBuyerError,
} from "../../redux/reducers/globalReducer";
import { getData, updateData } from "../../utils/apiClient";
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { Badge, Box } from '@mui/material';
import Notifications from "../Notifications";
import { isNotNullOrEmpty } from "../../utils/helper";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

async function fetchNotifications() {
  const getNotifications = await getData("notification/all");
  if(getNotifications.success)return getNotifications.data;
  else return [];
}

async function fetchDepartmentData(token) {
  const getDepartmentData = await getData("jwt/department/"+token);
  if(getDepartmentData.success)return getDepartmentData.type;
  else return [];
}

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const getDepartments = async () => {
      try {
        dispatch(setDepartmentLoad());
        const getDepartmentData = await updateData("/departments");
        if (getDepartmentData.success) {
          dispatch(setDepartmentSuccess(getDepartmentData.data));
        } else {
          dispatch(setDepartmentError());
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    const getDepartmentData = async (token) => {
      const getDeptDetails = await updateData(
        "/departments/getdetailsFromToken",
        { token }
      );
      if (getDeptDetails && getDeptDetails.success) {
        dispatch(
          setDepartmentDataSuccess({
            departmenData: getDeptDetails.data,
            token: token,
          })
        );
      }
    };
    const productsAndSuppliers = async () => {
      try {
        dispatch(setProductsLoad());
        const productCategories = await updateData("/productcategories");
        if (productCategories.success) {
          dispatch(setProductsSuccess(productCategories.data));
        } else {
          dispatch(setProductsError());
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    const getBuyers = async () => {
      try {
        dispatch(setBuyerLoad());
        const buyerCategories = await updateData("buyers/all");
        if (buyerCategories.success) {
          dispatch(setBuyerSuccess(buyerCategories.data));
        } else {
          dispatch(setBuyerError());
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    const getSuppliers = async () => {
      try {
        dispatch(setSuppliersLoad());
        const suppliersData = await updateData("suppliers/all");
        if (suppliersData.success) {
          dispatch(setSuppliersSuccess(suppliersData.data));
        } else {
          dispatch(setSuppliersError());
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    const getRawMaterials = async () => {
      try {
        dispatch(setRawMaterialsLoad());
        const suppliersData = await updateData("products/rawmaterials");
        if (suppliersData.success) {
          dispatch(setRawMaterialsSuccess(suppliersData.data));
        } else {
          dispatch(setInventoriesError());
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    const checkDepartmentToken = localStorage.getItem("departmentTokenphoneix");
    if (checkDepartmentToken) {
      getDepartmentData(checkDepartmentToken);
    } else {
      navigate("/login");
    }
    const getInventories = async () => {
      try {
        dispatch(setInventoriesLoad());
        const suppliersData = await updateData("inventories");
        if (suppliersData.success) {
          dispatch(setInventoriesSuccess(suppliersData.data));
        } else {
          dispatch(setRawMaterialsError());
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    const getComapines = async () => {
      try {
        dispatch(setCompaniesLoad());
        const companiesData = await updateData("companies");
        if (companiesData.success) {
          dispatch(setCompaniesSuccess(companiesData.data));
        } else {
          dispatch(setCompaniesError());
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    getRawMaterials();
    getSuppliers();
    getBuyers();
    productsAndSuppliers();
    getDepartments();
    getInventories();
    getComapines();

    fetchNotifications().then(data => {
      const tables = data;
      const token = localStorage.getItem('departmentTokenphoneix');
      if (token) {
        fetchDepartmentData(token).then(datas => {
          const allowed = datas;
          setStoredName(allowed);
          const filteredNotifications = tables.filter(item => allowed.includes(item.notificationtype));
          setNotifications([...filteredNotifications]);
          const count = filteredNotifications.filter(obj => obj.readstatus === 1).length;
          setNotificationCount(count);
        });
      }
    });
  }, [dispatch, navigate]);

  const [storedName, setStoredName] = useState([]);
  const [notificationCount, setNotificationCount] = React.useState(0);
  const [notifications, setNotifications] = React.useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State for controlling the popup
  const [popupData, setPopupData] = useState(null); // State to hold data for the popup

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const togglePopup = async () => {
    if(isPopupOpen){
      try {
          if(notificationCount > 0){
            const notificationid = notifications.map(obj => obj.notificationid);
            const readNotifications = {
              notificationid : notificationid
            };
            const readNotificationsResp = await updateData("notification/read", readNotifications);
            if (readNotificationsResp.success) {
              console.log("done");
              setNotificationCount(0);
            } else {
              console.log("else");
            }
          }
      } catch (error) {
          console.log(error);
      }
    }
    setIsPopupOpen(!isPopupOpen);
  };

  const handlePopupOpen = () => {
    setPopupData(notifications);
    togglePopup();
  };

  const logoutClick = () => {
    localStorage.removeItem("departmentTokenphoneix");
    navigate('/login');
  };

  return (
    <Container maxWidth="xl" sx={{ marginBottom: "20px", marginTop: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {localStorage.getItem('departmentTokenphoneix') && (
          <>
            <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              marginRight: '50px',
              cursor: 'pointer'
            }}
            onClick={handlePopupOpen}
          >
            <NotificationsActiveOutlinedIcon style={{ color: 'darkblue', fontSize: '40px' }} />
            <Badge
              badgeContent={notificationCount}
              color="error"
              overlap="circular"
              sx={{
                position: 'absolute',
                top: 20,
                right: -20,
                '& .MuiBadge-badge': {
                  border: `2px solid white`,
                  padding: '0 4px',
                  fontSize: '1rem',
                  height: '35px',
                  minWidth: '35px',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              }}
            />
            </Box>
            <PowerSettingsNewIcon style={{ color: 'red', fontSize: '40px', cursor: 'pointer' }} onClick={logoutClick} />
          </>
        )}
        <img src={headerLogo} alt="Phoneix" width="160px" />
      </div>
      {popupData && localStorage.getItem('departmentTokenphoneix') && (
        <Notifications open={isPopupOpen} onClose={togglePopup} popupData={popupData} />
      )}
    </Container>
  );
}
function mapStateToProps(state) {
  const { global } = state;
  return global;
}

export default connect(mapStateToProps)(Header);
