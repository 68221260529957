import { connect, useDispatch } from "react-redux";
import React, { useEffect, useState } from 'react';
import Container from "@mui/material/Container";
import InfoComponent from "../../components/InfoComponent";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import { getData } from "../../utils/apiClient";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AccessType from "../../components/AdminTab/AccessType";
import BuyersType from "../../components/AdminTab/BuyersType";
import CompanyTab from "../../components/AdminTab/CompanyTab";
import DepartmentAccessMappingTab from "../../components/AdminTab/DepartmentAccessMappingTab";
import DepartmentsType from "../../components/AdminTab/DepartmentsType";
import GIIRTab from "../../components/AdminTab/GIIRTab";
import GrnPoMappingTab from "../../components/AdminTab/GrnPoMappingTab";
import GrnProductMapping from "../../components/AdminTab/GrnProductMapping";
import Grns from "../../components/AdminTab/Grns";
import Invoices from "../../components/AdminTab/Invoices";
import InvoiceProductsListMapping from "../../components/AdminTab/InvoiceProductsListMapping";
import InvoiceProductsMapping from "../../components/AdminTab/InvoiceProductsMapping";
import IPOs from "../../components/AdminTab/IPOs";
import IpoProductsListMapping from "../../components/AdminTab/IpoProductsListMapping";
import IpoProductsMapping from "../../components/AdminTab/IpoProductsMapping";
import Measurementmetrics from "../../components/AdminTab/Measurementmetrics";
import POTab from "../../components/AdminTab/POTab";
import PoProductMappingTab from "../../components/AdminTab/PoProductMappingTab";
import PrnProductsMapping from "../../components/AdminTab/PrnProductsMapping";
import PrnsTab from "../../components/AdminTab/PrnsTab";
import ProductCategorytab from "../../components/AdminTab/ProductCategorytab";
import ProductDeliveryScheduleTab from "../../components/AdminTab/ProductDeliveryScheduleTab";
import Products from "../../components/AdminTab/Products";
import ProductSupplierMappingTab from "../../components/AdminTab/ProductSupplierMappingTab";
import SuppliersTab from "../../components/AdminTab/SuppliersTab";
import UserDepartmentMappingTab from "../../components/AdminTab/UserDepartmentMappingTab";
import CircularProgress from '@mui/material/CircularProgress';
import Users from "../../components/AdminTab/Users";

function TabPanel(props) {
    const { children, value, index, data, alldata, ...other } = props;
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box p={3}>
                {data && data.tablename && data.tablename === "AccessType" && <>
                    <AccessType data={data} alldata={alldata}></AccessType>
                </>}
                {data && data.tablename && data.tablename === "Buyers" && <>
                    <BuyersType data={data} alldata={alldata}></BuyersType>
                </>}
                {data && data.tablename && data.tablename === "Company" && <>
                    <CompanyTab data={data} alldata={alldata}></CompanyTab>
                </>}
                {data && data.tablename && data.tablename === "Department Access Mapping" && <>
                    <DepartmentAccessMappingTab data={data} alldata={alldata}></DepartmentAccessMappingTab>
                </>}
                {data && data.tablename && data.tablename === "Departments" && <>
                    <DepartmentsType data={data} alldata={alldata}></DepartmentsType>
                </>}
                {data && data.tablename && data.tablename === "GIIR" && <>
                    <GIIRTab data={data} alldata={alldata}></GIIRTab>
                </>}
                {data && data.tablename && data.tablename === "GRN PO Mapping" && <>
                    <GrnPoMappingTab data={data} alldata={alldata}></GrnPoMappingTab>
                </>}
                {data && data.tablename && data.tablename === "GRN Product Mapping" && <>
                    <GrnProductMapping data={data} alldata={alldata}></GrnProductMapping>
                </>}
                {data && data.tablename && data.tablename === "GRNs" && <>
                    <Grns data={data} alldata={alldata}></Grns>
                </>}
                {data && data.tablename && data.tablename === "Invoices" && <>
                    <Invoices data={data} alldata={alldata}></Invoices>
                </>}
                {data && data.tablename && data.tablename === "Invoice Products List Mapping" && <>
                    <InvoiceProductsListMapping data={data} alldata={alldata}></InvoiceProductsListMapping>
                </>}
                {data && data.tablename && data.tablename === "Invoice Products Mapping" && <>
                    <InvoiceProductsMapping data={data} alldata={alldata}></InvoiceProductsMapping>
                </>}
                {data && data.tablename && data.tablename === "IPOs" && <>
                    <IPOs data={data} alldata={alldata}></IPOs>
                </>}
                {data && data.tablename && data.tablename === "Ipo Products List Mapping" && <>
                    <IpoProductsListMapping data={data} alldata={alldata}></IpoProductsListMapping>
                </>}
                {data && data.tablename && data.tablename === "Ipo Products Mapping" && <>
                    <IpoProductsMapping data={data} alldata={alldata}></IpoProductsMapping>
                </>}
                {data && data.tablename && data.tablename === "Measurementmetrics" && <>
                    <Measurementmetrics data={data} alldata={alldata}></Measurementmetrics>
                </>}
                {data && data.tablename && data.tablename === "PO" && <>
                    <POTab data={data} alldata={alldata}></POTab>
                </>}
                {data && data.tablename && data.tablename === "Po Product Mapping" && <>
                    <PoProductMappingTab data={data} alldata={alldata}></PoProductMappingTab>
                </>}
                {data && data.tablename && data.tablename === "Prn Products Mapping" && <>
                    <PrnProductsMapping data={data} alldata={alldata}></PrnProductsMapping>
                </>}
                {data && data.tablename && data.tablename === "Prns" && <>
                    <PrnsTab data={data} alldata={alldata}></PrnsTab>
                </>}
                {data && data.tablename && data.tablename === "Product Category" && <>
                    <ProductCategorytab data={data} alldata={alldata}></ProductCategorytab>
                </>}
                {data && data.tablename && data.tablename === "Product Delivery Schedule" && <>
                    <ProductDeliveryScheduleTab data={data} alldata={alldata}></ProductDeliveryScheduleTab>
                </>}
                {data && data.tablename && data.tablename === "Products" && <>
                    <Products data={data} alldata={alldata}></Products>
                </>}
                {data && data.tablename && data.tablename === "Product Supplier Mapping" && <>
                    <ProductSupplierMappingTab data={data} alldata={alldata}></ProductSupplierMappingTab>
                </>}
                {data && data.tablename && data.tablename === "Suppliers" && <>
                    <SuppliersTab data={data} alldata={alldata}></SuppliersTab>
                </>}
                {data && data.tablename && data.tablename === "User Department Mapping" && <>
                    <UserDepartmentMappingTab data={data} alldata={alldata}></UserDepartmentMappingTab>
                </>}
                {data && data.tablename && data.tablename === "Users" && <>
                    <Users data={data} alldata={alldata}></Users>
                </>}
            </Box>
        )}
        </div>
    );
}

async function fetchAccessType() {
    const getDetails = await getData("admin/accesstype/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchBuyersType() {
    const getDetails = await getData("admin/buyers/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchCompanyType() {
    const getDetails = await getData("admin/company/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchDepartmentAccessMappingType() {
    const getDetails = await getData("admin/departmentaccessmapping/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchDepartments() {
    const getDetails = await getData("admin/departments/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchGiirs() {
    const getDetails = await getData("admin/giir/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchGrnPoMapping() {
    const getDetails = await getData("admin/grnpomapping/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchGrnProductMapping() {
    const getDetails = await getData("admin/grnproductmapping/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchGrnsMapping() {
    const getDetails = await getData("admin/grns/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchInvoiceMapping() {
    const getDetails = await getData("admin/invoice/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchInvoiceProductsListMappingMapping() {
    const getDetails = await getData("admin/invoiceproductslistmapping/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchInvoiceProductsMappingMapping() {
    const getDetails = await getData("admin/invoiceproductsmapping/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchIPOs() {
    const getDetails = await getData("admin/ipo/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchIpoProductsListMapping() {
    const getDetails = await getData("admin/ipoproductslistmapping/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchIpoProductsMapping() {
    const getDetails = await getData("admin/ipoproductsmapping/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchMeasurementmetrics() {
    const getDetails = await getData("admin/measurementmetrics/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchPo() {
    const getDetails = await getData("admin/po/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchPoProductMapping() {
    const getDetails = await getData("admin/poproductmapping/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchPrnProductsMapping() {
    const getDetails = await getData("admin/prnproductsmapping/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchPrns() {
    const getDetails = await getData("admin/prns/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchProductCategory() {
    const getDetails = await getData("admin/productcategory/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchProductDeliverySchedule() {
    const getDetails = await getData("admin/productdeliveryschedule/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchProducts() {
    const getDetails = await getData("admin/products/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchProductSupplierMapping() {
    const getDetails = await getData("admin/productsuppliermapping/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchSuppliers() {
    const getDetails = await getData("admin/suppliers/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchUserDepartmentMapping() {
    const getDetails = await getData("admin/userdepartmentmapping/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchUsers() {
    const getDetails = await getData("admin/users/all");
    if(getDetails.success)return getDetails.data;
    else return [];
}

async function fetchDepartmentData(token) {
    const getDepartmentData = await getData("jwt/departmentname/"+token);
    if(getDepartmentData.success)return getDepartmentData;
    else return {success : false};
}

function Admin(props){
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const [tables, setTables] = useState(
        [
            {
                "tablename" : "AccessType",
                "tabledata" : [],
                "loaded" : false
            },
            {
                "tablename" : "Buyers",
                "tabledata" : [],
"loaded" : false
            },
            {
                "tablename" : "Company",
                "tabledata" : [],
"loaded" : false
            },
            {
                "tablename" : "Department Access Mapping",
                "tabledata" : [],
"loaded" : false
            },
            {
                "tablename" : "Departments",
                "tabledata" : [],
"loaded" : false
            },
            {
                "tablename" : "GIIR",
                "tabledata" : [],
"loaded" : false
            },
            {
                "tablename" : "GRN PO Mapping",
                "tabledata" : [],
"loaded" : false
            },
            {
                "tablename" : "GRN Product Mapping",
                "tabledata" : [],
"loaded" : false
            },
            {
                "tablename" : "GRNs",
                "tabledata" : [],
"loaded" : false
            },
            {
                "tablename" : "Invoices",
                "tabledata" : [],
"loaded" : false
            },
            {
                "tablename" : "Invoice Products List Mapping",
                "tabledata" : [],
"loaded" : false
            },
            {
                "tablename" : "Invoice Products Mapping",
                "tabledata" : [],
"loaded" : false
            },
            {
                "tablename" : "IPOs",
                "tabledata" : [],
"loaded" : false
            },
            {
                "tablename" : "Ipo Products List Mapping",
                "tabledata" : [],
"loaded" : false
            },
            {
                "tablename" : "Ipo Products Mapping",
                "tabledata" : [],
"loaded" : false
            },
            {
                "tablename" : "Measurementmetrics",
                "tabledata" : [],
"loaded" : false
            },
            {
                "tablename" : "PO",
                "tabledata" : [],
"loaded" : false
            },
            {
                "tablename" : "Po Product Mapping",
                "tabledata" : [],
"loaded" : false
            },
            {
                "tablename" : "Prn Products Mapping",
                "tabledata" : [],
"loaded" : false
            },
            {
                "tablename" : "Prns",
                "tabledata" : [],
"loaded" : false
            },
            {
                "tablename" : "Product Category",
                "tabledata" : [],
"loaded" : false
            },
            {
                "tablename" : "Product Delivery Schedule",
                "tabledata" : [],
"loaded" : false
            },
            {
                "tablename" : "Products",
                "tabledata" : [],
"loaded" : false
            },
            {
                "tablename" : "Product Supplier Mapping",
                "tabledata" : [],
"loaded" : false
            },
            {
                "tablename" : "Suppliers",
                "tabledata" : [],
"loaded" : false
            },
            {
                "tablename" : "User Department Mapping",
                "tabledata" : [],
"loaded" : false
            },
            {
                "tablename" : "Users",
                "tabledata" : [],
"loaded" : false
            }
        ]
    );

    useEffect(() => {
        const token = localStorage.getItem('departmentTokenphoneix');
        fetchDepartmentData(token).then(datas => {
            if(datas && datas.success){
                if(datas.store === "admin"){
                    fetchAccessType().then(data => {
                        tables[0].tabledata = data;
                        tables[0].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                    fetchBuyersType().then(data => {
                        tables[1].tabledata = data;
                        tables[1].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                    fetchCompanyType().then(data => {
                        tables[2].tabledata = data;
                        tables[2].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                    fetchDepartmentAccessMappingType().then(data => {
                        tables[3].tabledata = data;
                        tables[3].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                    fetchDepartments().then(data => {
                        tables[4].tabledata = data;
                        tables[4].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                    fetchGiirs().then(data => {
                        tables[5].tabledata = data;
                        tables[5].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                    fetchGrnPoMapping().then(data => {
                        tables[6].tabledata = data;
                        tables[6].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                    fetchGrnProductMapping().then(data => {
                        tables[7].tabledata = data;
                        tables[7].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                    fetchGrnsMapping().then(data => {
                        tables[8].tabledata = data;
                        tables[8].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                    fetchInvoiceMapping().then(data => {
                        tables[9].tabledata = data;
                        tables[9].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                    fetchInvoiceProductsListMappingMapping().then(data => {
                        tables[10].tabledata = data;
                        tables[10].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                    fetchInvoiceProductsMappingMapping().then(data => {
                        tables[11].tabledata = data;
                        tables[11].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                    fetchIPOs().then(data => {
                        tables[12].tabledata = data;
                        tables[12].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                    fetchIpoProductsListMapping().then(data => {
                        tables[13].tabledata = data;
                        tables[13].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                    fetchIpoProductsMapping().then(data => {
                        tables[14].tabledata = data;
                        tables[14].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                    fetchMeasurementmetrics().then(data => {
                        tables[15].tabledata = data;
                        tables[15].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                    fetchPo().then(data => {
                        tables[16].tabledata = data;
                        tables[16].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                    fetchPoProductMapping().then(data => {
                        tables[17].tabledata = data;
                        tables[17].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                    fetchPrnProductsMapping().then(data => {
                        tables[18].tabledata = data;
                        tables[18].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                    fetchPrns().then(data => {
                        tables[19].tabledata = data;
                        tables[19].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                    fetchProductCategory().then(data => {
                        tables[20].tabledata = data;
                        tables[20].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                    fetchProductDeliverySchedule().then(data => {
                        tables[21].tabledata = data;
                        tables[21].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                    fetchProducts().then(data => {
                        tables[22].tabledata = data;
                        tables[22].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                    fetchProductSupplierMapping().then(data => {
                        tables[23].tabledata = data;
                        tables[23].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                    fetchSuppliers().then(data => {
                        tables[24].tabledata = data;
                        tables[24].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                    fetchUserDepartmentMapping().then(data => {
                        tables[25].tabledata = data;
                        tables[25].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                    fetchUsers().then(data => {
                        tables[26].tabledata = data;
                        tables[26].loaded = true;
                        setTables([...tables]);
                        checkForLoader();
                    });
                } else {
                    navigate(`/department/${datas.store}`)
                }
            } else {
                localStorage.removeItem("departmentTokenphoneix");
                navigate('/login');
            }
        });
    }, []);

    const checkForLoader = () => {
        const count = tables.filter(item => item.loaded === true).length;
        if(count === tables.length){
            console.log(tables)
            setIsLoading(false);
        }
        else setIsLoading(true);
    }

    const [value, setValue] = useState(0);

    const array = Array.from({ length: 2 }, (_, index) => index);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleLeftClick = () => {
        if(value === 0 ){
            const newValue = tables.length - 1;
            setValue(newValue);
        } else {
            const newValue = value - 1;
            setValue(newValue);
        }
    };

    const handleRightClick = () => {
        if(value === tables.length - 1){
            const newValue = 0;
            setValue(newValue);
        } else {
            const newValue = value + 1;
            setValue(newValue);
        }
    };

    return (
        <Container maxWidth="xl" sx={{ marginBottom: "40px" }}>
            {isLoading && <CircularProgress />}
            <InfoComponent
                title="Master Data Panel"
                subtitle="Admin View"
                showBackButton
            />
            {!isLoading && <>
                <div>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <ArrowBackIosIcon onClick={handleLeftClick} sx={{cursor:'pointer'}} />
                        <Tabs
                            value={value} 
                            onChange={handleChange} 
                            aria-label="tabs"
                            scrollButtons="auto"
                            sx={{
                                borderBottom: '1px solid #4282BF',                      
                            }}
                        >
                            {tables &&
                                tables.map((table) => (
                                    <Tab
                                        label={table.tablename}
                                        id="tab-0" 
                                        sx={{ 
                                            minWidth: '300px',
                                            fontSize: '20px',
                                            backgroundColor: '#D3D3D3',
                                            color: 'black',
                                            borderTopLeftRadius: '8px',
                                            borderTopRightRadius: '8px',
                                            marginLeft: '0.5%',
                                            marginRight: '0.5%',
                                            '&.Mui-selected': {
                                                backgroundColor: '#4282BF',
                                                color: 'white',
                                            },
                                        }} 
                                    />
                            ))}
                        </Tabs>
                        <ArrowForwardIosIcon onClick={handleRightClick} sx={{cursor:'pointer'}} />
                    </Box>
                    {tables &&
                        tables.map((table, index) => (
                            <TabPanel value={value} index={index} data={table} alldata={tables}>
                                {table.tablename}
                            </TabPanel>            
                    ))}
                </div>
            </>}
        </Container>
    );
}

function mapStateToProps(state) {
    const { global } = state;
    return global;
}
export default connect(mapStateToProps)(Admin);