import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";

function UsersCreate({alldata}){
    return(
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                        id="contactperson"
                        variant="outlined"
                        fullWidth
                        label="Username"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                        id="contactperson"
                        variant="outlined"
                        fullWidth
                        label="Password Salt"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                        id="contactperson"
                        variant="outlined"
                        fullWidth
                        label="Password Hash"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">IS Active</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="IS Active"
                        >
                            <MenuItem value="1">Yes</MenuItem>
                            <MenuItem value="0">No</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={5} lg={5}></Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Button
                        variant="contained"
                        size="large"
                        style={{
                            width: '100%',
                        }}
                        endIcon={<AddIcon />}
                    >
                    Create
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5}></Grid>
            </Grid>
        </div>
    );
}

function mapStateToProps(state) {
    const { po, global } = state;
    return { ...po, ...global };
}
  
export default connect(mapStateToProps)(UsersCreate);