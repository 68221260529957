import {  connect } from "react-redux";
import React from "react";
import { useDispatch } from "react-redux";
import {useState, useEffect} from "react";
import { BrowserRouter as Router, Route, Link, useParams, useNavigate } from 'react-router-dom';
import Container from "@mui/material/Container";
import InfoComponent from "../../components/InfoComponent";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { getData, updateData } from "../../utils/apiClient";
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs from 'dayjs';
import { isNotNullOrEmpty } from "../../utils/helper";
import Snackbar from "@mui/material/Snackbar";
import Switch from "@mui/material/Switch";
import CircularProgress from '@mui/material/CircularProgress';
import MuiAlert from "@mui/material/Alert";
import { FormControlLabel, InputAdornment } from "@mui/material";

const TitleText = styled.p`
  color: #2e3e8c;
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
`;

const MatchesSuccess = styled.p`
  color: #24B73C;
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
`;

const MatchesFailed = styled.p`
  color: #D34836;
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
`;

async function fetchIPOData(ipo) {
    const getIPOData = await getData("ipoe/get?ipoid="+ipo);
    if(getIPOData.success)return getIPOData.data;
    else return [];
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
}); 

function InvoiceEntryPage(props){
    const navigate = useNavigate(); 
    const [isLoading, setIsLoading] = useState(false);
    let { ipoid } = useParams();

    const [data, setData] = useState(null);
    const [dateTimeOfEntry, setDateTimeOfEntry] = React.useState(null);
    const [contactPerson, setContactPerson] = React.useState(null);
    const [incomingPONumber, setIncomingPONumber] = React.useState(null);
    const [incomingPODate, setIncomingPODate] = React.useState(null);
    const [buyerCustomer, setBuyerCustomer] = React.useState(null);
    const [companyName, setCompanyName] = React.useState(null);
    const [invoicenumber, setInvoiceNumber] = React.useState(null);
    const [dateofinvoice, setDateofInvoice] = React.useState(null);
    const [netamount, setNetAmount] = React.useState(0);
    const [grossamount, setGrossAmount] = React.useState(0);
    const [credit, setCredit] = React.useState(0);
    const [remarksinvoice, setRemarkInvoice] = React.useState(0);
    const [currency, setCurrency] = React.useState("inr");
    const [isCreditEnabled, setIsCreditEnabled] = useState(true);

    const handleToggle = (event) => {
        setIsCreditEnabled(event.target.checked);
    };

    useEffect(() => {
        fetchIPOData(ipoid).then(data => {
            setData(data);
            const record = data[0];
            setContactPerson(record.contactperson);
            setIncomingPONumber(record.iponumber);
            setDateTimeOfEntry(dayjs(record.ipodate));
            setIncomingPODate(dayjs(record.ipodate));
            setBuyerCustomer(record.buyerid);
            setCompanyName(record.companyid);
            setProducts(record.products);
            setNetAmount(record.netamount);
            setGrossAmount(record.grossamount);
        });
        fetchIPOData(ipoid).then(data => {
            const record = data[0];
            setDefaultProduct(record.products);
        });
    }, []);

    const [defaultProduct, setDefaultProduct] = React.useState(null);
    const [products, setProducts] = React.useState([
        {
            category: "-",
            product: "-",
            productid: "",
            sgst: 0,
            cgst: 0,
            igst: 0,
            producttaxamount: 0,
            producttotal: 0,
            productdetail : [
                {
                    quantity: "",
                    unit : "",
                    date: "",
                    ppu:"0",
                    totalamount:0
                },
            ],
            remark: ""
        },
    ]);


    const handleSelectCompany = (event) => {
        
    };
    const handleDateChange = (newValue) => {
        
    };

    const handleDateChangeProduct = (index1, index2, newVal) => {
        products[index1].productdetail[index2].date = new Date(newVal);
        setProducts([...products]);
    };

    const handleProductQuantity = (index1, index2) => (event) => {
        products[index1].productdetail[index2].quantity = event.target.value;
        products[index1].productdetail[index2].totalamount = parseFloat(products[index1].productdetail[index2].quantity)*parseFloat(products[index1].productdetail[index2].ppu);
        const productslist = products[index1].productdetail;
        products[index1].producttotal = productslist.reduce((acc, curr) => acc + curr.totalamount, 0);
        const totaltaxamount = ((parseFloat(products[index1].sgst) + parseFloat(products[index1].cgst) + parseFloat(products[index1].igst)) * parseFloat(products[index1].producttotal))/100;
        products[index1].producttaxamount = totaltaxamount;
        setProducts([...products]);
        const net = products.reduce((acc, curr) => acc + curr.producttotal, 0);
        setNetAmount(net);
        const gross = products.reduce((acc, curr) => acc + curr.producttaxamount, 0);
        setGrossAmount(net + gross);
    };

    const handleProductUnit = (index1, index2) => (event) => {
        products[index1].productdetail[index2].unit = event.target.value;
        setProducts([...products]);
    };

    const handleDeleteProduct = (index) => {
        const product = products.filter((_, i) => i !== index);
        if(product.length === 0){
            product.push({
                category: "-",
                product: "-",
                productid: "",
                sgst: 0,
                cgst: 0,
                igst: 0,
                producttaxamount: 0,
                producttotal: 0,
                productdetail : [
                    {
                        quantity: "",
                        unit : "",
                        date: "",
                        ppu:"0",
                        totalamount:0
                    },
                ],
                remark: ""
            });
        }
        setProducts([...product]);
    };

    const handleDeleteSplitProduct = (index1, index2) => {
        const array = products[index1].productdetail;
        const product = array.filter((_, i) => i !== index2);
        if(product.length === 0){
            product.push(
                {
                    quantity: "",
                    unit : "",
                    date: "",
                    ppu: "0",
                    totalamount:0
                }
            );
        }
        products[index1].productdetail = product;
        setProducts([...products]);
    };

    const addNewProduct = () => {
        setProducts([...products, 
            {
                category: "-",
                product: "-",
                productid: "",
                sgst: 0,
                cgst: 0,
                igst: 0,
                producttaxamount: 0,
                producttotal: 0,
                productdetail : [
                    {
                        quantity: "",
                        unit : "",
                        date: "",
                        ppu:"0",
                        totalamount:0
                    },
                ],
                remark: ""
            },
        ])
    }

    const addSplitProductHandler = (index) => {
        const unit = products[index]?.productdetail[0]?.unit;
        products[index].productdetail.push(
            {
                quantity: "",
                unit : unit,
                date: "",
                ppu: "0",
                totalamount:0
            }
        );
        setProducts([...products]);
    };

    const handleSGST = (index) => (event) => {
        products[index].sgst = event.target.value;
        const productslist = products[index].productdetail;
        const totalamountofallsubproducts = productslist.reduce((acc, curr) => acc + curr.totalamount, 0);
        const totaltaxamount = ((parseFloat(products[index].sgst) + parseFloat(products[index].cgst) + parseFloat(products[index].igst)) * parseFloat(totalamountofallsubproducts))/100;
        products[index].producttaxamount = totaltaxamount;
        setProducts([...products]);
        const net = products.reduce((acc, curr) => acc + curr.producttotal, 0);
        const gross = products.reduce((acc, curr) => acc + curr.producttaxamount, 0);
        setGrossAmount(net + gross);
    };

    const handleRemark = (index) => (event) => {
        products[index].remark = event.target.value;
        setProducts([...products]);
    };

    const handleIGST = (index) => (event) => {
        products[index].igst = event.target.value;
        const productslist = products[index].productdetail;
        const totalamountofallsubproducts = productslist.reduce((acc, curr) => acc + curr.totalamount, 0);
        const totaltaxamount = ((parseFloat(products[index].sgst) + parseFloat(products[index].cgst) + parseFloat(products[index].igst)) * parseFloat(totalamountofallsubproducts))/100;
        products[index].producttaxamount = totaltaxamount;
        setProducts([...products]);
        const net = products.reduce((acc, curr) => acc + curr.producttotal, 0);
        const gross = products.reduce((acc, curr) => acc + curr.producttaxamount, 0);
        setGrossAmount(net + gross);
    };

    const handleCGST = (index) => (event) => {
        products[index].cgst = event.target.value;
        const productslist = products[index].productdetail;
        const totalamountofallsubproducts = productslist.reduce((acc, curr) => acc + curr.totalamount, 0);
        const totaltaxamount = ((parseFloat(products[index].sgst) + parseFloat(products[index].cgst) + parseFloat(products[index].igst)) * parseFloat(totalamountofallsubproducts))/100;
        products[index].producttaxamount = totaltaxamount;
        setProducts([...products]);
        const net = products.reduce((acc, curr) => acc + curr.producttotal, 0);
        const gross = products.reduce((acc, curr) => acc + curr.producttaxamount, 0);
        setGrossAmount(net + gross);
    };

    const handlePricePerUnit = (index1, index2) => (event) => {
        products[index1].productdetail[index2].ppu = event.target.value;
        products[index1].productdetail[index2].totalamount = parseFloat(products[index1].productdetail[index2].quantity)*parseFloat(products[index1].productdetail[index2].ppu);
        const productslist = products[index1].productdetail;
        products[index1].producttotal = productslist.reduce((acc, curr) => acc + curr.totalamount, 0);
        const totaltaxamount = ((parseFloat(products[index1].sgst) + parseFloat(products[index1].cgst) + parseFloat(products[index1].igst)) * parseFloat(products[index1].producttotal))/100;
        products[index1].producttaxamount = totaltaxamount;
        setProducts([...products]);
        const net = products.reduce((acc, curr) => acc + curr.producttotal, 0);
        setNetAmount(net);
        const gross = products.reduce((acc, curr) => acc + curr.producttaxamount, 0);
        setGrossAmount(net + gross);
    };

    const handleSelectProduct = (index) => (event) => {
        const categoryDetail = props.products.find(
            (category) => category.products.find(
                (product) => product.productid === event.target.value
            )
        );
        products[index].productid = event.target.value;
        products[index].category = categoryDetail.productcategoryid;
        const product = props.products.reduce((foundProduct, category) => {
            return foundProduct || category.products.find(product => product.productid === event.target.value);
          }, null);
        for(let index2=0;index2<products[index].productdetail.length;index2++){
            products[index].productdetail[index2].unit = product?.measurementmetrics?.name;
        }
        setProducts([...products]);
    };

    const quantityCheckForProduct = (productid, type) => {
        if(productid != ""){
            let dp = [];
            if(defaultProduct){
                defaultProduct.forEach(p => {
                    if(p.productid === productid){
                        dp = p.productdetail;
                    }
                })
            }

            let pp = [];
            if(products){
                products.forEach(p => {
                    if(p.productid === productid){
                        pp = p.productdetail;
                    }
                })
            }

            const psum = pp.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.quantity), 0);
            const productsum = dp.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.quantity), 0);

            if(type === "text") return (psum === productsum);
            else if(type === "total") return productsum;
            else if(type === "quan") return psum;
        }
        return false;
    };

    const [open, setOpen] = React.useState(false);
    const [snakbarMessage, setSnakbarMessage] = React.useState(null);
    const [isSnakbarError, seIsSnakbarError] = React.useState(false);
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpen(false);
      };

    const handleAddIPO = async () => {
        setIsLoading(true);
        if(!isCreditEnabled) setCredit("-");
        try {
            const createInvoiceData = {
                ipoid: ipoid,
                invoicenumber: invoicenumber,
                dateofinvoice : new Date(dateofinvoice),
                datetimeofentry : new Date(dateTimeOfEntry),
                companyid : companyName,
                contactperson : contactPerson,
                iponumber: incomingPONumber,
                ipodate: new Date(incomingPODate),
                buyerid : buyerCustomer,
                products : products,
                credit : credit,
                remarksinvoice : remarksinvoice
            };

                if(isNotNullOrEmpty(createInvoiceData)){
                    const createInvoiceResp = await updateData("invoice/create", createInvoiceData);
                    if (createInvoiceResp.success) {
                        setIsLoading(false);
                        setOpen(true);
                        seIsSnakbarError(false);
                        setSnakbarMessage("Invoice Created.");
                        setTimeout(() => {
                            navigate('/invlist');
                        }, 4000);
                    } else {
                        setIsLoading(false);
                        setOpen(true);
                        seIsSnakbarError(true);
                        setSnakbarMessage("All fields are required.");
                    }
                } else {
                    setIsLoading(false);
                    setOpen(true);
                    seIsSnakbarError(true);
                    setSnakbarMessage("All fields are required.");
                }
            } catch (error) {
                setIsLoading(false);
                setOpen(true);
                seIsSnakbarError(true);
                setSnakbarMessage("All fields are required.");
            }
      };

    return (
        <Container maxWidth="xl" sx={{ marginBottom: "40px" }}>
            {isLoading && <CircularProgress />}
            <Snackbar
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                <Alert
                onClose={handleClose}
                severity={isSnakbarError ? "error" : "success"}
                sx={{ width: "100%" }}
                >
                {snakbarMessage}
                </Alert>
            </Snackbar>
            {!isLoading && <>
            <InfoComponent
                title="Invoice Entry Page"
                subtitle="Kindly fill the details to create the invoice"
                showBackButton
            />
            <TitleText>Invoice:</TitleText>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        id="invoicenumber"
                        variant="outlined"
                        fullWidth
                        label="Invoice Number"
                        value={invoicenumber}
                        onChange={(event) => setInvoiceNumber(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                        sx={{ width: "100%" }}
                        label="Date Time of Entry"
                        value={dateTimeOfEntry}
                        disabled={true}
                        onChange={(newValue) => handleDateChange(newValue)}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                        sx={{ width: "100%" }}
                        label="Date of Invoice"
                        onChange={(newValue) => setDateofInvoice(newValue)}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControl fullWidth>
                        <Select
                        id="demo-simple-select"
                        label="Company Name"
                        value={companyName}
                        disabled={true}
                        onChange={handleSelectCompany}
                        >
                        {props.companies &&
                            props.companies.map((company) => (
                            <MenuItem value={company.companyid}>{company.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <br />
            <div style={{ width: '100%', borderTop: '1px solid #D3D3D3' }}></div>
            <TitleText>Buyer/Customer:</TitleText>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        id="contactperson"
                        fullWidth
                        value={contactPerson}
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControl fullWidth>
                        <TextField
                            id="demo-simple-select"
                            fullWidth
                            disabled={true}
                            value={incomingPONumber}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                        sx={{ width: "100%" }}
                        label="Incoming PO Date"
                        disabled={true}
                        value={incomingPODate}
                        onChange={(newValue) => handleDateChange(newValue)}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControl fullWidth>
                        <Select
                        id="demo-simple-select"
                        label="Buyer/Customer"
                        onChange={handleSelectCompany}
                        value={buyerCustomer}
                        disabled={true}
                        >
                        {props.buyers &&
                            props.buyers.map((buyer) => (
                            <MenuItem value={buyer.buyerid}>{buyer.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <br />
            <div style={{ width: '100%', borderTop: '1px solid #D3D3D3' }}></div>
            <TitleText>Product:</TitleText>
            <Stack spacing={3}>
                {products &&
                    products.map((product, index) => (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Product</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Product"
                                            value={products[index].productid}
                                            onChange={handleSelectProduct(index)}
                                            >
                                            {props.products &&
                                                props.products.map((products) => (
                                                    products.products.map((product) => (
                                                        <MenuItem value={product.productid}>{product.name} - ({products.name})</MenuItem>
                                                    )
                                                )))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={7}>
                                        { quantityCheckForProduct(products[index].productid, "text") ? (
                                            <MatchesSuccess>
                                                {quantityCheckForProduct(products[index].productid, "quan")} / {quantityCheckForProduct(products[index].productid, "total")} Invoice Quantity matches Incoming PO
                                            </MatchesSuccess>
                                        ) : (
                                            <MatchesFailed>
                                                {quantityCheckForProduct(products[index].productid, "quan")} / {quantityCheckForProduct(products[index].productid, "total")} Invoice Quantity does not matches Incoming PO
                                            </MatchesFailed>
                                        )}
                                    </Grid>
                                    <Grid item xs={1}>
                                        <DeleteIcon style={{ color: 'red', cursor: 'pointer' }} onClick={() => handleDeleteProduct(index)}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <br />
                                {product.productdetail &&
                                    product.productdetail.map((productData, innerIndex) => (
                                            <Grid container spacing={2} style={{ margin: '3px'}}>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        label="Product Quantity"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={products[index].productdetail[innerIndex].quantity}
                                                        onChange={handleProductQuantity(index, innerIndex)}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <TextField
                                                                    variant="standard"
                                                                    value={products[index].productdetail[innerIndex].unit}
                                                                    disabled
                                                                    />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            sx={{ width: "100%" }}
                                                            label="Delivery Date"
                                                            value={dayjs(products[index].productdetail[innerIndex].date)}
                                                            onChange={(newValue) =>
                                                                handleDateChangeProduct(index, innerIndex, newValue)
                                                            }
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        label="Price Per Unit"
                                                        variant="outlined"
                                                        fullWidth
                                                        onChange={handlePricePerUnit(index, innerIndex)}
                                                        InputProps={{
                                                            endAdornment: (
                                                            <TextField
                                                                select
                                                                variant="standard"
                                                                sx={{ width: '25%' }}
                                                                value={currency}
                                                                onChange={(event) => setCurrency(event.target.value)}
                                                            >
                                                                <MenuItem value="inr">INR</MenuItem>
                                                                <MenuItem value="usd">USD</MenuItem>
                                                            </TextField>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        label="Total Amount"
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled={true}
                                                        value={products[index].productdetail[innerIndex].totalamount}
                                                        InputProps={{
                                                            endAdornment: (
                                                            <TextField
                                                                select
                                                                disabled={true}
                                                                value={currency}
                                                                sx={{ width: '25%' }}
                                                                variant="standard"
                                                                onChange={(event) => setCurrency(event.target.value)}
                                                            >
                                                                <MenuItem value="inr">INR</MenuItem>
                                                                <MenuItem value="usd">USD</MenuItem>
                                                            </TextField>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <DeleteIcon style={{ color: 'red', cursor: 'pointer' }} onClick={() => handleDeleteSplitProduct(index, innerIndex)}/>
                                                </Grid>
                                            </Grid>
                                    ))}
                            <br />
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    id="incomingponumber"
                                    variant="outlined"
                                    fullWidth
                                    value={products[index].remark}
                                    onChange={handleRemark(index)}
                                    label="Specifications/Remarks"
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                            <Button
                                variant="contained"
                                size="large"
                                color="secondary"
                                style={{
                                    width: '300px',
                                    marginLeft : '15px',
                                }}
                                onClick={() => addSplitProductHandler(index)}
                                endIcon={<AddIcon />}
                                sx={{ maxWidth: "312px", marginTop: "20px", marginBottom: "10px"  }}
                                >
                                Split
                            </Button>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="incomingponumber"
                                            variant="outlined"
                                            fullWidth
                                            value={products[index].sgst}
                                            label="SGST%"
                                            onChange={handleSGST(index)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="incomingponumber"
                                            variant="outlined"
                                            fullWidth
                                            value={products[index].cgst}
                                            label="CGST%"
                                            onChange={handleCGST(index)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="incomingponumber"
                                            variant="outlined"
                                            fullWidth
                                            value={products[index].igst}
                                            label="IGST%"
                                            onChange={handleIGST(index)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Product Tax Amount"
                                            variant="outlined"
                                            fullWidth
                                            value={products[index].producttaxamount}
                                            disabled={true}
                                            InputProps={{
                                                endAdornment: (
                                                <TextField
                                                    select
                                                    variant="standard"
                                                    sx={{ width: '25%' }}
                                                    value={currency}
                                                    onChange={(event) => setCurrency(event.target.value)}
                                                    disabled={true}
                                                >
                                                    <MenuItem value="inr">INR</MenuItem>
                                                    <MenuItem value="usd">USD</MenuItem>
                                                </TextField>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={9}></Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Product Total"
                                            disabled={true}
                                            variant="outlined"
                                            fullWidth
                                            value={products[index].producttotal}
                                            InputProps={{
                                                endAdornment: (
                                                <TextField
                                                    select
                                                    disabled={true}
                                                    sx={{ width: '25%' }}
                                                    value={currency}
                                                    variant="standard"
                                                    onChange={(event) => setCurrency(event.target.value)}
                                                >
                                                    <MenuItem value="inr">INR</MenuItem>
                                                    <MenuItem value="usd">USD</MenuItem>
                                                </TextField>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            < hr />
                        </Grid>
            ))}            
            </Stack>
            <br />
            <Button
                variant="outlined"
                size="large"
                style={{
                    width: '300px',
                }}
                onClick={() => addNewProduct()}
                endIcon={<AddIcon />}
                sx={{ maxWidth: "312px", marginTop: "20px", marginBottom: "20px" }}
            >
            Another Product
            </Button>
            <br />
            <div style={{ width: '100%', borderTop: '1px solid #D3D3D3' }}></div>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={3} style={{ textAlign: 'right', marginTop : '1%' }}>
                            Net Amount (Before Tax)
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Net Amount"
                                variant="outlined"
                                fullWidth
                                value={netamount}
                                disabled={true}
                                InputProps={{
                                    endAdornment: (
                                    <TextField
                                        select
                                        variant="standard"
                                        sx={{ width: '25%' }}
                                        value={currency}
                                        onChange={(event) => setCurrency(event.target.value)}
                                        disabled={true}
                                    >
                                        <MenuItem value="inr">INR</MenuItem>
                                        <MenuItem value="usd">USD</MenuItem>
                                    </TextField>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={3} style={{ textAlign: 'right', marginTop : '1%' }}>
                            Gross Amount (After Tax)
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Gross Amount"
                                variant="outlined"
                                disabled={true}
                                value={grossamount}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                    <TextField
                                        select
                                        disabled={true}
                                        sx={{ width: '25%' }}
                                        value={currency}
                                        onChange={(event) => setCurrency(event.target.value)}
                                        variant="standard"
                                    >
                                        <MenuItem value="inr">INR</MenuItem>
                                        <MenuItem value="usd">USD</MenuItem>
                                    </TextField>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <br />
            <div style={{ width: '100%', borderTop: '1px solid #D3D3D3' }}></div>
            <TitleText>Note:</TitleText>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={1} lg={1}>
                    <FormControl component="fieldset">
                        <FormControlLabel
                        control={<Switch checked={isCreditEnabled} onChange={handleToggle} />}
                        label="Credit"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                    {isCreditEnabled && (
                        <TextField
                            id="contactperson"
                            variant="outlined"
                            fullWidth
                            onChange={(event) => setCredit(event.target.value)}
                            label="Credit Period"
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                    {isCreditEnabled && (
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Credit Unit</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Credit Unit"
                            value="Days"
                            >
                                <MenuItem value="Days">Days</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                        id="contactperson"
                        variant="outlined"
                        fullWidth
                        onChange={(event) => setRemarkInvoice(event.target.value)}
                        label="Remarks (if any)"
                        multiline
                        rows={4}
                    />
                </Grid>
            </Grid>
            <br />
            <div style={{ width: '100%', borderTop: '1px solid #D3D3D3' }}></div>
            <Button
                variant="contained"
                size="large"
                style={{
                    width: '300px',
                }}
                onClick={() => handleAddIPO()}
                endIcon={<AddIcon />}
                sx={{ maxWidth: "312px", marginTop: "20px", marginBottom: "30px" }}
            >
            Create
            </Button>
            </> }
        </Container>
    );
}
function mapStateToProps(state) {
    const { global } = state;
    return global;
}
export default connect(mapStateToProps)(InvoiceEntryPage);