import React, { useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import { connect, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import { styled as muiStyled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { updateData } from "../../utils/apiClient";
import {
  setProductsLoad,
  setProductsSuccess,
  setProductsError,
} from "../../redux/reducers/globalReducer";
const filter = createFilterOptions();

const Accordion = muiStyled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = muiStyled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#4282BF",
  borderRadius: "8px 8px 0px 0px",
  color: "#F1F1F1",
  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(270deg)",
    color: "#F1F1F1 !important",
  },

  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    color: "#F1F1F1 !important",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const AccordionDetails = muiStyled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  border: "1px solid #4282BF",
  borderRadius: "0px 0px 8px 8px",
}));
function ProductPRNForm(props) {
  const {
    category,
    product,
    quantity,
    unit,
    productid,
    delteProduct,
    selectProductValue,
    isDisabled,
  } = props;
  const [expanded, setExpanded] = React.useState("panel1");
  const [filteredProducts, setFilteredProducts] = React.useState([]);
  const [filteredUnits, setFilteredUnits] = React.useState([]);
  const dispatch = useDispatch();
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleSelectCategory = (event) => {
    const getCategory = props.products.find(
      (eachCategory) => eachCategory.productcategoryid === event.target.value
    );
    if (getCategory) {
      setFilteredProducts(getCategory.products);
    }
    selectProductValue(productid, event.target.value, "category");
  };
  const handleSelectProduct = async (event, newVal) => {
    const getProduct = filteredProducts.find(
      (eachProduct) => eachProduct.productid === newVal.productid
    );
    if (getProduct) {
      if (
        getProduct.measurementmetrics &&
        getProduct.measurementmetrics.name
      ) {
        setFilteredUnits([getProduct.measurementmetrics.name]);
      }
      selectProductValue(productid, getProduct, "product");
      selectProductValue(productid, getProduct.measurementmetrics.name, "unit");
      
    } else {
      const addNewProductData = {
        name: newVal.inputValue,
        productcategoryid: category,
      };
      const addProductResp = await updateData(
        "products/create",
        addNewProductData
      );
      if (addProductResp.success) {
        dispatch(setProductsLoad());
        selectProductValue(productid, addProductResp.data, "product");
        const productCategories = await updateData("productcategories");
        if (productCategories.success) {
          dispatch(setProductsSuccess(productCategories.data));
        } else {
          dispatch(setProductsError());
        }
      }
    }
  };
  const handleSelectUnit = (event) => {
    selectProductValue(productid, event.target.value, "unit");
  };
  const handleQuantity = (event) => {
    selectProductValue(productid, event.target.value, "quantity");
  };
  useEffect(() => {
    const getCategory = props.products.find(
      (eachCategory) => eachCategory.productcategoryid === props.category
    );
    if (getCategory) {
      setFilteredProducts(getCategory.products);
    }
    if (product && product.productid) {
      if (
        product &&
        product.measurementmetrics &&
        product.measurementmetrics.name
      ) {
        setFilteredUnits([product.measurementmetrics.name]);
      }
    }
  }, [product, props.category, props.products]);
  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        {product ? product.name : "Product - 1"}
        {!isDisabled && (
          <IconButton
            sx={{ width: "fit-content" }}
            onClick={() => delteProduct(productid)}
          >
            <DeleteIcon sx={{ color: "#f1f1f1" }} />
          </IconButton>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <FormControl fullWidth sx={{ maxWidth: "452px" }}>
              <InputLabel id="demo-simple-select-label">
                Choose Category
              </InputLabel>
              {props.productsSuccess && (
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={category}
                  disabled={isDisabled || false}
                  label="Choose Category"
                  onChange={handleSelectCategory}
                >
                  {props.products &&
                    props.products.map((category) => (
                      <MenuItem value={category.productcategoryid}>
                        {category.name}
                      </MenuItem>
                    ))}
                </Select>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            {/* <FormControl fullWidth sx={{ maxWidth: "452px" }}>
              <InputLabel id="demo-simple-select-label">Product</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={product && product.productid}
                disabled={isDisabled || false}
                label="Product"
                onChange={handleSelectProduct}
              >
                {filteredProducts &&
                  filteredProducts.map((product) => (
                    <MenuItem value={product.productid}>
                      {product.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl> */}
            <Autocomplete
              fullWidth
              value={product ? product.name : ""}
              onChange={handleSelectProduct}
              disabled={isDisabled || false}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.name
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    name: `Add "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={filteredProducts}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.name;
              }}
              renderOption={(props, option) => (
                <li {...props}>{option.name}</li>
              )}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Product" fullWidth />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              // id="quantity"
              label="Product Quantity"
              variant="outlined"
              fullWidth
              value={quantity}
              disabled={isDisabled || false}
              onChange={handleQuantity}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FormControl
                      variant="standard"
                      fullWidth
                      sx={{
                        minWidth: "50px",
                        borderLeft: "1px solid",
                        paddingLeft: "20px",
                      }}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={unit}
                        defaultValue="kg"
                        label="Product"
                        disabled={isDisabled || false}
                        onChange={handleSelectUnit}
                      >
                        {filteredUnits.map((unit) => (
                          <MenuItem value={unit}>{unit}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
function mapStateToProps(state) {
  const { grn, global } = state;
  return { ...grn, ...global };
}

export default connect(mapStateToProps)(ProductPRNForm);
